var ConsumerContextActionTypeKeys;
(function (ConsumerContextActionTypeKeys) {
    ConsumerContextActionTypeKeys["Get_ConsumerMetadata_Success"] = "GET_CONSUMERMETADATA_SUCCESS";
    ConsumerContextActionTypeKeys["Get_ConsumerMetadata_Failure"] = "GET_CONSUMERMETADATA_ERROR";
    ConsumerContextActionTypeKeys["Get_ConsumerMetadata_InProgress"] = "GET_CONSUMERMETADATA_INPROGRESS";
    ConsumerContextActionTypeKeys["Update_BasicDetails"] = "UPDATE_BASICSDETAILS";
    ConsumerContextActionTypeKeys["Update_EscalationDetails"] = "UPDATE_ESCALATIONDETAILS";
    ConsumerContextActionTypeKeys["Update_BasicDetailsValidationStatus"] = "UPDATE_BASICDETAILSVALIDATIONSTATUS";
    ConsumerContextActionTypeKeys["Update_EscalationDetailsValidationStatus"] = "UPDATE_ESCALATIONSETAILSVALIDATIONSTATUS";
    ConsumerContextActionTypeKeys["Submit_ConsumerEscalation"] = "SUBMIT_CONSUMERESCALATION";
    ConsumerContextActionTypeKeys["Submit_ConsumerEscalation_Success"] = "SUBMIT_CONSUMERESCALATION_SUCCESS";
    ConsumerContextActionTypeKeys["Submit_ConsumerEscalation_InProgress"] = "SUBMIT_CONSUMERESCALATION_INPROGRESS";
    ConsumerContextActionTypeKeys["Submit_ConsumerEscalation_Failure"] = "SUBMIT_CONSUMERESCALATION_FAILURE";
})(ConsumerContextActionTypeKeys || (ConsumerContextActionTypeKeys = {}));
export default ConsumerContextActionTypeKeys;
