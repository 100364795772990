import { ElxSpacing } from "@elixir/styling";
import { mergeStyles } from "@fluentui/react";
import { useTheme } from "@fluentui/react-theme-provider";
export const useTileStyles = () => {
    var _a, _b, _c;
    const theme = useTheme();
    const tileStyle = {
        root: [
            {
                "& h2": {
                    margin: "0px",
                    "& ul": {
                        marginTop: "0px",
                        marginLeft: "0rem",
                        paddingLeft: "1rem",
                    },
                },
                "& .tile-navigation": {
                    textDecoration: "none !important",
                    color: "#292827 !important",
                    lineHeight: "18px",
                },
                "& .tile-section": {
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "stretch",
                    border: "1px",
                    cursor: "pointer",
                    "& .tile:hover": {
                        backgroundColor: "rgb(245, 244, 243)",
                    },
                    "& .tile": {
                        margin: "0.325rem",
                        width: "330px",
                        height: "250px",
                        background: (_c = (_b = (_a = theme.tokens) === null || _a === void 0 ? void 0 : _a.color) === null || _b === void 0 ? void 0 : _b.brand) === null || _c === void 0 ? void 0 : _c.secondaryContentColor,
                        boxShadow: `0 calc(${ElxSpacing.space1} / 2) ${ElxSpacing.spaceHalf} rgba(0, 0, 0, 0.1)`,
                        borderRadius: "2px",
                        border: "0.01rem solid #e6e6e661",
                        "& .tile-content": {
                            padding: "8px 12px 10px 12px",
                            display: "flex",
                            flexDirection: "column",
                            fontSize: "12px",
                            fontWeight: "400",
                            height: "70%",
                            "& .tile-header": {
                                fontFamily: "Segoe UI",
                                fontWeight: "600",
                                lineHeight: "22px",
                                fontSize: "16px",
                            },
                            "& .tile-description": {
                                flex: "1 1 auto",
                                minHeight: "3rem",
                                marginTop: "0px",
                                paddingTop: "0.75rem",
                                margin: "0px",
                                "&.ul": {
                                    marginLeft: "0px",
                                    paddingLeft: "0px",
                                    textAlign: "left",
                                },
                            },
                            "& .tileSelectBtn": {
                                marginLeft: "12px",
                            },
                        },
                    },
                },
                "& .supported-links-section": {
                    marginLeft: `${ElxSpacing.spaceHalf}`,
                    marginTop: `${ElxSpacing.space1} * 4`,
                    "& .links": {
                        listStyle: "none",
                        paddingLeft: 0,
                    },
                    "& .link": {
                        textDecoration: "underline",
                        marginTop: `${ElxSpacing.spaceHalf} * 3`,
                        display: "block",
                        "& .link-color": {
                            color: theme.semanticColors.link,
                            "&:hover": {
                                color: theme.semanticColors.linkHovered,
                            },
                        },
                    },
                },
            },
        ],
    };
    return mergeStyles(tileStyle);
};
