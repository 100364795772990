var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createHttpClient } from "@elixir/fx";
/**
 * Provides generic HTTP calls utilizing standard authentication, data handling, and error handling.
 */
export class HttpService {
    constructor() {
        this.Methods = {
            DELETE: "DELETE",
            GET: "GET",
            HEAD: "HEAD",
            PATCH: "PATCH",
            POST: "POST",
            PUT: "PUT",
        };
        this.reqHeaders = {
            "Access-Control-Allow-Origin": "*",
        };
        this.elixirClient = createHttpClient({ headers: this.reqHeaders });
        this.graphClient = createHttpClient(undefined, {
            scopes: ["Directory.Read.All"],
        });
        /**
         * Builds a URL object from the given React environment variables and query parameters.
         *
         * @param baseApiVar React variable corresponding to the base URL for this endpoint, omitting 'REACT_APP_'
         * @param routeVar React variable corresponding to the URL route for this endpoint, omitting 'REACT_APP_{baseApiVar}_'
         * @param queryParams Query parameters object to be appended to the URL
         *
         * @returns A URL representing the input endpoint
         */
        this.constructUrl = (baseApiVar, routeVar, queryParams, specifier) => {
            let baseApi = baseApiVar; // Add from config;
            if (!baseApi) {
                throw new Error("Specified base API does not exist in environment.");
            }
            let route = routeVar; // Add from config;
            if (!route) {
                throw new Error("No route specified.");
            }
            if (baseApi.endsWith("/")) {
                baseApi = baseApi.substring(0, baseApi.length - 1);
            }
            if (!route.startsWith("/")) {
                route = "/" + route;
            }
            if (specifier && !specifier.startsWith("/")) {
                specifier = "/" + specifier;
            }
            let url;
            if (specifier) {
                url = new URL(baseApi + route + specifier);
            }
            else {
                url = new URL(baseApi + route);
            }
            if (queryParams) {
                queryParams.forEach((value, key) => {
                    url.searchParams.append(key, value);
                });
            }
            return url;
        };
        /**
         * Executes a generic HTTP call to the given endpoint.
         *
         * @param method The HTTP method to use for this call
         * @param baseApiVar React variable corresponding to the base URL for this endpoint, omitting 'REACT_APP_'
         * @param routeVar React variable corresponding to the URL route for this endpoint, omitting 'REACT_APP_{baseApiVar}_'
         * @param queryParams Query parameters object to be appended to the URL for this call
         * @param body The body of the request
         *
         * @returns A Promise for this HTTP call
         */
        this.sendRequest = (method, baseApiVar, routeVar, queryParams, body, specifier, isGraphClient) => __awaiter(this, void 0, void 0, function* () {
            let url;
            try {
                // eslint-disable-line
                url = this.constructUrl(baseApiVar, routeVar, queryParams, specifier);
            }
            catch (e) {
                console.log(e); // Adding console to fix eslint issue. Will be replaced with telemetry.
                throw e;
            }
            if (body) {
                if (method.toUpperCase() === this.Methods.GET ||
                    method.toUpperCase() === this.Methods.HEAD) {
                    throw new Error("Cannot send a body with a GET request.");
                }
            }
            // TODO: Elixir telemetry
            const trackingContext = {};
            switch (method) {
                case this.Methods.DELETE:
                    return this.elixirClient.delete(trackingContext, url.toString(), body);
                case this.Methods.GET:
                    if (isGraphClient)
                        return this.graphClient.get(trackingContext, url.toString());
                    return this.elixirClient.get(trackingContext, url.toString());
                case this.Methods.HEAD:
                    return this.elixirClient.head(trackingContext, url.toString());
                case this.Methods.PATCH:
                    return this.elixirClient.patch(trackingContext, url.toString(), body);
                case this.Methods.POST:
                    return this.elixirClient.post(trackingContext, url.toString(), body);
                case this.Methods.PUT:
                    return this.elixirClient.put(trackingContext, url.toString(), body);
            }
        });
        /**
         * Executes an HTTP DELETE call to the given endpoint.
         *
         * @param baseApiVar React variable corresponding to the base URL for this endpoint, omitting 'REACT_APP_'
         * @param routeVar React variable corresponding to the URL route for this endpoint, omitting 'REACT_APP_{baseApiVar}_'
         * @param queryParams Query parameters object to be appended to the URL for this call
         * @param body The body of the request
         *
         * @returns A Promise for this HTTP call
         */
        this.delete = (baseApiVar, routeVar, queryParams, body, specifier) => __awaiter(this, void 0, void 0, function* () {
            return this.sendRequest(this.Methods.DELETE, baseApiVar, routeVar, queryParams, body, specifier);
        });
        /**
         * Executes an HTTP GET call to the given endpoint.
         *
         * @param baseApiVar React variable corresponding to the base URL for this endpoint, omitting 'REACT_APP_'
         * @param routeVar React variable corresponding to the URL route for this endpoint, omitting 'REACT_APP_{baseApiVar}_'
         * @param queryParams Query parameters object to be appended to the URL for this call
         * @param isGraphClient boolean if client is graph client
         *
         * @returns A Promise for this HTTP call
         */
        this.get = (baseApiVar, routeVar, queryParams, isGraphClient) => __awaiter(this, void 0, void 0, function* () {
            return this.sendRequest(this.Methods.GET, baseApiVar, routeVar, queryParams, undefined, undefined, isGraphClient);
        });
        /**
         * Executes an HTTP HEAD call to the given endpoint.
         *
         * @param baseApiVar React variable corresponding to the base URL for this endpoint, omitting 'REACT_APP_'
         * @param routeVar React variable corresponding to the URL route for this endpoint, omitting 'REACT_APP_{baseApiVar}_'
         * @param queryParams Query parameters object to be appended to the URL for this call
         *
         * @returns A Promise for this HTTP call
         */
        this.head = (baseApiVar, routeVar, queryParams) => __awaiter(this, void 0, void 0, function* () {
            return this.sendRequest(this.Methods.HEAD, baseApiVar, routeVar, queryParams);
        });
        /**
         * Executes an HTTP PATCH call to the given endpoint.
         *
         * @param baseApiVar React variable corresponding to the base URL for this endpoint, omitting 'REACT_APP_'
         * @param routeVar React variable corresponding to the URL route for this endpoint, omitting 'REACT_APP_{baseApiVar}_'
         * @param queryParams Query parameters object to be appended to the URL for this call
         * @param body The body of the request
         *
         * @returns A Promise for this HTTP call
         */
        this.patch = (baseApiVar, routeVar, queryParams, body) => __awaiter(this, void 0, void 0, function* () {
            return this.sendRequest(this.Methods.PATCH, baseApiVar, routeVar, queryParams, body);
        });
        /**
         * Executes an HTTP POST call to the given endpoint.
         *
         * @param baseApiVar React variable corresponding to the base URL for this endpoint, omitting 'REACT_APP_'
         * @param routeVar React variable corresponding to the URL route for this endpoint, omitting 'REACT_APP_{baseApiVar}_'
         * @param queryParams Query parameters object to be appended to the URL for this call
         * @param body The body of the request
         *
         * @returns A Promise for this HTTP call
         */
        this.post = (baseApiVar, routeVar, queryParams, body) => __awaiter(this, void 0, void 0, function* () {
            return this.sendRequest(this.Methods.POST, baseApiVar, routeVar, queryParams, body);
        });
        /**
         * Executes an HTTP PUT call to the given endpoint.
         *
         * @param baseApiVar React variable corresponding to the base URL for this endpoint, omitting 'REACT_APP_'
         * @param routeVar React variable corresponding to the URL route for this endpoint, omitting 'REACT_APP_{baseApiVar}_'
         * @param queryParams Query parameters object to be appended to the URL for this call
         * @param body The body of the request
         *
         * @returns A Promise for this HTTP call
         */
        this.put = (baseApiVar, routeVar, queryParams, body) => __awaiter(this, void 0, void 0, function* () {
            return this.sendRequest(this.Methods.PUT, baseApiVar, routeVar, queryParams, body);
        });
    }
}
