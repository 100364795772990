import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Persona, PersonaSize, } from "@fluentui/react/lib/Persona";
import { ElxTooltip } from "@elixir/components/lib/Tooltip";
import { elixirSelectors, fetchPersonaAction, } from "@elixir/fx/lib/store";
export var ElixirPersonaTypes;
(function (ElixirPersonaTypes) {
    ElixirPersonaTypes[ElixirPersonaTypes["Image"] = 0] = "Image";
    ElixirPersonaTypes[ElixirPersonaTypes["Name"] = 1] = "Name";
    ElixirPersonaTypes[ElixirPersonaTypes["SmallImage"] = 2] = "SmallImage";
    ElixirPersonaTypes[ElixirPersonaTypes["EmailInline"] = 3] = "EmailInline";
    ElixirPersonaTypes[ElixirPersonaTypes["EmailInlineSmallImage"] = 4] = "EmailInlineSmallImage";
    ElixirPersonaTypes[ElixirPersonaTypes["EmailMultilines"] = 5] = "EmailMultilines";
})(ElixirPersonaTypes || (ElixirPersonaTypes = {}));
export const ElxPersona = (props) => {
    var _a;
    const component = usePersona(props);
    const { type, tooltipContent } = props;
    let { displayName } = props;
    let showTooltip = false;
    const { user, account, localImage } = component;
    // if we resolve user, we should take that over what is provided via props
    displayName = (_a = user === null || user === void 0 ? void 0 : user.displayName) !== null && _a !== void 0 ? _a : displayName;
    const persona = {
        text: displayName,
        size: PersonaSize.size24,
        imageUrl: localImage,
    };
    switch (type) {
        case ElixirPersonaTypes.Image:
            persona.hidePersonaDetails = true;
            showTooltip = true;
            break;
        case ElixirPersonaTypes.Name:
            break;
        case ElixirPersonaTypes.SmallImage:
            persona.size = PersonaSize.size16;
            persona.hidePersonaDetails = true;
            showTooltip = true;
            break;
        case ElixirPersonaTypes.EmailInline:
            persona.text = displayName ? `${displayName} (${account})` : account;
            break;
        case ElixirPersonaTypes.EmailInlineSmallImage:
            persona.size = PersonaSize.size8;
            persona.text = displayName ? `${displayName} (${account})` : account;
            break;
        case ElixirPersonaTypes.EmailMultilines:
            persona.size = PersonaSize.size40;
            persona.secondaryText = account;
            break;
        default:
            break;
    }
    React.useEffect(effectFetchPersona(component), [user, localImage]);
    const content = (_jsx(Persona, Object.assign({ coinProps: Object.assign({ styles: { initials: { color: "white" } } }, persona.coinProps) }, persona)));
    return showTooltip ? (_jsx(ElxTooltip, Object.assign({ content: tooltipContent ? tooltipContent : persona.text }, { children: content }))) : (content);
};
function usePersona(props) {
    const { userPrincipalName } = props;
    const account = userPrincipalName.includes("@microsoft.com")
        ? userPrincipalName
        : `${userPrincipalName}@microsoft.com`;
    const alias = userPrincipalName.split("@")[0];
    const [localImage, setLocalImage] = React.useState();
    return {
        dispatch: useDispatch(),
        account,
        alias,
        user: useSelector((state) => elixirSelectors.getPersona(state, account)),
        localImage,
        setLocalImage,
    };
}
function effectFetchPersona(component) {
    const { dispatch, user, alias, localImage, setLocalImage, account } = component;
    return () => {
        if (localImage === undefined) {
            if (!user) {
                dispatch(fetchPersonaAction(alias, account));
            }
            else {
                setLocalImage(user.image);
            }
        }
    };
}
