import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { sortBy } from "lodash";
import { ElxActionButton, ElxContainer, ElxSearchBox, ElxTable, ElxLink, SearchBoxSize, ElxText, ElxFilter, FilterOptionPillMode, ElxPrimaryButton, } from "@elixir/components";
import { ElxNavLink } from "@elixir/fx";
import { Icon } from "@fluentui/react/lib/Icon";
import { Label, SelectionMode, Spinner, SpinnerSize, Stack, } from "@fluentui/react";
import { headerStyle, useEfdContainerStyles } from "./MyEscalations.styles";
import { useLocaleStrings } from "../../library/providers/strings";
import { useRootContext } from "../../library/providers/root/RootContextProvider";
import { convertISOtoLocal } from "../../library/utilities/DateAndTimeFormatter";
import "./MyEscalationsStyle.css";
import DetailsPage from "../DetailsPage/DetailsPage";
import { UseMyEscalationsDispatch, UseMyEscalationsState, } from "../../reducers/MyEscalationsContext/MyEscalationsContextProvider";
import NoEscalationImage from "../../images/noEscalations.png";
const topStack = { childrenGap: 5 };
const midStack = { childrenGap: 10 };
const MyEscalations = () => {
    const { getLocalSpecificString } = useLocaleStrings();
    const { isConfigurationSet } = useRootContext();
    const MyEscalationsState = UseMyEscalationsState();
    const { GetMyEscalations, SetSelectedEscalationRequestNumber, SetMyCasesView, } = UseMyEscalationsDispatch();
    const [myCasesDetails, setMyCasesDetails] = React.useState([]);
    const [myEscalationItems, setMyEscalationItems] = React.useState([]);
    const [searchCriteria, setSearchCriteria] = React.useState({
        filters: {},
        keyword: "",
    });
    const [selections, setSelections] = React.useState([]);
    const [loading, setLoading] = React.useState(true);
    const [detailsview, setDetailsview] = React.useState(false);
    const columns = [
        {
            key: "column0",
            name: "Escalation ID",
            fieldName: "escalationId",
            isMultiline: true,
            minWidth: 100,
            maxWidth: 150,
            isResizable: true,
            showSortIconWhenUnsorted: true,
            onRender: (item) => {
                return (_jsx(ElxLink, Object.assign({ className: "no-underline", onClick: () => detailsInfoLoad(item.escalationId) }, { children: item.escalationId })));
            },
        },
        {
            key: "column1",
            name: "Customer",
            fieldName: "customerName",
            minWidth: 160,
            maxWidth: 300,
            isResizable: true,
            isMultiline: true,
            showSortIconWhenUnsorted: true,
        },
        {
            key: "column2",
            name: "Type",
            fieldName: "escalationFlowType",
            minWidth: 160,
            maxWidth: 300,
            isResizable: true,
            isMultiline: true,
            showSortIconWhenUnsorted: true,
        },
        {
            key: "column3",
            name: "Product/Program",
            fieldName: "product",
            minWidth: 400,
            maxWidth: 1000,
            isResizable: true,
            isMultiline: true,
            showSortIconWhenUnsorted: true,
        },
        {
            key: "column4",
            name: "Associated tickets or IDs",
            fieldName: "supportRequestNumber",
            minWidth: 220,
            maxWidth: 230,
            isResizable: true,
            isMultiline: true,
            showSortIconWhenUnsorted: true,
        },
        {
            key: "column5",
            name: "Status",
            fieldName: "status",
            minWidth: 100,
            maxWidth: 300,
            isResizable: true,
            isMultiline: true,
            showSortIconWhenUnsorted: true,
            onRender: (item) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                if (((_a = item.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == "closed" ||
                    ((_b = item.status) === null || _b === void 0 ? void 0 : _b.toLowerCase()) == "resolved") {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "SkypeCircleCheck", style: { color: "green" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else if ((_c = item.status) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes("progress")) {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "SyncStatusSolid", style: { color: "#015CDA" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else if ((_d = item.status) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes("review")) {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "InfoSolid", style: { color: "#be2596" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else if ((_e = item.status) === null || _e === void 0 ? void 0 : _e.toLowerCase().includes("block")) {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "Blocked2Solid", style: { color: "#red" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else if (((_f = item.status) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes("open")) ||
                    ((_g = item.status) === null || _g === void 0 ? void 0 : _g.toLowerCase().includes("new")) ||
                    ((_h = item.status) === null || _h === void 0 ? void 0 : _h.toLowerCase().includes("start"))) {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "SyncStatusSolid", style: { color: "#015CDA" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else {
                    return _jsx("div", { children: item.status || "Not Available" });
                }
            },
        },
        {
            key: "column6",
            name: "Submitted",
            fieldName: "submittedOn",
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
            showSortIconWhenUnsorted: true,
            isMultiline: true,
            onRender: (item) => {
                if (item.submittedOn !== undefined && item.submittedOn !== null) {
                    const formattedDateTime = convertISOtoLocal(item.submittedOn);
                    return formattedDateTime;
                }
                else {
                    return "Not Available";
                }
            },
        },
    ];
    const filterOptions = [
        {
            pillMode: FilterOptionPillMode.Static,
            field: "customerName",
            label: "Customer Name",
            values: Array.from(new Set(myEscalationItems.map((item) => item.customerName || "NA"))),
            multiselect: true,
        },
        {
            pillMode: FilterOptionPillMode.Static,
            field: "product",
            label: "Product",
            values: Array.from(new Set(myEscalationItems.map((item) => item.product || ""))),
            multiselect: true,
        },
        {
            pillMode: FilterOptionPillMode.Static,
            field: "status",
            label: "Status",
            values: Array.from(new Set(myEscalationItems.map((item) => item.status || ""))),
            multiselect: true,
        },
        {
            pillMode: FilterOptionPillMode.Static,
            field: "escalationFlowType",
            label: "Type",
            values: Array.from(new Set(myEscalationItems.map((item) => item.escalationFlowType || ""))),
            multiselect: true,
        },
        {
            pillMode: FilterOptionPillMode.Static,
            field: "supportRequestNumber",
            label: "Associated tickets or IDs",
            values: Array.from(new Set(myEscalationItems.map((item) => item.supportRequestNumber || ""))),
            multiselect: true,
        },
    ];
    function onBreadcrumbItemClicked() {
        SetMyCasesView(true);
    }
    React.useEffect(() => {
        if (isConfigurationSet)
            GetMyEscalations();
    }, [isConfigurationSet]);
    React.useEffect(() => {
        var _a;
        try {
            setLoading(MyEscalationsState.InProgress);
            if (MyEscalationsState === null || MyEscalationsState === void 0 ? void 0 : MyEscalationsState.Escalations) {
                // Get the anchor element by its label attribute
                const anchorElement = document.querySelector('[label="ElxBreadcrumbItem"]');
                // Check if the element exists before adding the click event
                if (anchorElement) {
                    // Add the click event listener
                    anchorElement.addEventListener("click", onBreadcrumbItemClicked);
                }
                // Access the data here
                const myescalation = MyEscalationsState.Escalations;
                const consumerEscalation = myescalation === null || myescalation === void 0 ? void 0 : myescalation.consumerEscalation;
                // eslint-disable-next-line prefer-const
                let itemsFinalConsumer = [];
                if (consumerEscalation !== null &&
                    consumerEscalation !== undefined &&
                    consumerEscalation.length > 0) {
                    itemsFinalConsumer = consumerEscalation.map((conEscalation) => {
                        var _a, _b;
                        const escalation = {
                            supportRequestNumber: conEscalation.supportRequestNumber || "",
                            customerName: ((_a = conEscalation.customerInfo) === null || _a === void 0 ? void 0 : _a.customerFirstName) || "",
                            escalationId: conEscalation.escalationRequestNumber || "",
                            product: ((_b = conEscalation.product) === null || _b === void 0 ? void 0 : _b.name) || "",
                            status: conEscalation.status || "",
                            submittedOn: conEscalation.createdOn,
                            escalationFlowType: conEscalation.flowType || "Home User",
                        };
                        return escalation;
                    });
                }
                const commercialEscalation = myescalation === null || myescalation === void 0 ? void 0 : myescalation.commercialEscalation;
                let itemsFinalCommercial = [];
                if (commercialEscalation !== null &&
                    commercialEscalation !== undefined &&
                    commercialEscalation.length > 0) {
                    itemsFinalCommercial = commercialEscalation.map((conEscalation) => {
                        var _a, _b, _c, _d, _e;
                        const productNames = (_a = conEscalation.products) === null || _a === void 0 ? void 0 : _a.map((product) => product.productName).join(",");
                        const programNames = (_b = conEscalation.programs) === null || _b === void 0 ? void 0 : _b.map((program) => program.programName).join(",");
                        const combinedNamesArray = [productNames, programNames].filter(Boolean);
                        const combinedNames = combinedNamesArray.join(",");
                        const escalation = {
                            supportRequestNumber: conEscalation.supportRequestNumber || "",
                            customerName: ((_c = conEscalation.customerInfo) === null || _c === void 0 ? void 0 : _c.customerSegmentName) === "Consumer"
                                ? (_d = conEscalation.customerInfo) === null || _d === void 0 ? void 0 : _d.customerFirstName
                                : ((_e = conEscalation.accountInfo) === null || _e === void 0 ? void 0 : _e.companyName) || "NA",
                            escalationId: conEscalation.escalationRequestNumber || "NA",
                            product: combinedNames,
                            status: conEscalation.status || "",
                            submittedOn: conEscalation.createdOn,
                            escalationFlowType: conEscalation.flowType || "Commercial",
                        };
                        return escalation;
                    });
                }
                const finalEscalationSummary = itemsFinalCommercial.concat(itemsFinalConsumer);
                const sortedEscalationsArray = sortBy(finalEscalationSummary, (obj) => obj.submittedOn).reverse();
                setMyEscalationItems(sortedEscalationsArray);
                setMyCasesDetails(sortedEscalationsArray);
                if (!(MyEscalationsState === null || MyEscalationsState === void 0 ? void 0 : MyEscalationsState.InProgress))
                    setLoading(false);
            }
            else if ((MyEscalationsState === null || MyEscalationsState === void 0 ? void 0 : MyEscalationsState.Error) &&
                ((_a = MyEscalationsState === null || MyEscalationsState === void 0 ? void 0 : MyEscalationsState.Error) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                setLoading(false);
            }
        }
        catch (error) {
            console.error(error);
        }
    }, [MyEscalationsState.Escalations]);
    React.useEffect(() => {
        if (myEscalationItems !== null &&
            myEscalationItems !== undefined &&
            myEscalationItems.length > 0) {
            setLoading(true);
            const searchedobj = myEscalationItems.filter((elobject) => {
                var _a;
                return ((((_a = searchCriteria.keyword) === null || _a === void 0 ? void 0 : _a.trim().length) == 0 ||
                    Object.values(elobject).some((es) => {
                        var _a;
                        return (es === null || es === void 0 ? void 0 : es.toLowerCase().indexOf((_a = searchCriteria === null || searchCriteria === void 0 ? void 0 : searchCriteria.keyword) === null || _a === void 0 ? void 0 : _a.toLowerCase())) != -1;
                    })) &&
                    ((selections === null || selections === void 0 ? void 0 : selections.length) == 0 ||
                        (selections === null || selections === void 0 ? void 0 : selections.every((criteria) => {
                            if (criteria.field == "customerName" &&
                                criteria.values !== undefined)
                                return criteria.values.includes(elobject.customerName || "undefined");
                            else if (criteria.field == "product" &&
                                criteria.values !== undefined)
                                return criteria.values.includes(elobject.product || "undefined");
                            else if (criteria.field == "status" &&
                                criteria.values !== undefined)
                                return criteria.values.includes(elobject.status || "undefined");
                            else if (criteria.field == "supportRequestNumber" &&
                                criteria.values !== undefined)
                                return criteria.values.includes(elobject.supportRequestNumber || "undefined");
                            else if (criteria.field == "escalationFlowType" &&
                                criteria.values !== undefined)
                                return criteria.values.includes(elobject.escalationFlowType || "undefined");
                        }))));
            });
            setMyCasesDetails(searchedobj);
            setLoading(false);
        }
    }, [searchCriteria, selections]);
    const detailsInfoLoad = (escalationRequestNumber) => {
        setDetailsview(true);
        SetMyCasesView(false);
        SetSelectedEscalationRequestNumber(escalationRequestNumber);
    };
    function renderSubHeader() {
        return (_jsx(ElxText, Object.assign({ "data-testid": "subtitle", className: "MyEscalationsHeaderText", "aria-label": " Track your escalations and advocate on behalf of your customers" }, { children: "Track your escalations and advocate on behalf of your customers" })));
    }
    function renderHeader() {
        return (_jsx("h1", Object.assign({ style: { fontSize: "x-large", marginTop: 0 }, className: "MyEscalationsHeader" }, { children: "My Cases" })));
    }
    return (_jsxs("div", Object.assign({ className: useEfdContainerStyles }, { children: [(MyEscalationsState.MyCasesViewDefault || detailsview == false) && (_jsx(ElxContainer, Object.assign({ "data-testid": "mycasescontainer", headerText: getLocalSpecificString("myEscalationsHeader"), fillBackground: true, subHeaderText: getLocalSpecificString("myEscalationsSubHeader"), headerContent: _jsx("div", { children: _jsx(ElxActionButton, { "data-testid": "createescalation", iconProps: { iconName: "Add" }, text: "Create an escalation", href: "/escalation" }) }), onRenderSubHeader: renderSubHeader, onRenderHeader: renderHeader, className: headerStyle }, { children: _jsxs(Stack, { children: [_jsxs(Stack.Item, Object.assign({ tokens: topStack }, { children: [_jsx("h2", Object.assign({ style: { margin: 0 } }, { children: "My escalations" })), loading && _jsx(Spinner, { size: SpinnerSize.large })] })), loading == false && (_jsx(Stack.Item, Object.assign({ className: "searchAndFilter", "data-testid": "searchandfilter" }, { children: _jsxs(Stack, Object.assign({ horizontal: true, horizontalAlign: "start", tokens: { childrenGap: 10 } }, { children: [_jsx(Stack.Item, { children: _jsx(ElxSearchBox, { "aria-label": "searchbox", "data-testid": "searchbox", placeholder: getLocalSpecificString("mycasesSearchPlaceHolder"), size: SearchBoxSize.Medium, onChange: (value) => setSearchCriteria(value) }) }), _jsx(Stack.Item, { children: _jsx(ElxFilter, { "aria-label": "filterOptions", options: filterOptions, styles: { root: { width: 400 } }, selections: selections, onChange: setSelections }) })] })) }))), loading == false && (myCasesDetails === null || myCasesDetails === void 0 ? void 0 : myCasesDetails.length) == 0 && (
                        //test
                        _jsxs("div", Object.assign({ className: "norecordsDisplayContainer" }, { children: [_jsx(Icon, { className: "norecordsImage", imageProps: { src: NoEscalationImage } }), _jsx(Label, Object.assign({ className: "noRecordsFontDisplay" }, { children: "No escalations to display." })), _jsx(Label, { children: "Your submitted escalations will be shown here. Create an escalation to get started" }), _jsx(ElxNavLink, Object.assign({ tabIndex: -1, to: getLocalSpecificString("myescalationsLink") }, { children: _jsx(ElxPrimaryButton, { "data-testid": "createescalation", text: "Create an escalation", href: "/escalation", className: "norecordsImage" }) }))] }))), myCasesDetails && myCasesDetails.length > 0 && (_jsx(Stack.Item, Object.assign({ tokens: midStack }, { children: _jsx(ElxTable, { "data-testid": "mycaseslist", className: "EscalationsList", selectionMode: SelectionMode.none, items: myCasesDetails, columns: columns }) })))] }) }))), !MyEscalationsState.MyCasesViewDefault && detailsview && (_jsx(DetailsPage, { showDetails: detailsview, setShowDetails: setDetailsview }))] })));
};
export default MyEscalations;
