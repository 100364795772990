import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ElxNavLink } from "@elixir/fx";
import { Image } from "@fluentui/react";
import MSLogoImage from "../../images/footerImage.png";
import "./FooterStyle";
import { ElxText } from "@elixir/components";
const MSLogoProps = {
    src: MSLogoImage,
};
const MSLogo = () => {
    return (_jsx(Image, Object.assign({ "aria-label": "MicrosoftLogo" }, MSLogoProps, { alt: "Microsoft Logo", className: "shell-footer-logo" })));
};
const Footer = () => {
    return (_jsx("footer", Object.assign({ className: "shell-footer" }, { children: _jsxs("div", Object.assign({ className: "shell-footer-content" }, { children: [_jsxs("div", Object.assign({ className: "shell-footer-links" }, { children: [_jsx(ElxText, Object.assign({ className: "shell-footer-link", "aria-label": "MicrosoftConfidential", nowrap: true }, { children: "Microsoft Confidential" })), _jsx(ElxText, Object.assign({ className: "shell-footer-link" }, { children: "|" })), _jsx(ElxNavLink, Object.assign({ id: "privacyNoticelabel", "aria-label": "PrivacyNotice", to: "https://go.microsoft.com/fwlink/?LinkId=518021", target: "_blank", rel: "noopener noreferrer", className: "shell-footer-link" }, { children: "Privacy Notice" })), _jsx(ElxText, Object.assign({ className: "shell-footer-link" }, { children: "|" })), _jsx(ElxNavLink, Object.assign({ "aria-labelledby": "privacyNoticelabel", to: "https://privacy.microsoft.com/en-US/data-privacy-notice", target: "_blank", rel: "noopener noreferrer", className: "shell-footer-link" }, { children: "Data Protection Notice" }))] })), _jsx(MSLogo, {})] })) })));
};
export default Footer;
