export const CONTAINER_WIDTH = "45rem";
export const CONTAINER_WIDTH_VERTICAL = "25rem";
export const COMPONENT_WIDTH = "40rem";
export const COMPONENT_WIDTH_VERTICAL = "20rem";
export const COMPONENT_LABEL_WIDTH = "19rem";
export const COMPONENT_LABEL_MARGIN_RIGHT = "1rem";
export const COMPONENT_LABEL_WIDTH_VERTICAL = "19rem";
export const COMPONENT_EDITOR_WIDTH = "39.87rem";
export const COMPONENT_EDITOR_MINHEIGHT = "11rem";
export const BREAKPOINT = 1000;
