import { getCurrentUser } from "@elixir/auth";
import { FlowTypeSelector, } from "../../../models";
export const enrichCommercialCase = (commercialFlowContext, flowType, flowTypeText) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13;
    const date = new Date();
    // Enriching the object
    const commercialObject = {
        FlowType: flowTypeText,
        // TO DO - Product field is not multiselect
        isEU: commercialFlowContext.isEU,
        TenantIds: (_a = commercialFlowContext.EscalationDetails.tenantIds) === null || _a === void 0 ? void 0 : _a.map((item) => item.text),
        Products: [
            {
                productName: (_c = (_b = commercialFlowContext.EscalationDetails.affectedProduct) === null || _b === void 0 ? void 0 : _b.title) !== null && _c !== void 0 ? _c : "",
                productId: (_e = (_d = commercialFlowContext.EscalationDetails.affectedProduct) === null || _d === void 0 ? void 0 : _d.id) !== null && _e !== void 0 ? _e : 0,
            },
        ],
        AdditionalProducts: commercialFlowContext.EscalationDetails.additonalAffectedProducts.map((x) => {
            return { productName: x.title, productId: x.id };
        }),
        AdditionalPrograms: commercialFlowContext.EscalationDetails.additionalAffectedPrograms.map((x) => {
            return { programName: x.text, programId: x.id };
        }),
        AgreementDetails: {
            LicenseAgreementName: commercialFlowContext.EscalationDetails.purchasingChannel.text,
            LicenseAgreementNumber: commercialFlowContext.EscalationDetails.enrollmentNumber,
        },
        SubscriptionDetails: {
            AzureSubscriptionId: commercialFlowContext.EscalationDetails.azureSubscriptionID ||
                undefined,
            AzureSubscriptionTypeName: commercialFlowContext.EscalationDetails.azureSubscriptionType.text ||
                undefined,
        },
        TenantInfo: {
            TenantName: commercialFlowContext.EscalationDetails.tenantName || undefined,
            TenantTypeName: commercialFlowContext.EscalationDetails.tenantType.text || undefined,
        },
        StakeHolderEmails: commercialFlowContext.BasicDetails.additionalInternalStakeholders.map((user) => user.account.toLowerCase()),
        CaseParentProductQuestionaire: commercialFlowContext.EscalationDetails.caseParentProductQuestionnaire !=
            undefined
            ? [
                commercialFlowContext.EscalationDetails
                    .caseParentProductQuestionnaire,
            ]
            : undefined,
        PublicRelationsFlag: undefined,
        IsCaseOutsideOfGetHelp: ((_f = commercialFlowContext.EscalationDetails.supportTicketIds) === null || _f === void 0 ? void 0 : _f.length) > 0,
        CommunicateWithCustomerOrPartner: ((_h = (_g = commercialFlowContext.BasicDetails.isDirectCommunicationRequired) === null || _g === void 0 ? void 0 : _g.key) === null || _h === void 0 ? void 0 : _h.trim().toLocaleLowerCase()) == "yes"
            ? true
            : false !== null && false !== void 0 ? false : false,
        IssueType: flowType == FlowTypeSelector.TechProductIssue ? "Product" : "Program",
        PublicSectorCustomerFlag: undefined,
        PartnerIdNumber: (_j = commercialFlowContext.BasicDetails.partner) === null || _j === void 0 ? void 0 : _j.partnerId,
        IsServiceIncidentNumberRelated: ((_k = commercialFlowContext.EscalationDetails.serviceIncidentNumber) === null || _k === void 0 ? void 0 : _k.length) > 0,
        ServiceIncidentNumber: commercialFlowContext.EscalationDetails.serviceIncidentNumber ||
            undefined,
        IsACEManaged: undefined,
        BusinessImpact: {
            DesiredOutcomeDescription: (_l = commercialFlowContext.EscalationDetails.desiredOutcome) !== null && _l !== void 0 ? _l : "NA",
            DesiredOutcomeDescriptionHTML: (_m = commercialFlowContext.EscalationDetails.desiredOutcome) !== null && _m !== void 0 ? _m : "NA",
            BusinessImpactDescription: (_o = commercialFlowContext.EscalationDetails.businessImpact) !== null && _o !== void 0 ? _o : "NA",
            BusinessImpactDescriptionHTML: (_p = commercialFlowContext.EscalationDetails.businessImpact) !== null && _p !== void 0 ? _p : "NA",
            BusinessRisk: commercialFlowContext.EscalationDetails.businessRisk.text || undefined,
            SecurityAndPrivacyThreatFlag: commercialFlowContext.EscalationDetails.securityAndPrivacyThreatFlag,
            ComplianceFlag: commercialFlowContext.EscalationDetails.complianceFlag,
            DeploymentBlocker: commercialFlowContext.EscalationDetails.businessRisk.key ==
                "deployment" ||
                commercialFlowContext.EscalationDetails.businessRisk.key ==
                    "postDeploymentUsage"
                ? {
                    DeploymentBlockerFlag: commercialFlowContext.EscalationDetails.businessRisk.key ==
                        "deployment",
                    PostDeploymentUsageBlockerFlag: commercialFlowContext.EscalationDetails.businessRisk.key ==
                        "postDeploymentUsage",
                    DeploymentDate: commercialFlowContext.EscalationDetails.deploymentDate != "" &&
                        commercialFlowContext.EscalationDetails.deploymentDate !=
                            undefined
                        ? new Date(commercialFlowContext.EscalationDetails.deploymentDate)
                        : undefined,
                    EstimatedActiveUsers: commercialFlowContext.EscalationDetails.impactedUsers != ""
                        ? parseInt(commercialFlowContext.EscalationDetails.impactedUsers)
                        : undefined,
                    EstimatedActiveUsersPercentage: commercialFlowContext.EscalationDetails.impactedUsers != ""
                        ? parseFloat(((parseFloat(commercialFlowContext.EscalationDetails.impactedUsers) /
                            parseFloat(commercialFlowContext.EscalationDetails.totalUsers)) *
                            100).toFixed(4))
                        : undefined,
                    EstimatedMonthlyUsage: commercialFlowContext.EscalationDetails.impactedAmount != ""
                        ? parseFloat(commercialFlowContext.EscalationDetails.impactedAmount)
                        : undefined,
                    EstimatedMonthlyUsagePercentage: commercialFlowContext.EscalationDetails.impactedAmount != ""
                        ? parseFloat(((parseFloat(commercialFlowContext.EscalationDetails
                            .impactedAmount) /
                            parseFloat(commercialFlowContext.EscalationDetails.totalAmount)) *
                            100).toFixed(4))
                        : undefined,
                    SuccessEngagementID: commercialFlowContext.EscalationDetails.engagementID ||
                        undefined,
                }
                : undefined,
            WorkStoppageFlag: commercialFlowContext.EscalationDetails.businessRisk.key ==
                "businessDownorWork",
        },
        SalesBlocker: commercialFlowContext.EscalationDetails.businessRisk.key == "sales"
            ? {
                OpportunityId: commercialFlowContext.EscalationDetails.opportunityID,
                RevenueRiskAmount: commercialFlowContext.EscalationDetails.impactedAmount != ""
                    ? parseFloat(commercialFlowContext.EscalationDetails.impactedAmount)
                    : undefined,
                ImpactedRevenuePercentage: commercialFlowContext.EscalationDetails.impactedAmount != ""
                    ? parseFloat(((parseFloat(commercialFlowContext.EscalationDetails.impactedAmount) /
                        parseFloat(commercialFlowContext.EscalationDetails.totalAmount)) *
                        100).toFixed(4))
                    : undefined,
                SalesAccountNumber: "",
                SalesBlockerFlag: commercialFlowContext.EscalationDetails.businessRisk.key ==
                    "sales",
                SalesPhaseId: 1,
            }
            : undefined,
        AccountInfo: {
            MSSalesId: (_q = commercialFlowContext.BasicDetails.customer) === null || _q === void 0 ? void 0 : _q.msSalesID,
            AccountId: (_r = commercialFlowContext.BasicDetails.customer) === null || _r === void 0 ? void 0 : _r.accountNumber,
            TPID: (_s = commercialFlowContext.BasicDetails.customer) === null || _s === void 0 ? void 0 : _s.tpid,
            CompanyName: (_t = commercialFlowContext.BasicDetails.customer) === null || _t === void 0 ? void 0 : _t.companyName,
        },
        SupportRequestNumber: (_u = commercialFlowContext.EscalationDetails.supportTicketIds) === null || _u === void 0 ? void 0 : _u.map((item) => item.text).join(","),
        PremierCaseRedirects: {
            IsCasePremier: commercialFlowContext.BasicDetails.isCSAMSelected.text,
        },
        IsConfidential: undefined,
        IsCovid19RelatedIssue: undefined,
        Covid19ScenarioName: undefined,
        // Validation needed
        IsExecutive: undefined,
        DocumentType: undefined,
        EscalationRequestNumber: undefined,
        UEMTrackingId: undefined,
        SubmitterInfo: {
            SubmitterEmail: getCurrentUser().account,
            SubmitterFirstName: getCurrentUser().displayName.split(" ")[0],
            SubmitterLastName: (_v = getCurrentUser().displayName.split(" ")[1]) !== null && _v !== void 0 ? _v : "",
            AADObjectId: getCurrentUser().oid,
            LogOnBehalfOf: commercialFlowContext.BasicDetails.submitterOnBehalfOfEmail,
        },
        CustomerInfo: {
            CustomerFirstName: commercialFlowContext.BasicDetails.customerFirstName,
            CustomerLastName: commercialFlowContext.BasicDetails.customerLastName,
            CustomerEmail: commercialFlowContext.BasicDetails.customerEmail,
            CustomerPhone: commercialFlowContext.BasicDetails.customerPhone,
            CustomerSegmentName: ((_w = commercialFlowContext.BasicDetails.customerOrPartnerSelection) === null || _w === void 0 ? void 0 : _w.key) ==
                "partner"
                ? "Partner"
                : "Commercial",
            // This is not an input in any form
            PreferredLanguage: "en-us",
        },
        Language: {
            alpha2: "en-us",
            name: "en-us", //Not populated anywhere else hence populated it to default value
        },
        NotificationPreference: undefined,
        EscalationType: 1,
        Description: (_x = commercialFlowContext.EscalationDetails.issueDescription) !== null && _x !== void 0 ? _x : "N/A",
        DescriptionHtml: (_y = commercialFlowContext.EscalationDetails.issueDescription) !== null && _y !== void 0 ? _y : "N/A",
        IssueOutcome: (_z = commercialFlowContext.EscalationDetails.desiredOutcome) !== null && _z !== void 0 ? _z : "N/A",
        Attachments: commercialFlowContext.EscalationDetails.attachments.map((x) => {
            var _a;
            const byteArray = new Uint8Array(atob((_a = x.contentBase64) !== null && _a !== void 0 ? _a : "")
                .split("")
                .map(function (char) {
                return char.charCodeAt(0);
            }));
            return {
                FileName: x.fileName,
                ContentBytes: Array.from(byteArray),
                UniqueIdentifier: crypto.randomUUID(),
                FileSize: x.size,
                ContentType: x.contentType,
            };
        }),
        CreatedOn: date.toISOString(),
        CaseOwner: undefined,
        Status: undefined,
        StatusText: undefined,
        CaseSourceName: (_0 = commercialFlowContext.EscalationDetails.escalationSource.text) !== null && _0 !== void 0 ? _0 : "Standard",
        OriginalEscalationDateTime: commercialFlowContext.EscalationDetails.escalationDate,
    };
    if (commercialFlowContext.BasicDetails.additionalStakeholdersGroupsDLs &&
        commercialFlowContext.BasicDetails.additionalStakeholdersGroupsDLs.length >
            0) {
        const uniqueStakeholders = new Set([
            ...((_1 = commercialObject.StakeHolderEmails) !== null && _1 !== void 0 ? _1 : []),
            ...commercialFlowContext.BasicDetails.additionalStakeholdersGroupsDLs.map((x) => x.text.toLowerCase()),
        ]);
        commercialObject.StakeHolderEmails = Array.from(uniqueStakeholders);
    }
    if (flowType == FlowTypeSelector.TechProductIssue) {
        commercialObject.Products = [
            {
                productId: parseInt(commercialFlowContext.EscalationDetails.component.key.toString()),
                productName: (_2 = commercialFlowContext.EscalationDetails.component.title) !== null && _2 !== void 0 ? _2 : "",
                parentProductId: parseInt(commercialFlowContext.EscalationDetails.parentProduct.key.toString()),
                parentProductName: commercialFlowContext.EscalationDetails.parentProduct.text,
                isPrimary: true,
            },
        ];
    }
    if (flowType === FlowTypeSelector.ELP) {
        commercialObject.IsExecutive = true;
        commercialObject.CaseSourceName =
            (_3 = commercialFlowContext.EscalationDetails.elpEscalationSource.text) !== null && _3 !== void 0 ? _3 : "Executive";
        commercialObject.BusinessImpact = Object.assign(Object.assign({}, commercialObject.BusinessImpact), { DesiredOutcomeDescription: (_4 = commercialFlowContext.EscalationDetails.importNotes) !== null && _4 !== void 0 ? _4 : "N/A", DesiredOutcomeDescriptionHTML: (_5 = commercialFlowContext.EscalationDetails.importNotes) !== null && _5 !== void 0 ? _5 : "N/A" });
        if (((_6 = commercialFlowContext.EscalationDetails.issueArea) === null || _6 === void 0 ? void 0 : _6.key) === "product") {
            commercialObject.IssueType = "Product";
            commercialObject.Products = [
                {
                    productName: (_8 = (_7 = commercialFlowContext.EscalationDetails.affectedProduct) === null || _7 === void 0 ? void 0 : _7.title) !== null && _8 !== void 0 ? _8 : "",
                    productId: (_10 = (_9 = commercialFlowContext.EscalationDetails.affectedProduct) === null || _9 === void 0 ? void 0 : _9.id) !== null && _10 !== void 0 ? _10 : 0,
                    isPrimary: true,
                },
            ];
        }
        else {
            commercialObject.Products = [];
        }
        commercialObject.CustomerInfo = Object.assign(Object.assign({}, commercialObject.CustomerInfo), { CustomerSegmentName: ((_11 = commercialFlowContext.BasicDetails.isCustomerOrCommercialCase) === null || _11 === void 0 ? void 0 : _11.key) ===
                "consumer"
                ? "Consumer"
                : "Commercial" });
        if (commercialObject.CustomerInfo.CustomerSegmentName === "Commercial") {
            if (((_12 = commercialFlowContext.BasicDetails.customerOrPartnerSelection) === null || _12 === void 0 ? void 0 : _12.key) ===
                "partner") {
                commercialObject.CustomerInfo.CustomerSegmentName = "Partner";
                commercialObject.AccountInfo = Object.assign(Object.assign({}, commercialObject.AccountInfo), { CompanyName: (_13 = commercialFlowContext.BasicDetails.partner) === null || _13 === void 0 ? void 0 : _13.partnerName });
            }
        }
    }
    return commercialObject;
};
