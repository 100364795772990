import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { createContext } from "react";
import { get, has } from "lodash";
const StringsContext = createContext({
    data: {},
    locale: "en",
});
export const useStringsContext = () => {
    return React.useContext(StringsContext);
};
export const StringsContextProvider = (props) => {
    const value = {
        data: props.data,
        locale: props.locale,
    };
    return (_jsx(StringsContext.Provider, Object.assign({ value: value }, { children: props.children })));
};
export const useLocaleStrings = () => {
    const strings = useStringsContext();
    return {
        getLocalSpecificString(key) {
            if (has(strings.data, strings.locale)) {
                const localeStrings = strings.data[strings.locale];
                if (has(localeStrings, key)) {
                    return get(localeStrings, key);
                }
                throw new Error(`Strings data does not have a definition for: "${key}"`);
            }
            throw new Error(`Strings locale does not have a definition for: "${strings.locale}"`);
        },
    };
};
