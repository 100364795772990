import { getElxTheme, px2rem, MessageColors } from "@elixir/styling";
import { HighContrastSelector } from "@fluentui/react";
export function usePeoplePickerStyles({ theme, }) {
    const { spacing } = getElxTheme(theme);
    const elxTheme = getElxTheme(theme);
    return {
        root: [
            {
                selectors: {
                    '[data-icon-name="ChevronDown"]': {
                        marginTop: elxTheme.constants.peoplePicker.chevronMargin,
                    },
                },
            },
            "elx-people-picker",
            {
                position: "relative",
            },
        ],
        displayValue: [
            "elx-people-picker-value",
            {
                position: "absolute",
                top: px2rem(2),
                bottom: px2rem(2),
                left: spacing.spaceHalf,
                background: theme.palette.white,
                display: "flex",
                alignItems: "center",
            },
        ],
        emptyText: ["elx-people-picker-empty"],
        caretDown: {
            position: "absolute",
            right: 0,
            top: 0,
            bottom: 0,
            paddingTop: spacing.spaceHalf,
            paddingRight: spacing.spaceHalf,
            cursor: "pointer",
            pointerEvents: "all",
        },
        callout: ["elx-picker-callout"],
        list: [
            "elx-people-picker-list",
            {
                padding: 0,
                margin: 0,
                maxHeight: px2rem(300),
                height: "100%",
                overflow: "hidden",
                overflowY: "auto",
            },
        ],
        listItem: [
            "elx-people-picker-listitem",
            {
                display: "block",
                listStyleType: "none",
                padding: spacing.spaceHalf,
                cursor: "pointer",
                "&.selected": {
                    [HighContrastSelector]: {
                        outline: "auto",
                    },
                    background: theme.palette.neutralLight,
                },
                "&:hover": {
                    background: theme.palette.neutralLighter,
                },
            },
        ],
        searchOption: [
            "elx-people-picker-search-option",
            {
                display: "flex",
                alignItems: "center",
            },
        ],
        searchIcon: [
            "elx-people-picker-search-icon",
            {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: spacing.spaceHalf,
                width: px2rem(24),
                height: px2rem(24),
            },
        ],
        message: [
            "elx-people-picker-message",
            {
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: px2rem(140),
                color: theme.palette.black,
            },
        ],
    };
}
export const ComboBoxStyles = {
    optionsContainerWrapper: {
        minWidth: "300px",
        maxHeight: "300px",
        overflowY: "auto",
    },
};
export const SpinnerStyles = {
    root: {
        marginBottom: "1rem",
    },
};
export const ErrorBadgeStyles = {
    root: {
        color: MessageColors.ErrorIcon,
        fontSize: "20px",
        marginBottom: "1rem",
    },
};
export const chevronIconStyles = {
    root: {
        cursor: "pointer",
        pointerEvents: "all",
    },
};
