import { mergeStyles } from "@fluentui/react";
export const BREAKPOINT = 1000;
export const useEfdContainerStyles = mergeStyles({
    root: {
        "& .container-body": {
            display: "flex",
            flex: "none",
            flexWrap: "wrap",
            flexDirection: "row !important",
            justifyContent: "flex-start",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            paddingBottom: "0rem",
            overflow: "auto",
            "& .tileContainer": {
                display: "flex",
                flexWrap: "wrap",
                textOverflow: "ellipsis",
            },
            [`@media (max-width: ${BREAKPOINT}px)`]: {
                flexFlow: "column nowrap!important",
                overflow: "auto",
                flex: "1",
            },
        },
    },
});
