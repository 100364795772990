var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from "react";
import { ElxContainer, ElxPrimaryButton, ElxSecondaryButton, } from "@elixir/components";
import { ElxNavLink } from "@elixir/fx";
import { Icon, Text, Stack, mergeStyles, Spinner, SpinnerSize, } from "@fluentui/react";
import { useEfdContainerStyles } from "../EscalationSubmittedPage/EscalationSubmittedDetailPage.styles";
import { useLocaleStrings } from "../../library/providers/strings";
import { getEscalationRequestByTrackingId } from "../../services/consumerservice";
const headerStyle = mergeStyles({
    selectors: {
        "& > .elx-container > .container-header > .headerContainer-173 > .container-headings > .container-heading": {
            height: 10,
        },
    },
});
const boardViewparentStackToken = {
    childrenGap: 7,
};
const boardViewstackStyles = {
    root: {
        marginLeft: "10px",
        height: 20,
    },
};
const topStack = { childrenGap: 15 };
function renderSubHeader(subHeader) {
    return (_jsx(Text, Object.assign({ variant: "large", styles: { root: { fontSize: "13px" } } }, { children: subHeader })));
}
export const EscalationSubmittedReviewPage = (props) => {
    const { uemTrackingId, subHeaderTitle, escalationType } = props;
    const { getLocalSpecificString } = useLocaleStrings();
    const loopNum = useRef(0);
    const [escalationRequestNumber, setEscalationRequestNumber] = useState(uemTrackingId);
    const [escalationSubmitted, setEscalationSubmitted] = useState(undefined);
    useEffect(() => {
        if (escalationRequestNumber && loopNum.current < 5) {
            getEscalationRequestNumber();
        }
    }, [uemTrackingId]);
    function sleep(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    const getEscalationRequestNumber = () => __awaiter(void 0, void 0, void 0, function* () {
        const sleepTime = 2000;
        const escalationNumberRequest = {
            TrackingId: escalationRequestNumber,
            EscalationType: escalationType,
            IsEU: props.isEU,
        };
        while (loopNum.current < 50) {
            const escalationNumberResponse = yield getEscalationRequestByTrackingId(escalationNumberRequest);
            if (escalationNumberResponse &&
                escalationNumberResponse.escalationsRequestNumber) {
                setEscalationRequestNumber(escalationNumberResponse.escalationsRequestNumber);
                setEscalationSubmitted(true);
                break;
            }
            else {
                loopNum.current++;
                yield sleep(sleepTime);
            }
        }
        if (loopNum.current === 50)
            setEscalationSubmitted(false);
    });
    const staticData = escalationType == 1
        ? [
            {
                point: "You can check the status, edit and manage escalations on the Tracking page.",
            },
            {
                point: "Learn more about what to expect next on the Self-service page.",
            },
        ]
        : [
            {
                point: "To protect the privacy of customers you will not recieve updates for home user cases.",
            },
            {
                point: "You can check the status, edit and manage escalations on the Tracking page.",
            },
            {
                point: "Learn more about what to expect next on the Self-service page.",
            },
        ];
    return (_jsx("div", Object.assign({ className: useEfdContainerStyles }, { children: _jsx(ElxContainer, Object.assign({ headerText: "Escalation Submission", fillBackground: true, subHeaderText: getLocalSpecificString("EscalationsSubmittedReviewPageSubHeader"), headerContent: _jsx("div", { children: escalationSubmitted !== undefined ? (_jsx(Stack, Object.assign({ tokens: topStack }, { children: _jsxs(Stack, Object.assign({ horizontal: true, styles: boardViewstackStyles, tokens: boardViewparentStackToken }, { children: [_jsx(Stack.Item, { children: escalationSubmitted === true ? (_jsx(Icon, { iconName: "CompletedSolid", style: {
                                        color: "#57A300",
                                        marginTop: "4px",
                                        fontSize: "20px",
                                    } })) : (_jsx(Icon, { iconName: "StatusErrorFull", style: {
                                        color: "#E81123",
                                        marginTop: "4px",
                                        fontSize: "20px",
                                    } })) }), _jsx(Stack.Item, { children: _jsx(Text, Object.assign({ styles: {
                                        root: { fontWeight: "bold", fontSize: "20px" },
                                    } }, { children: escalationSubmitted === true
                                        ? `Escalation ID : ${escalationRequestNumber}`
                                        : "Escalation Creation Failed." })) })] })) }))) : (escalationSubmitted === undefined && (_jsx(Spinner, { size: SpinnerSize.medium, label: "Escalation Creation is in Progress...", labelPosition: "right", styles: { root: { justifyContent: "flex-start" } } }))) }), onRenderSubHeader: () => renderSubHeader(subHeaderTitle), className: headerStyle }, { children: _jsxs("div", { children: [_jsx("div", { children: _jsxs("ul", { children: [escalationSubmitted === false && (_jsxs("li", { children: [getLocalSpecificString("EscalationSubmissionErrorMessage"), _jsx("b", { children: uemTrackingId })] })), staticData.map((item, index) => {
                                    return _jsx("li", { children: item.point }, index);
                                })] }) }), _jsxs("div", { children: [_jsx(ElxNavLink, Object.assign({ tabIndex: -1, to: getLocalSpecificString("CreateEscalationsLink") }, { children: _jsx(ElxSecondaryButton, { text: "Submit another escalation", styles: { root: { marginRight: "1rem" } } }) })), _jsx(ElxNavLink, Object.assign({ tabIndex: -1, to: getLocalSpecificString("myescalationsLink") }, { children: _jsx(ElxPrimaryButton, { text: "Track my escalation", styles: { root: { marginRight: "1rem" } } }) }))] })] }) })) })));
};
export default EscalationSubmittedReviewPage;
