var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import ReactDOM from "react-dom";
import { LoginType, setConfig } from "@elixir/fx";
import { EscalationShell } from "./shell/EscalationShell";
import { RootContextProvider } from "./library/providers/root/RootContextProvider";
import { StringsContextProvider, strings } from "./library/providers/strings";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { MyEscalationsContextProvider } from "./reducers/MyEscalationsContext/MyEscalationsContextProvider";
setConfig({
    authConfig: {
        authentication: "aad",
        clientId: process.env.AppClientID,
        loginType: LoginType.Redirect,
        tenantId: process.env.AppTenantID,
        restrictExternalUsers: true,
        redirectUri: process.env.RedirectURI,
        scopes: (_a = process.env.Scopes) === null || _a === void 0 ? void 0 : _a.split(","),
    },
    applicationTitle: process.env.ApplicationTitle,
    applicationIconName: process.env.ApplicationIconName,
    env: "production",
    buildEnv: "production",
    telemetry: {
        trackInteraction: true,
        trackAuthenticatedId: true,
        settings: {
            config: {
                instrumentationKey: process.env.AppInsightsInstrumentationId,
            },
        },
    },
});
ReactDOM.render(_jsx("div", { children: _jsx(RootContextProvider, { children: _jsx(StringsContextProvider, Object.assign({ locale: "en", data: strings }, { children: _jsx(MyEscalationsContextProvider, { children: _jsx(EscalationShell, {}) }) })) }) }), document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWenpmbVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
