import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import * as React from "react";
import { AzureThemeLight } from "@elixir/styling";
import { ElxShell } from "@elixir/fx";
import { ConsumerContainer } from "../container/ConsumerContainer";
import { ConsumerContextProvider } from "../reducers/ConsumerContext/ConsumerContextProvider";
import { CommercialFlowContextProvider } from "../reducers/CommercialFlowContext/CommercialFlowContextProvider";
import { useShellHeaderStyles, useShellStyles } from "./EscalationShell.style";
import Escalations from "../components/Escalation/Escalations";
import MyEscalations from "../components/MyEscalations/MyEscalations";
import "../images/AzureCXPNew-small-logo.png";
import "./content/branding.css";
import { CommercialContextProvider } from "../reducers/CommercialContext/CommercialContextProvider";
import { CommercialContainer } from "../container/CommercialContainer";
import { FlowTypeSelector } from "../models/Commercial/FlowDetails";
import DetailsPage from "../components/DetailsPage/DetailsPage";
import { MyEscalationsContextProvider, UseMyEscalationsDispatch, UseMyEscalationsState, } from "../reducers/MyEscalationsContext/MyEscalationsContextProvider";
import Help from "../components/Help/Help";
import { useRootContext } from "../library/providers/root/RootContextProvider";
import HomePage from "../components/HomePage/HomePage";
import Footer from "../components/footer/Footer";
import { getDataFromSessionStorage } from "../config/configHelper";
export const EscalationShell = () => {
    const MyEscalationsState = UseMyEscalationsState();
    const { SetMyCasesView } = UseMyEscalationsDispatch();
    const baseModules = [
        {
            name: "homepage",
            env: "production",
            route: {
                index: "HomePage",
                name: "Home",
                iconProps: {
                    iconName: "Home",
                },
                path: "/home",
                isRouteable: true,
                exact: true,
                inSidebar: true,
                component: () => _jsx(HomePage, {}),
                subRoutes: [
                    {
                        inSidebar: false,
                        exact: true,
                        index: "Escalation",
                        name: "GetHelp",
                        breadcrumbLabel: "GetHelp",
                        path: "/escalation",
                        component: () => _jsx(Escalations, {}),
                    },
                    {
                        index: "MyEscalationsdetails",
                        name: "My Escalations details",
                        breadcrumbLabel: "My Escalations details",
                        iconProps: {
                            iconName: "ViewDashboard",
                        },
                        path: "/myescalationsdetails/:escalationId",
                        isRouteable: true,
                        exact: true,
                        inSidebar: false,
                        component: () => (_jsx(MyEscalationsContextProvider, { children: _jsx(DetailsPage, {}) })),
                    },
                ],
            },
        },
        {
            name: "myescalations",
            env: "production",
            route: {
                index: "MyEscalations",
                name: "My Cases",
                iconProps: {
                    iconName: "StackIndicator",
                },
                breadcrumbLabel: "My Cases",
                path: "/myescalations",
                onClick: (props) => {
                    SetMyCasesView(true);
                },
                isRouteable: true,
                exact: true,
                inSidebar: true,
                component: () => _jsx(MyEscalations, {}),
                subRoutes: [
                    {
                        inSidebar: false,
                        exact: true,
                        index: "Escalation",
                        name: "GetHelp",
                        breadcrumbLabel: "GetHelp",
                        path: "/escalation",
                        component: () => _jsx(Escalations, {}),
                    },
                    {
                        index: "MyEscalationsdetails",
                        name: "My Escalations details",
                        breadcrumbLabel: "My Escalations details",
                        iconProps: {
                            iconName: "ViewDashboard",
                        },
                        path: "/myescalationsdetails/:escalationId",
                        isRouteable: true,
                        exact: true,
                        inSidebar: false,
                        component: () => _jsx(DetailsPage, {}),
                    },
                ],
            },
        },
        {
            name: "escalation",
            env: "production",
            route: {
                index: "Escalation",
                name: "Submit New Escalation",
                breadcrumbLabel: "New Escalation",
                iconProps: {
                    iconName: "Add",
                },
                path: "/escalation",
                isRouteable: true,
                exact: true,
                inSidebar: true,
                component: () => _jsx(Escalations, {}),
                subRoutes: [
                    {
                        inSidebar: false,
                        exact: true,
                        index: "escalations",
                        name: "Escalations",
                        breadcrumbLabel: "Home User",
                        path: "/escalation/consumer",
                        // eslint-disable-next-line react/display-name
                        component: () => (_jsx(ConsumerContextProvider, { children: _jsx(ConsumerContainer, {}) })),
                    },
                    {
                        inSidebar: false,
                        exact: true,
                        index: "techproductissue",
                        name: "Escalations",
                        breadcrumbLabel: "Technical Product Issues",
                        path: "/escalation/technicalproductissues",
                        // eslint-disable-next-line react/display-name
                        component: () => (_jsx(CommercialContextProvider, { children: _jsx(CommercialFlowContextProvider, { children: _jsx(CommercialContainer, { FlowType: FlowTypeSelector.TechProductIssue }) }) })),
                    },
                    {
                        inSidebar: false,
                        exact: true,
                        index: "escalations",
                        name: "Escalations",
                        breadcrumbLabel: "Special Programs - By Referral Only",
                        path: "/escalation/specialprograms",
                        // eslint-disable-next-line react/display-name
                        component: () => (_jsx(CommercialContextProvider, { children: _jsx(CommercialFlowContextProvider, { children: _jsx(CommercialContainer, { FlowType: FlowTypeSelector.SpecialProgram }) }) })),
                    },
                    {
                        inSidebar: false,
                        exact: true,
                        index: "pssst",
                        name: "Escalations",
                        breadcrumbLabel: "Commerce, Volume Licensing and Post Sales Seller Support",
                        path: "/escalation/pssst",
                        // eslint-disable-next-line react/display-name
                        component: () => (_jsx(CommercialContextProvider, { children: _jsx(CommercialFlowContextProvider, { children: _jsx(CommercialContainer, { FlowType: FlowTypeSelector.PSST }) }) })),
                    },
                    {
                        inSidebar: false,
                        exact: true,
                        index: "escalations",
                        name: "Escalations",
                        breadcrumbLabel: "LicenseQ",
                        path: "/escalation/licenseq",
                        // eslint-disable-next-line react/display-name
                        component: () => (_jsx(CommercialContextProvider, { children: _jsx(CommercialFlowContextProvider, { children: _jsx(CommercialContainer, { FlowType: FlowTypeSelector.License }) }) })),
                    },
                    {
                        inSidebar: false,
                        exact: true,
                        index: "pca",
                        name: "Escalations",
                        breadcrumbLabel: "Privacy-Compliance Audit",
                        path: "/escalation/pca",
                        // eslint-disable-next-line react/display-name
                        component: () => (_jsx(CommercialContextProvider, { children: _jsx(CommercialFlowContextProvider, { children: _jsx(CommercialContainer, { FlowType: FlowTypeSelector.PCA }) }) })),
                    },
                    {
                        inSidebar: false,
                        exact: true,
                        index: "ecr",
                        name: "Escalations",
                        breadcrumbLabel: "Executive Customer Relations",
                        path: "/escalation/ecr",
                        // eslint-disable-next-line react/display-name
                        component: () => (_jsx(CommercialContextProvider, { children: _jsx(CommercialFlowContextProvider, { children: _jsx(CommercialContainer, { FlowType: FlowTypeSelector.ELP }) }) })),
                    },
                    {
                        inSidebar: false,
                        exact: true,
                        index: "cpp",
                        name: "Escalations",
                        breadcrumbLabel: "Customer or Partner Programs",
                        path: "/escalation/cpp",
                        // eslint-disable-next-line react/display-name
                        component: () => (_jsx(CommercialContextProvider, { children: _jsx(CommercialFlowContextProvider, { children: _jsx(CommercialContainer, { FlowType: FlowTypeSelector.CPP }) }) })),
                    },
                ],
            },
        },
    ];
    const getHeaderActions = () => {
        return {
            actions: [
                {
                    name: "Help",
                    iconProps: {
                        iconName: "Unknown",
                        styles: { root: { fontSize: "23px", height: "13px" } },
                    },
                    buttonProps: {
                        menuProps: {
                            items: [
                                {
                                    key: "title",
                                    text: "Help",
                                    onRender: (item) => _jsx(Help, {}),
                                },
                            ],
                        },
                    },
                },
            ],
        };
    };
    const [getHelpModules, setGetHelpModules] = React.useState([]);
    const { privileges: { isCLoB }, appConfiguration: config, } = useRootContext(); //change role as needed
    const [modules, setModules] = React.useState(baseModules);
    React.useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        setGetHelpModules([
            {
                name: "caseManagementResources",
                env: "production",
                route: {
                    index: "caseManagementResources",
                    name: "Case management resources",
                    iconProps: {
                        iconName: "AddWork",
                    },
                    isRouteable: false,
                    exact: true,
                    inSidebar: true,
                    path: "/myescalations",
                    subRoutes: [
                        {
                            inSidebar: ((_a = getDataFromSessionStorage("isEUEnabled")) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                                "false",
                            isRouteable: true,
                            isExternal: true,
                            exact: true,
                            index: "Internal Search",
                            name: "Internal Search",
                            breadcrumbLabel: "caseManagementResources",
                            path: config === null || config === void 0 ? void 0 : config.rm2SearchPageLink,
                        },
                        {
                            inSidebar: ((_b = getDataFromSessionStorage("isEUEnabled")) === null || _b === void 0 ? void 0 : _b.toLowerCase()) ===
                                "false",
                            isRouteable: true,
                            isExternal: true,
                            exact: true,
                            index: "Summary",
                            name: "Summary",
                            breadcrumbLabel: "caseManagementResources",
                            path: config === null || config === void 0 ? void 0 : config.rm2SummaryPageLink,
                        },
                        {
                            inSidebar: ((_c = getDataFromSessionStorage("isEUEnabled")) === null || _c === void 0 ? void 0 : _c.toLowerCase()) ===
                                "true",
                            isRouteable: true,
                            isExternal: true,
                            exact: true,
                            index: "Global Internal Search",
                            name: "Global Internal Search",
                            breadcrumbLabel: "caseManagementResources",
                            path: config === null || config === void 0 ? void 0 : config.rm2SearchPageLink,
                        },
                        {
                            inSidebar: ((_d = getDataFromSessionStorage("isEUEnabled")) === null || _d === void 0 ? void 0 : _d.toLowerCase()) ===
                                "true",
                            isRouteable: true,
                            isExternal: true,
                            exact: true,
                            index: "EU Internal Search",
                            name: "EU Internal Search",
                            breadcrumbLabel: "caseManagementResources",
                            path: config === null || config === void 0 ? void 0 : config.rm2EuSearchPageLink,
                        },
                        {
                            inSidebar: ((_e = getDataFromSessionStorage("isEUEnabled")) === null || _e === void 0 ? void 0 : _e.toLowerCase()) ===
                                "true",
                            isRouteable: true,
                            isExternal: true,
                            exact: true,
                            index: "Global Summary",
                            name: "Global Summary",
                            breadcrumbLabel: "caseManagementResources",
                            path: config === null || config === void 0 ? void 0 : config.rm2SummaryPageLink,
                        },
                        {
                            inSidebar: ((_f = getDataFromSessionStorage("isEUEnabled")) === null || _f === void 0 ? void 0 : _f.toLowerCase()) ===
                                "true",
                            isRouteable: true,
                            isExternal: true,
                            exact: true,
                            index: "EU Summary",
                            name: "EU Summary",
                            breadcrumbLabel: "caseManagementResources",
                            path: config === null || config === void 0 ? void 0 : config.rm2EuSummaryPageLink,
                        },
                    ],
                },
            },
        ]);
    }, [config]);
    React.useEffect(() => {
        if (isCLoB) {
            setModules([...baseModules, ...getHelpModules]);
        }
    }, [isCLoB, getHelpModules]);
    return (_jsxs(_Fragment, { children: [_jsxs(ElxShell, Object.assign({ modules: modules, theme: AzureThemeLight, headerStyles: useShellHeaderStyles(AzureThemeLight), styles: useShellStyles(AzureThemeLight), defaultPath: "./escalation", useNewSidebar: true, useCubixNavHeader: true, enableExternalRoutes: true }, { children: [_jsx(ElxShell.Branding, { onRenderLogo: () => _jsx("div", { className: "azureLogo" }), applicationName: "GetHelp" }), _jsx(ElxShell.Actions, Object.assign({}, getHeaderActions()))] })), _jsx(Footer, {})] }));
};
export default EscalationShell;
