import { jsx as _jsx } from "react/jsx-runtime";
import React, { useReducer } from "react";
import { ConsumerContextReducer } from "./ConsumerContextReducer";
import { getConsumerMetadata, submitConsumerEscalation, updateConsumerBasicDetails, updateConsumerBasicDetailsValidationStatus, updateConsumerEscalationDetails, updateConsumerEscalationDetailsValidationStatus } from "./ConsumerContextActions";
export const initialState = {
    BasicDetails: {
        submitterName: "",
        submitterEmail: "",
        customerFirstName: "",
        customerLastName: "",
        customerLocation: "",
        haveCustomerEmail: true,
        customerEmail: "",
        microsoftAccountId: "",
        preferredLanguage: "en",
    },
    EscalationDetails: {
        escalationSource: "",
        escalationSourceId: "",
        issueTypeId: "",
        issueType: "",
        productFamilyId: "",
        productFamily: "",
        issueDescription: "",
        supportRequestNumber: "",
        attachments: [],
        declarationCheck: false,
    },
    ConsumerMetadata: {
        countries: [],
        languages: [],
        issueTypes: [],
        productFamilies: [],
        flags: [],
        dfCRoutings: [],
    },
    areBasicDetailsValid: undefined,
    areEscalationDetailsValid: undefined,
};
const ConsumerContextState = React.createContext({});
const ConsumerContextDispatch = React.createContext({});
export function UseConsumerContextState() {
    const context = React.useContext(ConsumerContextState);
    if (context === undefined) {
        throw new Error("UseConsumerContextState must be used within a ConsumerContextProvider");
    }
    return context;
}
export function UseConsumerContextDispatch() {
    const context = React.useContext(ConsumerContextDispatch);
    if (context === undefined) {
        throw new Error("UseConsumerContextDispatch must be used within a ConsumerContextProvider");
    }
    return context;
}
export const ConsumerContextProvider = (props) => {
    const [context, contextDispatch] = useReducer(ConsumerContextReducer, {
        ConsumerContext: initialState,
        InProgress: true,
        Error: "",
        uemTrackingId: undefined,
        SubmitInProgress: false,
        SubmitError: ""
    });
    const getMetadata = () => {
        getConsumerMetadata(contextDispatch);
    };
    const updateBasicDetails = (basicDetails) => {
        updateConsumerBasicDetails(contextDispatch, basicDetails);
    };
    const updateEscalationDetails = (escalationDetails) => {
        updateConsumerEscalationDetails(contextDispatch, escalationDetails);
    };
    const updateBasicDetailsValidationStatus = (isValid) => {
        updateConsumerBasicDetailsValidationStatus(contextDispatch, isValid);
    };
    const updateEscalationDetailsValidationStatus = (isValid) => {
        updateConsumerEscalationDetailsValidationStatus(contextDispatch, isValid);
    };
    const submitEscalation = (payload) => {
        submitConsumerEscalation(contextDispatch, payload);
    };
    const consumerState = {
        ConsumerContext: context.ConsumerContext,
        InProgress: context.InProgress,
        Error: context.Error,
        uemTrackingId: context.uemTrackingId,
        SubmitInProgress: context.SubmitInProgress,
        SubmitError: context.SubmitError
    };
    const consumerDispatch = {
        GetConsumerMetadata: getMetadata,
        UpdateBasicDetails: updateBasicDetails,
        UpdateEscalationDetails: updateEscalationDetails,
        UpdateBasicDetailsValidationStatus: updateBasicDetailsValidationStatus,
        UpdateEscalationDetailsValidationStatus: updateEscalationDetailsValidationStatus,
        SubmitConsumerEscalation: submitEscalation
    };
    return (_jsx(ConsumerContextState.Provider, Object.assign({ value: consumerState }, { children: _jsx(ConsumerContextDispatch.Provider, Object.assign({ value: consumerDispatch }, { children: props.children })) })));
};
