var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCommercialDomainData } from "../../services/commercialservice";
import CommercialContextActionTypeKeys from "./CommercialContextActionTypeKeys";
import { getEngagementInfo, getOpportunityInfo } from "../../services";
export function getCommercialMetadata(dispatch) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(inProgress());
        const response = yield getCommercialDomainData();
        if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.result) {
            const result = response.data.result;
            const countryOptions = result.countries || [];
            const languageOptions = result.languages || [];
            const caseSourceOptions = result.caseSources || [];
            const agreementTypeOptions = result.agreementTypes || [];
            const programOptions = ((_b = result.commercialProductPrograms) === null || _b === void 0 ? void 0 : _b.program) || [];
            const caseParentProductQuestionaire = ((_c = result.commercialProductPrograms) === null || _c === void 0 ? void 0 : _c.caseParentProductQuestionaire) || [];
            const productOptions = (_e = (_d = result.commercialProductPrograms) === null || _d === void 0 ? void 0 : _d.products) !== null && _e !== void 0 ? _e : [];
            const businessRisks = [
                { key: "businessDownorWork", text: "Business Down or Work Stoppage" },
                { key: "deployment", text: "Deployment Blocker" },
                { key: "postDeploymentUsage", text: "Post Deployment Usage Blocker" },
                { key: "sales", text: "Sales Blocker" },
            ];
            const azureSubscriptionTypes = [];
            (_f = result.azureSubscriptionTypes) === null || _f === void 0 ? void 0 : _f.forEach((subscriptionType) => {
                azureSubscriptionTypes.push({
                    key: subscriptionType.azureSubscriptionTypeId,
                    text: subscriptionType.azureSubscriptionTypeName,
                });
            });
            const tenantTypes = [];
            (_g = result.tenantTypes) === null || _g === void 0 ? void 0 : _g.forEach((tenantType) => {
                tenantTypes.push({
                    key: tenantType.tenantTypeId,
                    text: tenantType.tenantTypeName,
                });
            });
            const commercialMetadata = {
                countries: countryOptions,
                languages: languageOptions,
                caseSource: caseSourceOptions,
                agreementTypes: agreementTypeOptions,
                products: productOptions,
                affectedProducts: productOptions,
                affectedPrograms: programOptions,
                programs: programOptions,
                azureSubscriptionTypes: azureSubscriptionTypes,
                tenantTypes: tenantTypes,
                businessRisks: businessRisks,
                questionnaire: caseParentProductQuestionaire,
            };
            dispatch(success(commercialMetadata));
        }
        else {
            dispatch(failure(((_j = (_h = response.data) === null || _h === void 0 ? void 0 : _h.errors[0]) === null || _j === void 0 ? void 0 : _j.Message) ||
                "Error occured while fetching commercial metadata"));
        }
    });
}
export function updateCommercialEnableAcknowledgement(dispatch, enableAcknowledgement) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch({
            payload: enableAcknowledgement,
            type: CommercialContextActionTypeKeys.Update_EnableAcknowledgement,
        });
    });
}
export function getEngagementLookUpInfo(dispatch, engagementRequest, metadata) {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        if (engagementRequest.engagementId != "") {
            dispatch(inProgress());
            const response = yield getEngagementInfo(engagementRequest);
            if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.result) {
                const result = response.data.result;
                const commercialMetadata = Object.assign(Object.assign({}, metadata), { engagementTotalUsers: result.length > 0 ? result[0].estActiveUsers : undefined, engagementTotalAmount: result.length > 0 ? (_b = result[0].estMonthlyUsage) === null || _b === void 0 ? void 0 : _b.toString() : undefined });
                dispatch(success(commercialMetadata));
            }
            else {
                dispatch(failure(((_d = (_c = response.data) === null || _c === void 0 ? void 0 : _c.errors[0]) === null || _d === void 0 ? void 0 : _d.Message) ||
                    "Error occured while fetching engagement lookup data"));
            }
        }
        else {
            const commercialMetadata = Object.assign(Object.assign({}, metadata), { engagementTotalUsers: "", engagementTotalAmount: "" });
            dispatch(success(commercialMetadata));
        }
    });
}
export function getOpportunityLookUpInfo(dispatch, opportunityRequest, metadata) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        if (opportunityRequest.opportunityId != "") {
            dispatch(inProgress());
            const response = yield getOpportunityInfo(opportunityRequest);
            if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.result) {
                const result = response.data.result;
                const commercialMetadata = Object.assign(Object.assign({}, metadata), { opportunityTotalAmount: result.length > 0 ? result[0].revenue : undefined });
                dispatch(success(commercialMetadata));
            }
            else {
                dispatch(failure(((_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.errors[0]) === null || _c === void 0 ? void 0 : _c.Message) ||
                    "Error occured while fetching opportunity lookup data"));
            }
        }
        else {
            const commercialMetadata = Object.assign(Object.assign({}, metadata), { opportunityTotalAmount: "" });
            dispatch(success(commercialMetadata));
        }
    });
}
function inProgress() {
    return {
        payload: true,
        type: CommercialContextActionTypeKeys.Get_CommercialMetadata_InProgress,
    };
}
function success(consumerDomaindata) {
    return {
        payload: consumerDomaindata,
        type: CommercialContextActionTypeKeys.Get_CommercialMetadata_Success,
    };
}
function failure(error) {
    return {
        type: CommercialContextActionTypeKeys.Get_CommercialMetadata_Failure,
        payload: error,
    };
}
