var CommercialFlowContextActionTypeKeys;
(function (CommercialFlowContextActionTypeKeys) {
    CommercialFlowContextActionTypeKeys["Update_BasicDetails"] = "UPDATE_BASICSDETAILS";
    CommercialFlowContextActionTypeKeys["Update_BasicDetailsValidationStatus_Complete"] = "UPDATE_BASICDETAILSVALIDATIONSTATUS_COMPLETE";
    CommercialFlowContextActionTypeKeys["Update_EscalationDetails"] = "UPDATE_ESCALATIONDETAILS";
    CommercialFlowContextActionTypeKeys["Update_EscalationDetails_Complete"] = "UPDATE_ESCALATIONDETAILS_COMPLETE";
    CommercialFlowContextActionTypeKeys["Update_BasicDetailsValidationStatus"] = "UPDATE_BASICDETAILSVALIDATIONSTATUS";
    CommercialFlowContextActionTypeKeys["Update_EscalationDetailsValidationStatus"] = "UPDATE_ESCALATIONSETAILSVALIDATIONSTATUS";
    CommercialFlowContextActionTypeKeys["Update_EscalationDetailsValidationStatus_Complete"] = "UPDATE_ESCALATIONSETAILSVALIDATIONSTATUS_COMPLETE";
    CommercialFlowContextActionTypeKeys["Submit_CommercialEscalation"] = "SUBMIT_COMMERCIAL_ESCALATION";
    CommercialFlowContextActionTypeKeys["Submit_CommercialEscalation_Success"] = "SUBMIT_COMMERCIAL_ESCALATION_SUCCESS";
    CommercialFlowContextActionTypeKeys["Submit_CommercialEscalation_Inprogress"] = "SUBMIT_COMMERCIAL_ESCALATION_INPROGRESS";
    CommercialFlowContextActionTypeKeys["Submit_CommercialEscalation_Failed"] = "SUBMIT_COMMERCIAL_ESCALATION_FAILED";
    CommercialFlowContextActionTypeKeys["Set_BasicDetailsAreValid"] = "SET_BASICDETAILSAREVALID";
    CommercialFlowContextActionTypeKeys["Set_EscalationDetailsAreValid"] = "SET_ESCALATIONDETAILSAREVALID";
})(CommercialFlowContextActionTypeKeys || (CommercialFlowContextActionTypeKeys = {}));
export default CommercialFlowContextActionTypeKeys;
