import { jsx as _jsx } from "react/jsx-runtime";
import { UseConsumerContextState } from "../../reducers";
import { ReviewForm } from "../../commoncomponents/ReviewForm";
import { useLocaleStrings } from "../../library/providers/strings";
import { useRootContext } from "../../library/providers/root/RootContextProvider";
export const ReviewTab = () => {
    var _a, _b;
    const { getLocalSpecificString } = useLocaleStrings();
    const { privileges: { isQALoB }, } = useRootContext();
    const consumerContextState = UseConsumerContextState().ConsumerContext;
    const selectedLocationName = (_a = consumerContextState.ConsumerMetadata.countries.find((x) => {
        return x.key === consumerContextState.BasicDetails.customerLocation;
    })) === null || _a === void 0 ? void 0 : _a.text;
    const preferredLanguage = (_b = consumerContextState.ConsumerMetadata.languages.find((x) => {
        return x.key === consumerContextState.BasicDetails.preferredLanguage;
    })) === null || _b === void 0 ? void 0 : _b.text;
    const filesList = consumerContextState.EscalationDetails.attachments.map((file) => { var _a; return (_a = file.fileName) !== null && _a !== void 0 ? _a : ""; });
    const reviewData = [
        {
            header: "Basics",
            data: [
                isQALoB
                    ? {
                        label: getLocalSpecificString("submitterLabel"),
                        value: consumerContextState.BasicDetails.submitterName != ""
                            ? `${consumerContextState.BasicDetails.submitterName} (${consumerContextState.BasicDetails.submitterEmail})`
                            : "",
                    }
                    : { label: undefined, value: undefined },
                {
                    label: getLocalSpecificString("customerFirstNameLabel"),
                    value: consumerContextState.BasicDetails.customerFirstName,
                },
                {
                    label: getLocalSpecificString("customerLastNameLabel"),
                    value: consumerContextState.BasicDetails.customerLastName,
                },
                {
                    label: getLocalSpecificString("customerLocationLabel"),
                    value: selectedLocationName,
                },
                consumerContextState.BasicDetails.haveCustomerEmail
                    ? {
                        label: getLocalSpecificString("customerEmailLabel"),
                        value: consumerContextState.BasicDetails.customerEmail,
                    }
                    : { label: undefined, value: undefined },
                {
                    label: getLocalSpecificString("customerPhoneLabel"),
                    value: consumerContextState.BasicDetails.customerPhone,
                },
                {
                    label: getLocalSpecificString("microsoftAccountIdLabel"),
                    value: consumerContextState.BasicDetails.microsoftAccountId,
                },
                {
                    label: getLocalSpecificString("customerPreferredLanguageLabel"),
                    value: preferredLanguage,
                },
            ],
        },
        {
            header: "Escalation details",
            data: [
                isQALoB
                    ? {
                        label: getLocalSpecificString("escalationSourceLabel"),
                        value: consumerContextState.EscalationDetails.escalationSource,
                    }
                    : { label: undefined, value: undefined },
                {
                    label: getLocalSpecificString("issueTypeLabel"),
                    value: consumerContextState.EscalationDetails.issueType,
                },
                {
                    label: getLocalSpecificString("productFamilyLabel"),
                    value: consumerContextState.EscalationDetails.productFamily,
                },
                {
                    label: getLocalSpecificString("issueDescriptionLabel"),
                    value: consumerContextState.EscalationDetails.issueDescription,
                    type: "html",
                },
                {
                    label: getLocalSpecificString("supportRequestNumberLabel"),
                    value: consumerContextState.EscalationDetails.supportRequestNumber,
                },
                {
                    label: getLocalSpecificString("attachmentsLabel"),
                    value: filesList,
                    type: "list",
                },
            ],
        },
    ];
    return _jsx(ReviewForm, { reviewDataArray: reviewData });
};
