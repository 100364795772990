import { NeutralColors } from "@elixir/styling";
const WHITE = "#ffffff";
export const useShellHeaderStyles = (theme) => {
    return {
        root: {
            color: WHITE,
            backgroundColor: theme.palette.themeSecondary,
            ".elx-app-title": {
                color: WHITE,
                ":visited": {
                    color: WHITE,
                },
            },
            ".elx-branding .elx-link": {
                color: WHITE,
            },
            ".ms-Breadcrumb-listItem": {
                span: {
                    color: NeutralColors.Grays.Gray10,
                },
                ":last-child a": {
                    color: WHITE,
                    ":hover": {
                        backgroundColor: theme.palette.themeDarkAlt,
                    },
                },
            },
            ".doc-search .elx-searchbox": {
                backgroundColor: theme.semanticColors.inputForegroundChecked,
            },
            "a.elx-header-action,button.elx-header-action": {
                color: WHITE,
                ":hover": {
                    backgroundColor: theme.palette.themeDarkAlt,
                },
            },
            "button.elx-header-action.is-expanded": {
                backgroundColor: theme.palette.themeDarkAlt,
            },
            ".ms-Label": {
                color: WHITE,
            },
        },
    };
};
export const useShellStyles = (theme) => ({
    root: {
        maxHeight: "95.7vh",
        margin: "0",
        ".elx-shell-body>.elx-sidebar .elx-sidebar-navs": {
            "& .ms-Nav-group": {
                borderRight: "1px solid lightgrey",
            },
            ".ms-Nav-compositeLink.is-selected": {
                ".ms-Button": {
                    backgroundColor: theme.palette.themeLighter,
                    "::after": {
                        borderLeft: `2px solid ${theme.palette.themePrimary}`,
                    },
                },
            },
        },
        ".ms-Checkbox-label": {
            width: "100%",
        },
    },
    container: {
        overflowY: "auto",
    },
    body: {
        ".container-body": {
            paddingBottom: "1.5rem"
        }
    }
});
