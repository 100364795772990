var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getMyEscalations } from "../../services/myescalationsservice";
import { createConsumerFollowup, createCommercialFollowup, } from "../../services/notificationsservice";
import MyEscalationsActionTypes from "./ActionTypeKeys";
import MyEscalationsActionTypeKeys from "./ActionTypeKeys";
export function getMyEscalationsAction(dispatch) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(inProgress());
        yield getMyEscalations().then((data) => {
            var _a, _b;
            try {
                // Access the data here
                const myescalation = data.data;
                if (myescalation === null || myescalation === void 0 ? void 0 : myescalation.result)
                    dispatch(success(myescalation === null || myescalation === void 0 ? void 0 : myescalation.result));
                else if ((myescalation === null || myescalation === void 0 ? void 0 : myescalation.errors) && ((_a = myescalation === null || myescalation === void 0 ? void 0 : myescalation.errors) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                    dispatch(failure(((_b = myescalation === null || myescalation === void 0 ? void 0 : myescalation.errors[0]) === null || _b === void 0 ? void 0 : _b.Message) ||
                        "Error occured while fetching escalations"));
                }
            }
            catch (error) {
                dispatch(failure(error));
            }
        });
    });
}
export function createConsumerFollowupAction(dispatch, consumerFollowupRequest) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(inProgress());
        yield createConsumerFollowup(consumerFollowupRequest).then((data) => {
            try {
                const response = parseInt((data === null || data === void 0 ? void 0 : data.httpStatusCode) || "");
                if (response == 202)
                    dispatch(FollowupSuccess(200));
                else {
                    dispatch(failure((data === null || data === void 0 ? void 0 : data.message) || "Error occured while submitting followup"));
                    dispatch(FollowupSuccess(response));
                }
            }
            catch (error) {
                dispatch(failure(error));
            }
        });
    });
}
export function createCommercialFollowupAction(dispatch, commercialFollowupRequest) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(inProgress());
        yield createCommercialFollowup(commercialFollowupRequest).then((data) => {
            try {
                const response = parseInt((data === null || data === void 0 ? void 0 : data.httpStatusCode) || "");
                console.log("followup response:", data === null || data === void 0 ? void 0 : data.httpStatusCode);
                if (response == 202)
                    dispatch(FollowupSuccess(200));
                else {
                    dispatch(failure((data === null || data === void 0 ? void 0 : data.message) || "Error occured while submitting Followup"));
                    dispatch(FollowupSuccess(response));
                }
            }
            catch (error) {
                dispatch(failure(error));
            }
        });
    });
}
function inProgress() {
    return {
        payload: true,
        type: MyEscalationsActionTypes.Get_MyEscalations_InProgress,
    };
}
function success(escalations) {
    return {
        payload: escalations,
        type: MyEscalationsActionTypes.Get_MyEscalations_Success,
    };
}
function FollowupSuccess(response) {
    return {
        payload: response,
        type: MyEscalationsActionTypes.Set_Followup_Response,
    };
}
export function setSelectedEscalationRequestNumberAction(escalationRequestNumber, dispatch) {
    dispatch({
        type: MyEscalationsActionTypeKeys.Set_EscalationDetails,
        payload: escalationRequestNumber,
    });
}
export function setMyCasesViewAction(myCasesView, dispatch) {
    dispatch({
        type: MyEscalationsActionTypeKeys.Set_MyCasesView,
        payload: myCasesView,
    });
}
export function setHomeViewAction(homeView, dispatch) {
    dispatch({
        type: MyEscalationsActionTypeKeys.Set_HomeView,
        payload: homeView,
    });
}
function failure(error) {
    return {
        type: MyEscalationsActionTypes.Get_MyEscalations_Failure,
        payload: error,
    };
}
