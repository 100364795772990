var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { useEffect } from "react";
import { ElxActionButton, ElxDialog, ElxTextField } from "@elixir/components";
import { Icon, Label, Stack } from "@fluentui/react";
import { useLocaleStrings } from "../../library/providers/strings";
import { UseMyEscalationsDispatch, UseMyEscalationsState, } from "../../reducers/MyEscalationsContext/MyEscalationsContextProvider";
import { enrichConsumerFollowup, enrichCommercialFollowup, } from "../../reducers/MyEscalationsContext/Transformers/EnrichFollowup";
const DetailsPageDialog = (props) => {
    const [showDialogFollowup, setShowDialogFollowup] = React.useState(false);
    const [showDialogConcern, setShowDialogConcern] = React.useState(false);
    const [showDialogAddInfo, setShowDialogAddInfo] = React.useState(false);
    const [showDialogUpdate, setShowDialogUpdate] = React.useState(false);
    const [showCommercialDialogConcern, setShowCommercialDialogConcern] = React.useState(false);
    const [displayText, setDisplayText] = React.useState("");
    const [followupSubmitted, setFollowupSubmitted] = React.useState(false);
    const [followupSubmittedResponse, setFollowupSubmittedResponse] = React.useState(-1);
    const { CreateConsumerFollowup, CreateCommercialFollowup } = UseMyEscalationsDispatch();
    const MyEscalationsState = UseMyEscalationsState();
    const escalationType = props.propValue;
    const matchedEscalation = props.matchedEscalation;
    const { getLocalSpecificString } = useLocaleStrings();
    const handleConsumerFollowup = (Type) => __awaiter(void 0, void 0, void 0, function* () {
        // transforming into ConsumerFollowuprequest Model
        const consumerFollowup = enrichConsumerFollowup(Type, matchedEscalation, displayText);
        //call dispatch method to create followup
        CreateConsumerFollowup(consumerFollowup);
        onsubmitClick();
    });
    const handleCommercialFollowup = (Type) => __awaiter(void 0, void 0, void 0, function* () {
        // transforming into CommercialFollowuprequest Model
        const commercialFollowup = enrichCommercialFollowup(Type, matchedEscalation, displayText);
        //call dispatch method to create followup
        CreateCommercialFollowup(commercialFollowup);
        onsubmitClick();
    });
    const onsubmitClick = () => {
        setShowDialogFollowup(false);
        setShowDialogConcern(false);
        setShowDialogAddInfo(false);
        setShowDialogUpdate(false);
        setShowCommercialDialogConcern(false);
        setDisplayText("");
        setFollowupSubmitted(true);
    };
    const setTextValue = (e) => {
        setDisplayText(e.target.value);
    };
    const handleDialog = (val) => {
        if (val === "Followup") {
            setShowDialogFollowup(true);
            setShowDialogConcern(false);
            setShowDialogAddInfo(false);
            setShowDialogUpdate(false);
            setShowCommercialDialogConcern(false);
        }
        if (val === "Concern") {
            setShowDialogConcern(true);
            setShowDialogFollowup(false);
            setShowDialogAddInfo(false);
            setShowCommercialDialogConcern(false);
            setShowDialogUpdate(false);
        }
        if (val === "AddInfo") {
            setShowDialogAddInfo(true);
            setShowDialogFollowup(false);
            setShowDialogConcern(false);
            setShowCommercialDialogConcern(false);
            setShowDialogUpdate(false);
        }
        if (val === "Update") {
            setShowDialogUpdate(true);
            setShowDialogFollowup(false);
            setShowDialogConcern(false);
            setShowDialogAddInfo(false);
            setShowCommercialDialogConcern(false);
        }
        if (val === "CommercialConcern") {
            setShowCommercialDialogConcern(true);
            setShowDialogFollowup(false);
            setShowDialogConcern(false);
            setShowDialogAddInfo(false);
            setShowDialogUpdate(false);
        }
        if (val === "Close") {
            setShowDialogFollowup(false);
            setShowDialogConcern(false);
            setShowDialogAddInfo(false);
            setShowDialogUpdate(false);
            setShowCommercialDialogConcern(false);
            setFollowupSubmitted(false);
            setDisplayText("");
        }
    };
    useEffect(() => {
        if (MyEscalationsState.FollowupResponse != -1)
            setFollowupSubmittedResponse(MyEscalationsState.FollowupResponse);
    }, [MyEscalationsState.FollowupResponse]);
    return (_jsx(Stack, Object.assign({ tokens: { childrenGap: 10 } }, { children: _jsxs(Stack, Object.assign({ className: "detailsHeader", horizontal: true, tokens: { childrenGap: 5 }, wrap: true, grow: true }, { children: [escalationType === "Consumer" && (_jsxs(_Fragment, { children: [_jsxs(Stack.Item, { children: [_jsx(ElxActionButton, { "aria-label": "followupButton", className: "followupdialogBtn", text: "Follow-up", iconProps: { iconName: "Forward" }, onClick: () => handleDialog("Followup") }), _jsx(ElxDialog, Object.assign({ hidden: !showDialogFollowup, dismissable: false, dialogContentProps: {
                                        title: "Follow-up",
                                    }, primaryButtonProps: {
                                        ariaLabel: "Submit",
                                        title: "Submit",
                                        text: "Submit",
                                        onClick: () => {
                                            handleConsumerFollowup("Update");
                                        },
                                    }, cancelButtonProps: {
                                        text: "Close",
                                        ariaLabel: "Close",
                                        title: "Close",
                                        onClick: () => {
                                            handleDialog("Close");
                                        },
                                    }, onDismiss: () => setShowDialogFollowup(false) }, { children: _jsx(Stack, { children: _jsx(ElxTextField, { id: "followup", name: "followup", required: true, multiline: true, rows: 7, cols: 250, placeholder: getLocalSpecificString("followUPPopUpHeaderPH"), "aria-label": "Requestdetails", onChange: setTextValue, value: displayText }) }) }))] }), _jsxs(Stack.Item, { children: [_jsx(ElxActionButton, { "aria-label": "raiseConcernButton", title: "Raise Concern Button", className: "raiseconcerndialogBtn", text: "Raise Concern", iconProps: { iconName: "Feedback" }, onClick: () => handleDialog("Concern") }), _jsx(ElxDialog, Object.assign({ hidden: !showDialogConcern, dismissable: false, dialogContentProps: {
                                        title: "Raise Concern",
                                    }, primaryButtonProps: {
                                        text: "Submit",
                                        title: "Submit",
                                        ariaLabel: "Submit",
                                        onClick: () => {
                                            handleConsumerFollowup("Concern");
                                        },
                                    }, cancelButtonProps: {
                                        title: "Close",
                                        ariaLabel: "Close",
                                        text: "Close",
                                        onClick: () => {
                                            handleDialog("Close");
                                        },
                                    }, onDismiss: () => setShowDialogConcern(false) }, { children: _jsx(Stack, { children: _jsx(ElxTextField, { id: "concerndetails", name: "concerndetails", required: true, multiline: true, rows: 7, cols: 250, placeholder: getLocalSpecificString("reportConcernPH"), "aria-label": "concerndetails", onChange: setTextValue, value: displayText }) }) }))] }), _jsxs(Stack.Item, { children: [_jsx(ElxActionButton, { "aria-label": "provideAdditionalInfoButton", className: "AdditionalInfodialogBtn", text: "Provide Additional Information", iconProps: { iconName: "Chat" }, onClick: () => handleDialog("AddInfo") }), _jsx(ElxDialog, Object.assign({ hidden: !showDialogAddInfo, dismissable: false, dialogContentProps: {
                                        title: "Provide Additional Information",
                                    }, primaryButtonProps: {
                                        text: "Submit",
                                        ariaLabel: "Submit",
                                        title: "Submit",
                                        onClick: () => {
                                            handleConsumerFollowup("AdditionalInfo");
                                        },
                                    }, cancelButtonProps: {
                                        text: "Close",
                                        ariaLabel: "Close",
                                        title: "Close",
                                        onClick: () => handleDialog("Close"),
                                    }, onDismiss: () => setShowDialogAddInfo(false) }, { children: _jsx(Stack, { children: _jsx(ElxTextField, { id: "provideAdditionalInfo", name: "provideAdditionalInfo", required: true, multiline: true, rows: 7, cols: 250, placeholder: getLocalSpecificString("provideAdditionalInfoPH"), "aria-label": "ProvideAdditionalInfo", onChange: setTextValue, value: displayText }) }) }))] })] })), escalationType === "Commercial" && (_jsxs(_Fragment, { children: [_jsxs(Stack.Item, Object.assign({ className: "dialogStack" }, { children: [_jsx(ElxActionButton, { "aria-label": "RequestUpdatedialogButton", className: "RequestUpdatedialogBtn", text: "Request Update", iconProps: { iconName: "Chat" }, onClick: () => handleDialog("Update") }), _jsx(ElxDialog, Object.assign({ hidden: !showDialogUpdate, dismissable: false, dialogContentProps: {
                                        title: "Request Update",
                                    }, primaryButtonProps: {
                                        text: "Submit",
                                        ariaLabel: "Submit",
                                        title: "Submit",
                                        onClick: () => {
                                            handleCommercialFollowup("Update");
                                        },
                                    }, cancelButtonProps: {
                                        text: "Close",
                                        ariaLabel: "Close",
                                        title: "Close",
                                        onClick: () => {
                                            handleDialog("Close");
                                        },
                                    }, onDismiss: () => setShowDialogUpdate(false) }, { children: _jsx(Stack, { children: _jsx(ElxTextField, { className: "TextField", id: "requestUpdate", name: "requestUpdate", required: true, multiline: true, rows: 7, cols: 250, placeholder: getLocalSpecificString("requestUpdatePH"), "aria-label": "Requestdetails", onChange: setTextValue, value: displayText }) }) }))] })), _jsxs(Stack.Item, Object.assign({ className: "dialogStack" }, { children: [_jsx(ElxActionButton, { "aria-label": "ReportConcernButton", className: "ReportConcernBtn", text: "Report Concern", iconProps: { iconName: "Feedback" }, onClick: () => handleDialog("CommercialConcern") }), _jsx(ElxDialog, Object.assign({ hidden: !showCommercialDialogConcern, dismissable: false, dialogContentProps: {
                                        title: "Report Concern",
                                    }, primaryButtonProps: {
                                        text: "Submit",
                                        ariaLabel: "Submit",
                                        title: "Submit",
                                        onClick: () => {
                                            handleCommercialFollowup("Concern");
                                        },
                                    }, cancelButtonProps: {
                                        text: "Close",
                                        ariaLabel: "Close",
                                        title: "Close",
                                        onClick: () => {
                                            handleDialog("Close");
                                        },
                                    }, onDismiss: () => setShowCommercialDialogConcern(false) }, { children: _jsx(Stack, { children: _jsx(ElxTextField, { id: "reportConcern", name: "reportConcern", required: true, multiline: true, rows: 7, cols: 250, placeholder: getLocalSpecificString("reportConcernCommercialPH"), "aria-label": "reportConcern", onChange: setTextValue, value: displayText }) }) }))] }))] })), followupSubmitted && (_jsx(Stack.Item, { children: _jsx(ElxDialog, Object.assign({ hidden: !followupSubmitted, dismissable: false, cancelButtonProps: {
                            text: "Close",
                            ariaLabel: "Close",
                            title: "Close",
                            onClick: () => {
                                handleDialog("Close");
                            },
                        }, onDismiss: () => setFollowupSubmitted(false) }, { children: followupSubmittedResponse == -1 ? (_jsxs(Stack, Object.assign({ style: { alignItems: "center" } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "SyncStatusSolid", style: {
                                            color: "#015CDA",
                                            marginLeft: "2px",
                                            marginRight: "4px",
                                            fontSize: "30px",
                                        } }) }), _jsx(Stack.Item, { children: getLocalSpecificString("followupWaitmessage") })] }))) : (_jsxs(Stack, Object.assign({ style: { alignItems: "center" } }, { children: [_jsx(Stack.Item, { children: MyEscalationsState.FollowupResponse == 200 ? (_jsx(Icon, { iconName: "CompletedSolid", style: {
                                            color: "#57A300",
                                            alignContent: "center",
                                            fontSize: "50px",
                                        } })) : (_jsx(Icon, { iconName: "ChromeClose", style: {
                                            color: "#ff0000",
                                            alignContent: "center",
                                            fontSize: "30px",
                                        } })) }), _jsx(Stack.Item, { children: _jsx(Label, Object.assign({ "aria-label": "Request status", style: { fontSize: 16, fontWeight: "bold" } }, { children: MyEscalationsState.FollowupResponse == 200
                                            ? getLocalSpecificString("submissionsuccessfulmessage")
                                            : getLocalSpecificString("submissionfailmessage") })) }), _jsx(Stack.Item, { children: _jsx(Label, Object.assign({ "aria-label": "Request status", style: { fontSize: 16, fontWeight: "bold" } }, { children: MyEscalationsState.FollowupResponse == 200
                                            ? getLocalSpecificString("requestSubmitted")
                                            : getLocalSpecificString("requestNotSubmitted") })) }), _jsx(Stack.Item, { children: _jsx(Label, Object.assign({ "aria-label": "Request status" }, { children: MyEscalationsState.FollowupResponse == 200
                                            ? getLocalSpecificString("requestreceivedmessage")
                                            : getLocalSpecificString("followupTryAgain") })) }), _jsx(Stack.Item, { children: _jsx(Label, Object.assign({ "aria-label": "Request status" }, { children: getLocalSpecificString("referencenumbermessage") +
                                            " " +
                                            matchedEscalation.escalationRequestNumber })) })] }))) })) }))] })) })));
};
export default DetailsPageDialog;
