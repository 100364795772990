import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable max-lines-per-function */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DirectionalHint, useTheme } from "@fluentui/react";
import { Callout } from "@fluentui/react/lib/Callout";
import { Icon } from "@fluentui/react/lib/Icon";
import { Spinner, SpinnerSize } from "@fluentui/react/lib/Spinner";
import { Text } from "@fluentui/react/lib/Text";
import { classNamesFunction, css, styled } from "@fluentui/react/lib/Utilities";
import { uniqueId, uniqWith, isEmpty, isEqual } from "lodash";
import { useId } from "@elixir/components/lib/common";
import { usePicker } from "@elixir/components/lib/Picker/usePicker";
import { initFilteredOptions, onArrowDownHandler, onArrowUpHandler, onCalloutDismiss, onCalloutOpen, onClick, onFocus, } from "@elixir/components/lib/Picker/usePicker.utility";
import { isNullOrWhitespace } from "@elixir/components/lib/common/utils/String";
import { ElxInputBase, getInputDescriptionId, getInputLabelId, } from "@elixir/components/lib/InputBase";
import { ElxTextField } from "@elixir/components/lib/TextField";
import { removeUnsupportedReadOnlyProps } from "@elixir/components/lib/InputBase/ElxInputBase.utility";
import { ElxTagContainer, TagContainerOrientation, } from "@elixir/components";
import { useInteractionTracker } from "@elixir/telemetry/lib/hooks/useInteractionTracker";
import { ElixirPersonaTypes, ElxPersona } from "./ElxPersona";
import { useScope } from "./Scope";
import { fetchPeopleAction, uxUpdatePeopleAction, } from "@elixir/fx/lib/store/Actions";
import { elixirSelectors } from "@elixir/fx/lib/store/Selectors";
import { ErrorBadgeStyles, SpinnerStyles, usePeoplePickerStyles, } from "./ElxPeoplePicker.styles";
import { createUserOptions } from "./ElxPeoplePicker.utilities";
import "./PeoplePickerStyles.css";
export const ElxMultiSelectPeoplePicker = styled(ElxPeoplePickerImpl, usePeoplePickerStyles);
const KEY_SEARCH_OPTION = "__elx_people_picker_search_option__";
const ID_LISTBOX = "__elx_people_picker_listbox__";
const ID_LISTITEM = "__elx_people_picker_listitem__";
const getClassNames = classNamesFunction();
function ElxPeoplePickerImpl(props) {
    const component = usePeoplePicker(props);
    const pickerComponent = usePicker();
    const { value, autoFocus, label, placeholder, emptyText, trackingContext } = props;
    const { localKeyword, listboxId, classNames, dispatch, scope, localPeople } = component;
    const { pickerInputRef, pickerInputWrapperRef, hideCallout } = pickerComponent;
    React.useEffect(effectUpdateLocalPeople(component), [
        props.people,
        component.peopleSearchResult,
        component.localKeyword,
    ]);
    React.useEffect(effectUpdateOptions(component, pickerComponent), [
        localPeople,
    ]);
    React.useEffect(effectCleanup(component), []);
    React.useEffect(() => {
        if (props.onMultiSelectChange && props.defaultMultiSelectValues) {
            props.onMultiSelectChange(props.defaultMultiSelectValues);
        }
    }, []);
    React.useEffect(() => {
        const delay = 500;
        const debounce = setTimeout(() => {
            onSearch(component, pickerComponent);
        }, delay);
        return () => {
            clearTimeout(debounce);
        };
    }, [component.localKeyword]);
    const placeholderText = placeholder || "Enter a name or alias";
    const displayValue = (value || emptyText) && hideCallout;
    const account = value && (value.account || value.alias);
    const controlWidth = pickerInputWrapperRef.current
        ? pickerInputWrapperRef.current.clientWidth
        : 0;
    const controlWidthInner = controlWidth - 36; // 36 to add margin for the chevron
    const generatedId = useId("elx-people-picker");
    const id = props.id || generatedId;
    const aria = {
        "aria-labelledby": getInputLabelId(id),
        "aria-describedby": getInputDescriptionId(id),
    };
    // these needs to be dynamic, they are valid only when the dom exists
    // if (!hideCallout) {
    //   aria['aria-controls'] = listboxId;
    //   aria['aria-activedescendant'] = getActive(pickerComponent, ID_LISTITEM);
    // }
    const theme = useTheme();
    return (_jsxs("div", Object.assign({ className: "peoplePickerAndItemsStyle" }, { children: [_jsxs(ElxInputBase, Object.assign({ id: id }, removeUnsupportedReadOnlyProps(props), { styles: props.wrapperStyles }, { children: [_jsxs("div", Object.assign({ ref: pickerInputWrapperRef, className: classNames.root }, { children: [_jsx(ElxTextField, Object.assign({ required: props.required, componentRef: pickerInputRef, disabled: props.disabled, readOnly: props.readOnly, autoComplete: "off", placeholder: placeholderText, autoFocus: autoFocus, value: localKeyword, onKeyDown: (event) => onKeyDown(component, pickerComponent, event), onChange: (event, value) => onChange(component, pickerComponent, value), onClick: () => props.disabled || props.readOnly ? null : onClick(pickerComponent), onFocus: () => props.disabled || props.readOnly ? null : onFocus(pickerComponent), iconProps: {
                                    className: classNames.caretDown,
                                    iconName: "ChevronDown",
                                    onClick: () => props.disabled || props.readOnly
                                        ? null
                                        : onClick(pickerComponent),
                                }, trackingContext: Object.assign({ label }, trackingContext) }, aria)), displayValue && (_jsx("div", Object.assign({ role: "presentation", className: classNames.displayValue, onClick: () => props.disabled || props.readOnly
                                    ? null
                                    : onClick(pickerComponent), style: {
                                    width: controlWidthInner,
                                    backgroundColor: props.disabled
                                        ? theme.semanticColors.buttonBackgroundDisabled
                                        : theme.palette.white,
                                    color: props.disabled
                                        ? theme.semanticColors.buttonTextDisabled
                                        : theme.semanticColors.buttonText,
                                } }, { children: value && account ? (_jsx(ElxPersona, { displayName: value.displayName, userPrincipalName: account, type: ElixirPersonaTypes.EmailInlineSmallImage })) : emptyText ? (_jsx(Text, Object.assign({ className: classNames.emptyText }, { children: emptyText }))) : null })))] })), !hideCallout && (_jsx(Callout, Object.assign({ className: classNames.callout, target: pickerInputWrapperRef.current, gapSpace: 0, isBeakVisible: false, setInitialFocus: false, directionalHint: DirectionalHint.bottomCenter, onDismiss: () => {
                            dispatch(uxUpdatePeopleAction(scope));
                            onCalloutDismiss(pickerComponent);
                        }, calloutWidth: controlWidth }, { children: renderPeopleList(component, pickerComponent) })))] })), props.multiSelect && tagContainer(component)] })));
}
function tagContainer(component) {
    return (_jsx("div", Object.assign({ style: { display: "flex" } }, { children: _jsx(ElxTagContainer, { displayOrientation: TagContainerOrientation.Horizontal, onRemoveTag: (tag) => {
                component.state.items.forEach((tagData) => {
                    if (isEqual(tagData, tag)) {
                        const updatedPeoples = [];
                        for (let i = 0; i < component.pickedPeoples.length; i++) {
                            if (tag.text !== component.pickedPeoples[i].displayName) {
                                updatedPeoples.push(component.pickedPeoples[i]);
                            }
                        }
                        component.setPickedPeoples(updatedPeoples);
                        //update the parent
                        if (component.props.onMultiSelectChange) {
                            component.props.onMultiSelectChange(updatedPeoples);
                        }
                        component.setState(Object.assign(Object.assign({}, component.state), { items: [
                                ...component.state.items.filter((item) => item !== tag),
                            ], lastRemovedItem: tag }));
                        component.tracker("ElxTagPicker Remove", "props.label", {
                            type: "change",
                            value: tag.text,
                        });
                    }
                });
            }, data: component.state.items }) })));
}
function transformToIUsers(users) {
    if (!users || isEmpty(users)) {
        return [];
    }
    const pickItems = [];
    users.map((user) => {
        var _a;
        if ((user === null || user === void 0 ? void 0 : user.displayName) !== undefined && (user === null || user === void 0 ? void 0 : user.account) !== undefined) {
            pickItems.push({
                displayName: user.displayName,
                account: user.account,
                alias: user.alias,
                name: user.displayName,
                isGuest: undefined,
                image: "",
                oid: (_a = user.oid) === null || _a === void 0 ? void 0 : _a.toString(),
            });
        }
    });
    return pickItems;
}
function transformToIElxTagsState(users) {
    if (!users || isEmpty(users)) {
        return { items: [] };
    }
    const items = [];
    users.forEach((user) => {
        if ((user === null || user === void 0 ? void 0 : user.displayName) !== undefined && (user === null || user === void 0 ? void 0 : user.uniqueName) !== undefined) {
            items.push({ key: user.uniqueName, text: user.displayName });
        }
    });
    return { items: items };
}
function usePeoplePicker(props) {
    var _a;
    const { value, disableSearch, styles } = props;
    const scope = useScope();
    const [listboxId] = React.useState(uniqueId(ID_LISTBOX));
    const disableGraphSearch = disableSearch || false;
    const [localPeople, setLocalPeople] = React.useState(props.people);
    const [localKeyword, setLocalKeyword] = React.useState((value && value.displayName) || "");
    const placeHolderOnMultiSelects = ((_a = props.defaultMultiSelectValues) === null || _a === void 0 ? void 0 : _a.map((item) => ({
        displayName: item.displayName,
        uniqueName: item.displayName,
        id: item.alias,
    }))) || [];
    const [state, setState] = useState(transformToIElxTagsState(placeHolderOnMultiSelects));
    const [pickedPeoples, setPickedPeoples] = useState(transformToIUsers(props.defaultMultiSelectValues || []));
    const tracker = useInteractionTracker();
    return {
        dispatch: useDispatch(),
        classNames: getClassNames(styles, { theme: useTheme() }),
        props,
        scope,
        listboxId: listboxId,
        peopleSearchResult: useSelector((state) => elixirSelectors.getPeople(state, scope)),
        localPeople,
        setLocalPeople,
        localKeyword,
        setLocalKeyword,
        disableGraphSearch,
        state,
        setState,
        pickedPeoples,
        setPickedPeoples,
        tracker,
    };
}
const searchOption = createSearchOption();
function effectCleanup(component) {
    const { scope, dispatch } = component;
    return () => {
        // clean up
        return () => {
            dispatch(uxUpdatePeopleAction(scope, undefined));
        };
    };
}
const matchByKeyword = (keyword, byUser) => (entry) => {
    var _a, _b;
    const localEntry = byUser ? entry : entry.data;
    return (isNullOrWhitespace(keyword) ||
        ((_a = localEntry === null || localEntry === void 0 ? void 0 : localEntry.alias) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(keyword === null || keyword === void 0 ? void 0 : keyword.toLowerCase())) ||
        ((_b = localEntry === null || localEntry === void 0 ? void 0 : localEntry.displayName) === null || _b === void 0 ? void 0 : _b.toLowerCase().includes(keyword === null || keyword === void 0 ? void 0 : keyword.toLowerCase())));
};
/**
 * update local people when people search result is updated
 */
function effectUpdateLocalPeople(component) {
    const { value, people } = component.props;
    const { localKeyword } = component;
    const searched = component.peopleSearchResult;
    const filteredPeople = people === null || people === void 0 ? void 0 : people.filter(matchByKeyword(localKeyword, true));
    return () => {
        if (value || people || searched) {
            const all = uniqWith([]
                .concat(searched ? searched : [])
                .concat(filteredPeople ? filteredPeople : []), (a, b) => a.alias === b.alias);
            if (value) {
                const idx = all.findIndex((o) => o.alias === value.alias);
                if (idx > -1) {
                    all.splice(idx, 1);
                }
                const idx2 = all.findIndex((o) => o.account === value.account);
                if (idx > -1) {
                    all.splice(idx2, 1);
                    all.unshift(value);
                }
            }
            component.setLocalPeople(all);
        }
    };
}
function effectUpdateOptions(component, pickerComponent) {
    const { localPeople, disableGraphSearch, localKeyword } = component;
    const { setOptions, setFilteredOptions } = pickerComponent;
    return () => {
        if (localPeople) {
            let options = createUserOptions(localPeople);
            if (!disableGraphSearch && !isNullOrWhitespace(localKeyword)) {
                options = options.concat(searchOption);
            }
            setOptions(options);
            setFilteredOptions(initFilteredOptions(pickerComponent, options));
        }
        else {
            setFilteredOptions([]);
        }
    };
}
function onChange(component, pickerComponent, value) {
    const { setLocalKeyword, disableGraphSearch, props } = component;
    const { hideCallout, setLocalSearchedKeyword, options, setFilteredOptions } = pickerComponent;
    if (hideCallout) {
        onCalloutOpen(pickerComponent);
    }
    setLocalKeyword(value);
    setLocalSearchedKeyword("");
    let filtered = options || [];
    if (isNullOrWhitespace(value)) {
        if (props.onChange) {
            props.onChange(undefined);
        }
        onCalloutDismiss(pickerComponent);
    }
    else {
        filtered = filtered.filter(matchByKeyword(value));
        if (!disableGraphSearch) {
            filtered = filtered.concat(searchOption);
        }
    }
    setFilteredOptions(initFilteredOptions(pickerComponent, filtered));
}
function onKeyDown(component, pickerComponent, event) {
    switch (event.key) {
        case "Enter":
            onEnter(component, pickerComponent);
            break;
        case "ArrowDown":
            onArrowDownHandler(pickerComponent);
            break;
        case "ArrowUp":
            onArrowUpHandler(pickerComponent);
            // preventing default behavior from text filed
            event.preventDefault();
            event.stopPropagation();
            break;
        case "Escape":
            onCalloutDismiss(pickerComponent);
            if (!pickerComponent.hideCallout) {
                event.stopPropagation();
            }
            break;
        default:
            break;
    }
}
function onSearch(component, pickerComponent) {
    const { localKeyword, scope, dispatch } = component;
    const { setLocalSearchedKeyword, setFilteredOptions } = pickerComponent;
    setFilteredOptions([]);
    setLocalSearchedKeyword(localKeyword);
    dispatch(uxUpdatePeopleAction(scope));
    dispatch(fetchPeopleAction(scope, localKeyword));
}
function onEnter(component, pickerComponent) {
    const { hideCallout, filteredOptions } = pickerComponent;
    if (!hideCallout) {
        if (filteredOptions) {
            const selected = filteredOptions.find((o) => o.selected);
            if (selected) {
                onSelect(component, pickerComponent, selected);
            }
        }
    }
}
function onSelect(component, pickerComponent, option) {
    var _a, _b, _c, _d;
    const { props } = component;
    if (option) {
        if (option.key === KEY_SEARCH_OPTION) {
            onSearch(component, pickerComponent);
        }
        else {
            if (props.multiSelect) {
                if (!((_a = component.state) === null || _a === void 0 ? void 0 : _a.items.some((item) => item.text === option.data.displayName))) {
                    const updatedPeoples = [
                        ...component.pickedPeoples,
                        option.data,
                    ];
                    component.setPickedPeoples(updatedPeoples);
                    component.setState(Object.assign(Object.assign({}, component.state), { items: [
                            ...component.state.items,
                            {
                                text: (_b = option === null || option === void 0 ? void 0 : option.data) === null || _b === void 0 ? void 0 : _b.displayName,
                                key: (_c = option === null || option === void 0 ? void 0 : option.data) === null || _c === void 0 ? void 0 : _c.displayName,
                            },
                        ] }));
                    onChange(component, pickerComponent, "");
                    pickerComponent.setFilteredOptions([]);
                    (_d = props.onMultiSelectChange) === null || _d === void 0 ? void 0 : _d.call(props, updatedPeoples);
                }
            }
            else {
                if (props.onChange) {
                    props.onChange(option.data);
                }
                onChange(component, pickerComponent, option.data.displayName);
            }
            onCalloutDismiss(pickerComponent);
        }
    }
}
function renderPeopleList(component, pickerComponent) {
    const { peopleSearchResult, listboxId, classNames } = component;
    const { filteredOptions, localSearchedKeyword, listboxRef } = pickerComponent;
    return (_jsx(_Fragment, { children: filteredOptions && filteredOptions.length > 0 ? (_jsx("ul", Object.assign({ id: listboxId, "aria-label": "people list", role: "listbox", ref: listboxRef, className: classNames.list }, { children: filteredOptions.map((o, i) => renderItem(component, pickerComponent, i, o)) }))) : localSearchedKeyword ? (peopleSearchResult && peopleSearchResult.length === 0 ? (_jsxs("div", Object.assign({ className: classNames.message }, { children: [_jsx(Icon, { iconName: "ErrorBadge", styles: ErrorBadgeStyles }), _jsx(Text, { children: "No result found." })] }))) : (_jsxs("div", Object.assign({ className: classNames.message }, { children: [_jsx(Spinner, { size: SpinnerSize.large, styles: SpinnerStyles }), _jsx(Text, { children: "Loading result..." })] })))) : null }));
}
function renderItem(component, pickerComponent, index, option) {
    var _a, _b;
    if (option) {
        const { data, key } = option;
        const { classNames } = component;
        const account = data && (data.account || data.alias);
        const label = (data && data.displayName) || option.text;
        if (account && !((_a = component === null || component === void 0 ? void 0 : component.props) === null || _a === void 0 ? void 0 : _a.searchAcrossAllDomain) && !((_b = data === null || data === void 0 ? void 0 : data.userEmail) === null || _b === void 0 ? void 0 : _b.includes("@microsoft.com"))) {
            return null;
        }
        return (_jsxs("li", Object.assign({ id: `${ID_LISTITEM}${index}`, role: "option", "aria-label": label, "aria-selected": option.selected, className: css(classNames.listItem, `${option.selected ? "selected" : ""}`), onClick: () => onSelect(component, pickerComponent, option) }, { children: [account && (_jsx(ElxPersona, { displayName: data.displayName, userPrincipalName: account, type: ElixirPersonaTypes.EmailInline })), key === KEY_SEARCH_OPTION && (_jsxs("div", Object.assign({ className: classNames.searchOption }, { children: [_jsx(Icon, { className: classNames.searchIcon, iconName: "Search", ariaLabel: "Search" }), _jsx("span", { children: option.text })] })))] }), option.key));
    }
    return null;
}
function createSearchOption() {
    return {
        key: KEY_SEARCH_OPTION,
        text: "Search for results",
        ariaLabel: "Search for results",
    };
}
