export var FlowTypeSelector;
(function (FlowTypeSelector) {
    FlowTypeSelector["SpecialProgram"] = "specialprograms";
    FlowTypeSelector["License"] = "licenseQ";
    FlowTypeSelector["PSST"] = "psst";
    FlowTypeSelector["PCA"] = "pca";
    FlowTypeSelector["TechProductIssue"] = "techproductissue";
    FlowTypeSelector["ELP"] = "elp";
    FlowTypeSelector["CPP"] = "cpp";
    FlowTypeSelector["Default"] = "commercial";
})(FlowTypeSelector || (FlowTypeSelector = {}));
