import CommercialFlowContextActionTypeKeys from "./ActionTypeKeys";
import { initialState, } from "./CommercialFlowContextProvider";
export function CommercialFlowContextReducer(state = initialState, action) {
    switch (action.type) {
        case CommercialFlowContextActionTypeKeys.Update_BasicDetails: {
            return Object.assign(Object.assign({}, state), { BasicDetails: action.payload });
        }
        case CommercialFlowContextActionTypeKeys.Update_EscalationDetails_Complete: {
            return Object.assign(Object.assign({}, state), { EscalationDetails: action.payload });
        }
        case CommercialFlowContextActionTypeKeys.Update_EscalationDetails: {
            return Object.assign(Object.assign({}, state), { EscalationDetails: Object.assign(Object.assign({}, state.EscalationDetails), { [action.payload.field]: action.payload.value }) });
        }
        case CommercialFlowContextActionTypeKeys.Update_BasicDetailsValidationStatus_Complete: {
            return Object.assign(Object.assign({}, state), { ValidationStatus: Object.assign(Object.assign({}, state.ValidationStatus), { BasicDetailsValidationStatus: action.payload }) });
        }
        case CommercialFlowContextActionTypeKeys.Update_BasicDetailsValidationStatus: {
            return Object.assign(Object.assign({}, state), { ValidationStatus: Object.assign(Object.assign({}, state.ValidationStatus), { BasicDetailsValidationStatus: Object.assign(Object.assign({}, state.ValidationStatus.BasicDetailsValidationStatus), { [action.payload.field]: action.payload.value }) }) });
        }
        case CommercialFlowContextActionTypeKeys.Update_EscalationDetailsValidationStatus: {
            return Object.assign(Object.assign({}, state), { ValidationStatus: Object.assign(Object.assign({}, state.ValidationStatus), { EscalationDetailsValidationStatus: Object.assign(Object.assign({}, state.ValidationStatus.EscalationDetailsValidationStatus), { [action.payload.field]: action.payload.value }) }) });
        }
        case CommercialFlowContextActionTypeKeys.Update_EscalationDetailsValidationStatus_Complete: {
            return Object.assign(Object.assign({}, state), { ValidationStatus: Object.assign(Object.assign({}, state.ValidationStatus), { EscalationDetailsValidationStatus: action.payload }) });
        }
        case CommercialFlowContextActionTypeKeys.Set_BasicDetailsAreValid: {
            return Object.assign(Object.assign({}, state), { areBasicDetailsValid: action.payload });
        }
        case CommercialFlowContextActionTypeKeys.Set_EscalationDetailsAreValid: {
            return Object.assign(Object.assign({}, state), { areEscalationDetailsValid: action.payload });
        }
        case CommercialFlowContextActionTypeKeys.Submit_CommercialEscalation_Success: {
            return Object.assign(Object.assign({}, state), { uemTrackingId: action.payload, Error: "", InProgress: false });
        }
        case CommercialFlowContextActionTypeKeys.Submit_CommercialEscalation_Failed: {
            return Object.assign(Object.assign({}, state), { Error: action.payload, InProgress: false });
        }
        case CommercialFlowContextActionTypeKeys.Submit_CommercialEscalation_Inprogress: {
            return Object.assign(Object.assign({}, state), { Error: "", InProgress: action.payload });
        }
        default:
            return state;
    }
}
