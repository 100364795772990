export const getNamesFromDropdownList = (inputList) => {
    return inputList === null || inputList === void 0 ? void 0 : inputList.map((product) => product.text);
};
export const parseCSV = (input) => {
    const values = input
        .split(",")
        .map((value) => value.trim().replace(/"/g, "").replace(/'/g, ""));
    const result = values.join(", ");
    return result;
};
