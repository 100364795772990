var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createConsumerEscalation, getConsumerDomainData } from "../../services/consumerservice";
import ConsumerContextActionTypeKeys from "./ConsumerContextActionTypeKeys";
export function getConsumerMetadata(dispatch) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(inProgress());
        const response = yield getConsumerDomainData();
        if ((_a = response.data) === null || _a === void 0 ? void 0 : _a.result) {
            const result = response.data.result;
            const countryOptions = [];
            (_b = result.countries) === null || _b === void 0 ? void 0 : _b.forEach((country) => {
                countryOptions.push({
                    key: country.id,
                    text: country.name,
                    alpha2: country.alpha2,
                });
            });
            const languageOptions = [];
            (_c = result.languages) === null || _c === void 0 ? void 0 : _c.forEach((language) => {
                languageOptions.push({
                    key: language.alpha2,
                    text: language.name,
                    id: language.id,
                });
            });
            const issueTypeOptions = [];
            (_d = result.issueTypes) === null || _d === void 0 ? void 0 : _d.forEach((issueType) => {
                issueTypeOptions.push({ key: issueType.id, text: issueType.name });
            });
            const productFamilyOptions = [];
            (_e = result.consumerProducts) === null || _e === void 0 ? void 0 : _e.forEach((product) => {
                productFamilyOptions.push({ key: product.id, text: product.name });
            });
            const flagOptions = [];
            (_f = result.flags) === null || _f === void 0 ? void 0 : _f.forEach((flag) => {
                flagOptions.push({
                    key: flag.id,
                    text: flag.name,
                    isActive: flag.isActive,
                });
            });
            const dfCRoutings = [];
            (_g = result.dfCRoutings) === null || _g === void 0 ? void 0 : _g.forEach((route) => {
                dfCRoutings.push({
                    issueTypeId: route.issueTypeId,
                    ProductTypeId: route.ProductTypeId,
                });
            });
            const ConsumerMetadata = {
                countries: countryOptions,
                languages: languageOptions,
                issueTypes: issueTypeOptions,
                productFamilies: productFamilyOptions,
                flags: flagOptions,
                dfCRoutings: dfCRoutings,
            };
            dispatch(success(ConsumerMetadata));
        }
        else {
            dispatch(failure(((_j = (_h = response.data) === null || _h === void 0 ? void 0 : _h.errors[0]) === null || _j === void 0 ? void 0 : _j.Message) ||
                "Error occured while fetching home user data"));
        }
    });
}
export function updateConsumerBasicDetails(dispatch, basicDetails) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch({
            payload: basicDetails,
            type: ConsumerContextActionTypeKeys.Update_BasicDetails,
        });
    });
}
export function updateConsumerEscalationDetails(dispatch, escalationDetails) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch({
            payload: escalationDetails,
            type: ConsumerContextActionTypeKeys.Update_EscalationDetails,
        });
    });
}
export function updateConsumerBasicDetailsValidationStatus(dispatch, isValid) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch({
            payload: isValid,
            type: ConsumerContextActionTypeKeys.Update_BasicDetailsValidationStatus,
        });
    });
}
export function updateConsumerEscalationDetailsValidationStatus(dispatch, isValid) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch({
            payload: isValid,
            type: ConsumerContextActionTypeKeys.Update_EscalationDetailsValidationStatus,
        });
    });
}
export function submitConsumerEscalation(dispatch, consumerEscalation) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(submitInprogress(true));
        // Calling service layer with consumer escalation model.
        createConsumerEscalation(consumerEscalation).then((data) => {
            try {
                // Access the data here
                const uemTrackingID = data === null || data === void 0 ? void 0 : data.uemTrackingId;
                if (uemTrackingID)
                    dispatch(submitSuccess(uemTrackingID));
                else {
                    dispatch(submitInprogress(false));
                    dispatch(submitFail("Failed to submit the escalation. Please validate the details and submit again."));
                }
            }
            catch (error) {
                dispatch(submitFail(error));
            }
        });
    });
}
function inProgress() {
    return {
        payload: true,
        type: ConsumerContextActionTypeKeys.Get_ConsumerMetadata_InProgress,
    };
}
function success(consumerDomaindata) {
    return {
        payload: consumerDomaindata,
        type: ConsumerContextActionTypeKeys.Get_ConsumerMetadata_Success,
    };
}
function failure(error) {
    return {
        type: ConsumerContextActionTypeKeys.Get_ConsumerMetadata_Failure,
        payload: error,
    };
}
export function submitEscalation(consumerEscalation) {
    return {
        type: ConsumerContextActionTypeKeys.Submit_ConsumerEscalation,
        payload: consumerEscalation,
    };
}
export function submitSuccess(uemTrackingID) {
    return {
        type: ConsumerContextActionTypeKeys.Submit_ConsumerEscalation_Success,
        payload: uemTrackingID,
    };
}
export function submitFail(error) {
    return {
        type: ConsumerContextActionTypeKeys.Submit_ConsumerEscalation_Failure,
        payload: error,
    };
}
export function submitInprogress(progress) {
    return {
        type: ConsumerContextActionTypeKeys.Submit_ConsumerEscalation_InProgress,
        payload: progress,
    };
}
