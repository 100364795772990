import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { FlowTypeSelector } from "../../models/Commercial/FlowDetails";
import * as Imports from "./Imports";
export var TabType;
(function (TabType) {
    TabType[TabType["Basics"] = 0] = "Basics";
    TabType[TabType["EscalationDetails"] = 1] = "EscalationDetails";
    TabType[TabType["Review"] = 2] = "Review";
})(TabType || (TabType = {}));
const GetTabComponents = (props) => {
    switch (props.flowType) {
        case `${FlowTypeSelector.SpecialProgram}-${TabType.Basics}`:
            return (_jsx(Imports.SpecialProgramBasicTab, { basicsNextClicked: props.basicsNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.SpecialProgram}-${TabType.EscalationDetails}`:
            return (_jsx(Imports.SpecialProgramDetailsTab, { escalationNextClicked: props.escalationNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.SpecialProgram}-${TabType.Review}`:
            return _jsx(Imports.SpecialProgramReviewTab, {});
        case `${FlowTypeSelector.PSST}-${TabType.Basics}`:
            return (_jsx(Imports.PSSTBasicsTab, { basicsNextClicked: props.basicsNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.PSST}-${TabType.EscalationDetails}`:
            return (_jsx(Imports.PSSTEscalationDetailsTab, { escalationNextClicked: props.escalationNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.PSST}-${TabType.Review}`:
            return _jsx(Imports.PSSTReviewTab, {});
        case `${FlowTypeSelector.License}-${TabType.Basics}`:
            return (_jsx(Imports.LicenseQBasicsTab, { basicsNextClicked: props.basicsNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.License}-${TabType.EscalationDetails}`:
            return (_jsx(Imports.LicenseQEscalationDetailsTab, { escalationNextClicked: props.escalationNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.License}-${TabType.Review}`:
            return _jsx(Imports.LicenseQReviewTab, {});
        case `${FlowTypeSelector.PCA}-${TabType.Basics}`:
            return (_jsx(Imports.PCABasicsTab, { basicsNextClicked: props.basicsNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.PCA}-${TabType.EscalationDetails}`:
            return (_jsx(Imports.PCAEscalationDetailsTab, { escalationNextClicked: props.escalationNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.PCA}-${TabType.Review}`:
            return _jsx(Imports.PCAReviewTab, {});
        case `${FlowTypeSelector.CPP}-${TabType.Basics}`:
            return (_jsx(Imports.CPPBasicsTab, { basicsNextClicked: props.basicsNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.CPP}-${TabType.EscalationDetails}`:
            return (_jsx(Imports.CPPEscalationDetailsTab, { escalationNextClicked: props.escalationNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.CPP}-${TabType.Review}`:
            return _jsx(Imports.CPPReviewTab, {});
        case `${FlowTypeSelector.TechProductIssue}-${TabType.Basics}`:
            return (_jsx(Imports.TechProductIssueBasicsTab, { basicsNextClicked: props.basicsNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.TechProductIssue}-${TabType.EscalationDetails}`:
            return (_jsx(Imports.TechProductIssueEscalationDetailsTab, { escalationNextClicked: props.escalationNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.TechProductIssue}-${TabType.Review}`:
            return _jsx(Imports.TechProductIssueReviewTab, {});
        case `${FlowTypeSelector.ELP}-${TabType.Basics}`:
            return (_jsx(Imports.ELPBasicsTab, { basicsNextClicked: props.basicsNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.ELP}-${TabType.EscalationDetails}`:
            return (_jsx(Imports.ELPEscalationDetailsTab, { escalationNextClicked: props.escalationNextClicked, moveToNextTab: props.moveToNextTab }));
        case `${FlowTypeSelector.ELP}-${TabType.Review}`:
            return _jsx(Imports.ELPReviewTab, {});
        default:
            return _jsx(_Fragment, {});
    }
};
export default GetTabComponents;
