export var HttpStatusCode;
(function (HttpStatusCode) {
    HttpStatusCode[HttpStatusCode["Continue"] = 100] = "Continue";
    //
    // Summary:
    //     Equivalent to HTTP status 101. System.Net.HttpStatusCode.SwitchingProtocols indicates
    //     that the protocol version or protocol is being changed.
    HttpStatusCode[HttpStatusCode["SwitchingProtocols"] = 101] = "SwitchingProtocols";
    //
    // Summary:
    //     Equivalent to HTTP status 102. System.Net.HttpStatusCode.Processing indicates
    //     that the server has accepted the complete request but hasn't completed it yet.
    HttpStatusCode[HttpStatusCode["Processing"] = 102] = "Processing";
    //
    // Summary:
    //     Equivalent to HTTP status 103. System.Net.HttpStatusCode.EarlyHints indicates
    //     to the client that the server is likely to send a final response with the header
    //     fields included in the informational response.
    HttpStatusCode[HttpStatusCode["EarlyHints"] = 103] = "EarlyHints";
    //
    // Summary:
    //     Equivalent to HTTP status 200. System.Net.HttpStatusCode.OK indicates that the
    //     request succeeded and that the requested information is in the response. This
    //     is the most common status code to receive.
    HttpStatusCode[HttpStatusCode["OK"] = 200] = "OK";
    //
    // Summary:
    //     Equivalent to HTTP status 201. System.Net.HttpStatusCode.Created indicates that
    //     the request resulted in a new resource created before the response was sent.
    HttpStatusCode[HttpStatusCode["Created"] = 201] = "Created";
    //
    // Summary:
    //     Equivalent to HTTP status 202. System.Net.HttpStatusCode.Accepted indicates that
    //     the request has been accepted for further processing.
    HttpStatusCode[HttpStatusCode["Accepted"] = 202] = "Accepted";
    //
    // Summary:
    //     Equivalent to HTTP status 203. System.Net.HttpStatusCode.NonAuthoritativeInformation
    //     indicates that the returned meta information is from a cached copy instead of
    //     the origin server and therefore may be incorrect.
    HttpStatusCode[HttpStatusCode["NonAuthoritativeInformation"] = 203] = "NonAuthoritativeInformation";
    //
    // Summary:
    //     Equivalent to HTTP status 204. System.Net.HttpStatusCode.NoContent indicates
    //     that the request has been successfully processed and that the response is intentionally
    //     blank.
    HttpStatusCode[HttpStatusCode["NoContent"] = 204] = "NoContent";
    //
    // Summary:
    //     Equivalent to HTTP status 205. System.Net.HttpStatusCode.ResetContent indicates
    //     that the client should reset (not reload) the current resource.
    HttpStatusCode[HttpStatusCode["ResetContent"] = 205] = "ResetContent";
    //
    // Summary:
    //     Equivalent to HTTP status 206. System.Net.HttpStatusCode.PartialContent indicates
    //     that the response is a partial response as requested by a GET request that includes
    //     a byte range.
    HttpStatusCode[HttpStatusCode["PartialContent"] = 206] = "PartialContent";
    //
    // Summary:
    //     Equivalent to HTTP status 207. System.Net.HttpStatusCode.MultiStatus indicates
    //     multiple status codes for a single response during a Web Distributed Authoring
    //     and Versioning (WebDAV) operation. The response body contains XML that describes
    //     the status codes.
    HttpStatusCode[HttpStatusCode["MultiStatus"] = 207] = "MultiStatus";
    //
    // Summary:
    //     Equivalent to HTTP status 208. System.Net.HttpStatusCode.AlreadyReported indicates
    //     that the members of a WebDAV binding have already been enumerated in a preceding
    //     part of the multistatus response, and are not being included again.
    HttpStatusCode[HttpStatusCode["AlreadyReported"] = 208] = "AlreadyReported";
    //
    // Summary:
    //     Equivalent to HTTP status 226. System.Net.HttpStatusCode.IMUsed indicates that
    //     the server has fulfilled a request for the resource, and the response is a representation
    //     of the result of one or more instance-manipulations applied to the current instance.
    HttpStatusCode[HttpStatusCode["IMUsed"] = 226] = "IMUsed";
    //
    // Summary:
    //     Equivalent to HTTP status 300. System.Net.HttpStatusCode.Ambiguous indicates
    //     that the requested information has multiple representations. The default action
    //     is to treat this status as a redirect and follow the contents of the Location
    //     header associated with this response. Ambiguous is a synonym for MultipleChoices.
    HttpStatusCode[HttpStatusCode["Ambiguous"] = 300] = "Ambiguous";
    //
    // Summary:
    //     Equivalent to HTTP status 300. System.Net.HttpStatusCode.MultipleChoices indicates
    //     that the requested information has multiple representations. The default action
    //     is to treat this status as a redirect and follow the contents of the Location
    //     header associated with this response. MultipleChoices is a synonym for Ambiguous.
    HttpStatusCode[HttpStatusCode["MultipleChoices"] = 300] = "MultipleChoices";
    //
    // Summary:
    //     Equivalent to HTTP status 301. System.Net.HttpStatusCode.Moved indicates that
    //     the requested information has been moved to the URI specified in the Location
    //     header. The default action when this status is received is to follow the Location
    //     header associated with the response. When the original request method was POST,
    //     the redirected request will use the GET method. Moved is a synonym for MovedPermanently.
    HttpStatusCode[HttpStatusCode["Moved"] = 301] = "Moved";
    //
    // Summary:
    //     Equivalent to HTTP status 301. System.Net.HttpStatusCode.MovedPermanently indicates
    //     that the requested information has been moved to the URI specified in the Location
    //     header. The default action when this status is received is to follow the Location
    //     header associated with the response. MovedPermanently is a synonym for Moved.
    HttpStatusCode[HttpStatusCode["MovedPermanently"] = 301] = "MovedPermanently";
    //
    // Summary:
    //     Equivalent to HTTP status 302. System.Net.HttpStatusCode.Found indicates that
    //     the requested information is located at the URI specified in the Location header.
    //     The default action when this status is received is to follow the Location header
    //     associated with the response. When the original request method was POST, the
    //     redirected request will use the GET method. Found is a synonym for Redirect.
    HttpStatusCode[HttpStatusCode["Found"] = 302] = "Found";
    //
    // Summary:
    //     Equivalent to HTTP status 302. System.Net.HttpStatusCode.Redirect indicates that
    //     the requested information is located at the URI specified in the Location header.
    //     The default action when this status is received is to follow the Location header
    //     associated with the response. When the original request method was POST, the
    //     redirected request will use the GET method. Redirect is a synonym for Found.
    HttpStatusCode[HttpStatusCode["Redirect"] = 302] = "Redirect";
    //
    // Summary:
    //     Equivalent to HTTP status 303. System.Net.HttpStatusCode.RedirectMethod automatically
    //     redirects the client to the URI specified in the Location header as the result
    //     of a POST. The request to the resource specified by the Location header will
    //     be made with a GET. RedirectMethod is a synonym for SeeOther.
    HttpStatusCode[HttpStatusCode["RedirectMethod"] = 303] = "RedirectMethod";
    //
    // Summary:
    //     Equivalent to HTTP status 303. System.Net.HttpStatusCode.SeeOther automatically
    //     redirects the client to the URI specified in the Location header as the result
    //     of a POST. The request to the resource specified by the Location header will
    //     be made with a GET. SeeOther is a synonym for RedirectMethod.
    HttpStatusCode[HttpStatusCode["SeeOther"] = 303] = "SeeOther";
    //
    // Summary:
    //     Equivalent to HTTP status 304. System.Net.HttpStatusCode.NotModified indicates
    //     that the client's cached copy is up to date. The contents of the resource are
    //     not transferred.
    HttpStatusCode[HttpStatusCode["NotModified"] = 304] = "NotModified";
    //
    // Summary:
    //     Equivalent to HTTP status 305. System.Net.HttpStatusCode.UseProxy indicates that
    //     the request should use the proxy server at the URI specified in the Location
    //     header.
    HttpStatusCode[HttpStatusCode["UseProxy"] = 305] = "UseProxy";
    //
    // Summary:
    //     Equivalent to HTTP status 306. System.Net.HttpStatusCode.Unused is a proposed
    //     extension to the HTTP/1.1 specification that is not fully specified.
    HttpStatusCode[HttpStatusCode["Unused"] = 306] = "Unused";
    //
    // Summary:
    //     Equivalent to HTTP status 307. System.Net.HttpStatusCode.RedirectKeepVerb indicates
    //     that the request information is located at the URI specified in the Location
    //     header. The default action when this status is received is to follow the Location
    //     header associated with the response. When the original request method was POST,
    //     the redirected request will also use the POST method. RedirectKeepVerb is a synonym
    //     for TemporaryRedirect.
    HttpStatusCode[HttpStatusCode["RedirectKeepVerb"] = 307] = "RedirectKeepVerb";
    //
    // Summary:
    //     Equivalent to HTTP status 307. System.Net.HttpStatusCode.TemporaryRedirect indicates
    //     that the request information is located at the URI specified in the Location
    //     header. The default action when this status is received is to follow the Location
    //     header associated with the response. When the original request method was POST,
    //     the redirected request will also use the POST method. TemporaryRedirect is a
    //     synonym for RedirectKeepVerb.
    HttpStatusCode[HttpStatusCode["TemporaryRedirect"] = 307] = "TemporaryRedirect";
    //
    // Summary:
    //     Equivalent to HTTP status 308. System.Net.HttpStatusCode.PermanentRedirect indicates
    //     that the request information is located at the URI specified in the Location
    //     header. The default action when this status is received is to follow the Location
    //     header associated with the response. When the original request method was POST,
    //     the redirected request will also use the POST method.
    HttpStatusCode[HttpStatusCode["PermanentRedirect"] = 308] = "PermanentRedirect";
    //
    // Summary:
    //     Equivalent to HTTP status 400. System.Net.HttpStatusCode.BadRequest indicates
    //     that the request could not be understood by the server. System.Net.HttpStatusCode.BadRequest
    //     is sent when no other error is applicable, or if the exact error is unknown or
    //     does not have its own error code.
    HttpStatusCode[HttpStatusCode["BadRequest"] = 400] = "BadRequest";
    //
    // Summary:
    //     Equivalent to HTTP status 401. System.Net.HttpStatusCode.Unauthorized indicates
    //     that the requested resource requires authentication. The WWW-Authenticate header
    //     contains the details of how to perform the authentication.
    HttpStatusCode[HttpStatusCode["Unauthorized"] = 401] = "Unauthorized";
    //
    // Summary:
    //     Equivalent to HTTP status 402. System.Net.HttpStatusCode.PaymentRequired is reserved
    //     for future use.
    HttpStatusCode[HttpStatusCode["PaymentRequired"] = 402] = "PaymentRequired";
    //
    // Summary:
    //     Equivalent to HTTP status 403. System.Net.HttpStatusCode.Forbidden indicates
    //     that the server refuses to fulfill the request.
    HttpStatusCode[HttpStatusCode["Forbidden"] = 403] = "Forbidden";
    //
    // Summary:
    //     Equivalent to HTTP status 404. System.Net.HttpStatusCode.NotFound indicates that
    //     the requested resource does not exist on the server.
    HttpStatusCode[HttpStatusCode["NotFound"] = 404] = "NotFound";
    //
    // Summary:
    //     Equivalent to HTTP status 405. System.Net.HttpStatusCode.MethodNotAllowed indicates
    //     that the request method (POST or GET) is not allowed on the requested resource.
    HttpStatusCode[HttpStatusCode["MethodNotAllowed"] = 405] = "MethodNotAllowed";
    //
    // Summary:
    //     Equivalent to HTTP status 406. System.Net.HttpStatusCode.NotAcceptable indicates
    //     that the client has indicated with Accept headers that it will not accept any
    //     of the available representations of the resource.
    HttpStatusCode[HttpStatusCode["NotAcceptable"] = 406] = "NotAcceptable";
    //
    // Summary:
    //     Equivalent to HTTP status 407. System.Net.HttpStatusCode.ProxyAuthenticationRequired
    //     indicates that the requested proxy requires authentication. The Proxy-authenticate
    //     header contains the details of how to perform the authentication.
    HttpStatusCode[HttpStatusCode["ProxyAuthenticationRequired"] = 407] = "ProxyAuthenticationRequired";
    //
    // Summary:
    //     Equivalent to HTTP status 408. System.Net.HttpStatusCode.RequestTimeout indicates
    //     that the client did not send a request within the time the server was expecting
    //     the request.
    HttpStatusCode[HttpStatusCode["RequestTimeout"] = 408] = "RequestTimeout";
    //
    // Summary:
    //     Equivalent to HTTP status 409. System.Net.HttpStatusCode.Conflict indicates that
    //     the request could not be carried out because of a conflict on the server.
    HttpStatusCode[HttpStatusCode["Conflict"] = 409] = "Conflict";
    //
    // Summary:
    //     Equivalent to HTTP status 410. System.Net.HttpStatusCode.Gone indicates that
    //     the requested resource is no longer available.
    HttpStatusCode[HttpStatusCode["Gone"] = 410] = "Gone";
    //
    // Summary:
    //     Equivalent to HTTP status 411. System.Net.HttpStatusCode.LengthRequired indicates
    //     that the required Content-length header is missing.
    HttpStatusCode[HttpStatusCode["LengthRequired"] = 411] = "LengthRequired";
    //
    // Summary:
    //     Equivalent to HTTP status 412. System.Net.HttpStatusCode.PreconditionFailed indicates
    //     that a condition set for this request failed, and the request cannot be carried
    //     out. Conditions are set with conditional request headers like If-Match, If-None-Match,
    //     or If-Unmodified-Since.
    HttpStatusCode[HttpStatusCode["PreconditionFailed"] = 412] = "PreconditionFailed";
    //
    // Summary:
    //     Equivalent to HTTP status 413. System.Net.HttpStatusCode.RequestEntityTooLarge
    //     indicates that the request is too large for the server to process.
    HttpStatusCode[HttpStatusCode["RequestEntityTooLarge"] = 413] = "RequestEntityTooLarge";
    //
    // Summary:
    //     Equivalent to HTTP status 414. System.Net.HttpStatusCode.RequestUriTooLong indicates
    //     that the URI is too long.
    HttpStatusCode[HttpStatusCode["RequestUriTooLong"] = 414] = "RequestUriTooLong";
    //
    // Summary:
    //     Equivalent to HTTP status 415. System.Net.HttpStatusCode.UnsupportedMediaType
    //     indicates that the request is an unsupported type.
    HttpStatusCode[HttpStatusCode["UnsupportedMediaType"] = 415] = "UnsupportedMediaType";
    //
    // Summary:
    //     Equivalent to HTTP status 416. System.Net.HttpStatusCode.RequestedRangeNotSatisfiable
    //     indicates that the range of data requested from the resource cannot be returned,
    //     either because the beginning of the range is before the beginning of the resource,
    //     or the end of the range is after the end of the resource.
    HttpStatusCode[HttpStatusCode["RequestedRangeNotSatisfiable"] = 416] = "RequestedRangeNotSatisfiable";
    //
    // Summary:
    //     Equivalent to HTTP status 417. System.Net.HttpStatusCode.ExpectationFailed indicates
    //     that an expectation given in an Expect header could not be met by the server.
    HttpStatusCode[HttpStatusCode["ExpectationFailed"] = 417] = "ExpectationFailed";
    //
    // Summary:
    //     Equivalent to HTTP status 421. System.Net.HttpStatusCode.MisdirectedRequest indicates
    //     that the request was directed at a server that is not able to produce a response.
    HttpStatusCode[HttpStatusCode["MisdirectedRequest"] = 421] = "MisdirectedRequest";
    //
    // Summary:
    //     Equivalent to HTTP status 422. System.Net.HttpStatusCode.UnprocessableEntity
    //     indicates that the request was well-formed but was unable to be followed due
    //     to semantic errors.
    HttpStatusCode[HttpStatusCode["UnprocessableEntity"] = 422] = "UnprocessableEntity";
    //
    // Summary:
    //     Equivalent to HTTP status 423. System.Net.HttpStatusCode.Locked indicates that
    //     the source or destination resource is locked.
    HttpStatusCode[HttpStatusCode["Locked"] = 423] = "Locked";
    //
    // Summary:
    //     Equivalent to HTTP status 424. System.Net.HttpStatusCode.FailedDependency indicates
    //     that the method couldn't be performed on the resource because the requested action
    //     depended on another action and that action failed.
    HttpStatusCode[HttpStatusCode["FailedDependency"] = 424] = "FailedDependency";
    //
    // Summary:
    //     Equivalent to HTTP status 426. System.Net.HttpStatusCode.UpgradeRequired indicates
    //     that the client should switch to a different protocol such as TLS/1.0.
    HttpStatusCode[HttpStatusCode["UpgradeRequired"] = 426] = "UpgradeRequired";
    //
    // Summary:
    //     Equivalent to HTTP status 428. System.Net.HttpStatusCode.PreconditionRequired
    //     indicates that the server requires the request to be conditional.
    HttpStatusCode[HttpStatusCode["PreconditionRequired"] = 428] = "PreconditionRequired";
    //
    // Summary:
    //     Equivalent to HTTP status 429. System.Net.HttpStatusCode.TooManyRequests indicates
    //     that the user has sent too many requests in a given amount of time.
    HttpStatusCode[HttpStatusCode["TooManyRequests"] = 429] = "TooManyRequests";
    //
    // Summary:
    //     Equivalent to HTTP status 431. System.Net.HttpStatusCode.RequestHeaderFieldsTooLarge
    //     indicates that the server is unwilling to process the request because its header
    //     fields (either an individual header field or all the header fields collectively)
    //     are too large.
    HttpStatusCode[HttpStatusCode["RequestHeaderFieldsTooLarge"] = 431] = "RequestHeaderFieldsTooLarge";
    //
    // Summary:
    //     Equivalent to HTTP status 451. System.Net.HttpStatusCode.UnavailableForLegalReasons
    //     indicates that the server is denying access to the resource as a consequence
    //     of a legal demand.
    HttpStatusCode[HttpStatusCode["UnavailableForLegalReasons"] = 451] = "UnavailableForLegalReasons";
    //
    // Summary:
    //     Equivalent to HTTP status 500. System.Net.HttpStatusCode.InternalServerError
    //     indicates that a generic error has occurred on the server.
    HttpStatusCode[HttpStatusCode["InternalServerError"] = 500] = "InternalServerError";
    //
    // Summary:
    //     Equivalent to HTTP status 501. System.Net.HttpStatusCode.NotImplemented indicates
    //     that the server does not support the requested function.
    HttpStatusCode[HttpStatusCode["NotImplemented"] = 501] = "NotImplemented";
    //
    // Summary:
    //     Equivalent to HTTP status 502. System.Net.HttpStatusCode.BadGateway indicates
    //     that an intermediate proxy server received a bad response from another proxy
    //     or the origin server.
    HttpStatusCode[HttpStatusCode["BadGateway"] = 502] = "BadGateway";
    //
    // Summary:
    //     Equivalent to HTTP status 503. System.Net.HttpStatusCode.ServiceUnavailable indicates
    //     that the server is temporarily unavailable, usually due to high load or maintenance.
    HttpStatusCode[HttpStatusCode["ServiceUnavailable"] = 503] = "ServiceUnavailable";
    //
    // Summary:
    //     Equivalent to HTTP status 504. System.Net.HttpStatusCode.GatewayTimeout indicates
    //     that an intermediate proxy server timed out while waiting for a response from
    //     another proxy or the origin server.
    HttpStatusCode[HttpStatusCode["GatewayTimeout"] = 504] = "GatewayTimeout";
    //
    // Summary:
    //     Equivalent to HTTP status 505. System.Net.HttpStatusCode.HttpVersionNotSupported
    //     indicates that the requested HTTP version is not supported by the server.
    HttpStatusCode[HttpStatusCode["HttpVersionNotSupported"] = 505] = "HttpVersionNotSupported";
    //
    // Summary:
    //     Equivalent to HTTP status 506. System.Net.HttpStatusCode.VariantAlsoNegotiates
    //     indicates that the chosen variant resource is configured to engage in transparent
    //     content negotiation itself and, therefore, isn't a proper endpoint in the negotiation
    //     process.
    HttpStatusCode[HttpStatusCode["VariantAlsoNegotiates"] = 506] = "VariantAlsoNegotiates";
    //
    // Summary:
    //     Equivalent to HTTP status 507. System.Net.HttpStatusCode.InsufficientStorage
    //     indicates that the server is unable to store the representation needed to complete
    //     the request.
    HttpStatusCode[HttpStatusCode["InsufficientStorage"] = 507] = "InsufficientStorage";
    //
    // Summary:
    //     Equivalent to HTTP status 508. System.Net.HttpStatusCode.LoopDetected indicates
    //     that the server terminated an operation because it encountered an infinite loop
    //     while processing a WebDAV request with "Depth: infinity". This status code is
    //     meant for backward compatibility with clients not aware of the 208 status code
    //     System.Net.HttpStatusCode.AlreadyReported appearing in multistatus response bodies.
    HttpStatusCode[HttpStatusCode["LoopDetected"] = 508] = "LoopDetected";
    //
    // Summary:
    //     Equivalent to HTTP status 510. System.Net.HttpStatusCode.NotExtended indicates
    //     that further extensions to the request are required for the server to fulfill
    //     it.
    HttpStatusCode[HttpStatusCode["NotExtended"] = 510] = "NotExtended";
    //
    // Summary:
    //     Equivalent to HTTP status 511. System.Net.HttpStatusCode.NetworkAuthenticationRequired
    //     indicates that the client needs to authenticate to gain network access; it's
    //     intended for use by intercepting proxies used to control access to the network.
    HttpStatusCode[HttpStatusCode["NetworkAuthenticationRequired"] = 511] = "NetworkAuthenticationRequired";
})(HttpStatusCode || (HttpStatusCode = {}));
