import { jsx as _jsx } from "react/jsx-runtime";
import * as React from "react";
import { ElxRichTextEditor } from "@elixir/fx/lib/components/Editor/RichText/ElxRichTextEditor";
import { defaultElxTextEditorStyles, useFormRichTextEditorOverrideStyles, } from "./FormRichTextEditor.styles";
import { Stack, mergeStyles } from "@fluentui/react";
import { InputMessageTypes } from "@elixir/components";
const FormRichTextEditor = (props) => {
    var _a, _b;
    const textEditorClass = mergeStyles(useFormRichTextEditorOverrideStyles, props.styles);
    const [lengthErrorMsg, setLengthErrorMsg] = React.useState(undefined);
    const getCurrentTextLength = (currentValue) => {
        const valueHolder = document.createElement("div");
        valueHolder.innerHTML = currentValue;
        return valueHolder.innerText.length;
    };
    React.useEffect(() => {
        var _a;
        if (props.maxLength) {
            const currentTextLength = getCurrentTextLength((_a = props.value) !== null && _a !== void 0 ? _a : "");
            if (currentTextLength > props.maxLength) {
                if (props.setIsLengthValid)
                    props.setIsLengthValid(false);
                setLengthErrorMsg({
                    type: InputMessageTypes.Error,
                    content: "Number of characters cannot exceed " + props.maxLength + ". Current character count: " + currentTextLength,
                });
            }
            else if (lengthErrorMsg !== undefined) {
                if (props.setIsLengthValid)
                    props.setIsLengthValid(true);
                setLengthErrorMsg(undefined);
            }
        }
    }, [props.value]);
    return (_jsx("div", Object.assign({ className: textEditorClass }, { children: _jsx(Stack, Object.assign({ onBlur: props.onBlur }, { children: _jsx(ElxRichTextEditor, { id: props.id, label: props.label, ariaLabel: props.ariaLabel + ". Please enter your text in the multiline input text editor.", value: props.value, onChange: props.onChange, required: props.required, disabled: props.disabled, message: (_a = props.message) !== null && _a !== void 0 ? _a : lengthErrorMsg, description: props.description, styles: (_b = props.styles) !== null && _b !== void 0 ? _b : defaultElxTextEditorStyles }) })) })));
};
export default FormRichTextEditor;
