import { mergeStyles } from "@fluentui/react";
import { BREAKPOINT } from "../../commoncomponents/Constants.styles";
export const headerStyle = mergeStyles({
    selectors: {
        "& > .elx-container > .container-header > .headerContainer-173 > .container-headings > .container-heading": {
            height: 1,
        },
    },
});
export const useEfdContainerStyles = mergeStyles({
    root: {
        "& .container-body": {
            display: "flex",
            flex: "none",
            flexWrap: "wrap",
            flexDirection: "row !important",
            justifyContent: "flex-start",
            paddingLeft: "2rem",
            paddingRight: "2rem",
            "& .tileContainer": {
                display: "flex",
                flexWrap: "wrap",
                textOverflow: "ellipsis",
                background: "#F7F8F9",
            },
            "& .tile-section >.tile >.tile-content": {
                background: "white",
            },
            [`@media (max-width: ${BREAKPOINT}px)`]: {
                flexFlow: "column nowrap!important",
                overflow: "auto",
                flex: "1",
            },
            [`@media (max-height: ${BREAKPOINT}px)`]: {
                flexFlow: "column nowrap!important",
                overflow: "auto",
                flex: "1",
            },
        },
    },
});
