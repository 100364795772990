import CustomerDataActionTypeKeys from "./ActionTypeKeys";
export function CustomerDataReducer(state = {
    CustomerData: [],
    Error: "",
    InProgress: false,
    PartnerData: [],
}, action) {
    switch (action.type) {
        case CustomerDataActionTypeKeys.Get_CustomerData_Success:
            return Object.assign(Object.assign({}, state), { CustomerData: action.payload, Error: "", InProgress: false, PartnerData: [] });
        case CustomerDataActionTypeKeys.Get_CustomerData_Failure:
            return Object.assign(Object.assign({}, state), { CustomerData: [], Error: action.payload, InProgress: false, PartnerData: [] });
        case CustomerDataActionTypeKeys.Get_CustomerData_InProgress:
            return Object.assign(Object.assign({}, state), { CustomerData: [], Error: "", InProgress: action.payload, PartnerData: [] });
        case CustomerDataActionTypeKeys.Get_PartnerData_Success:
            return Object.assign(Object.assign({}, state), { CustomerData: [], Error: "", InProgress: false, PartnerData: action.payload });
        default:
            return state;
    }
}
