import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Label, Stack } from "@fluentui/react";
import ReactHtmlParser from "react-html-parser";
import { useReviewFormStyles } from "./ReviewForm.styles";
export default function ReviewForm(props) {
    const reviewFormStyle = useReviewFormStyles();
    return (_jsx(Stack, Object.assign({ className: reviewFormStyle, tabIndex: 0 }, { children: props.reviewDataArray.map((item) => (_jsx(Stack.Item, Object.assign({ className: "section" }, { children: _jsxs(Stack, { children: [_jsx("h4", { children: item.header }), item.data.map((rowItem) => {
                        var _a;
                        return rowItem.label && (_jsxs(Stack.Item, Object.assign({ className: "reviewForm-row" }, { children: [_jsx(Label, Object.assign({ className: "rowLabel" }, { children: rowItem.label })), rowItem.type == "list" ? (_jsx("div", Object.assign({ className: "valueList rowValue" }, { children: (_a = rowItem.value) === null || _a === void 0 ? void 0 : _a.map((li) => (_jsx("ul", { children: li }, li))) }))) : rowItem.type == "html" ? (_jsx(Label, Object.assign({ className: "rowValue" }, { children: ReactHtmlParser(rowItem.value) }))) : (_jsx(Label, Object.assign({ className: "rowValue" }, { children: rowItem.value })))] }), rowItem.label));
                    })] }, item.header) }), item.header))) })));
}
