export const defaultAttachmentStyle = () => {
    return {
        root: {},
        subComponentStyles: {
            attachmentAddContainer: {
                root: {
                    margin: "1rem",
                    textAlign: "center",
                    width: "500px",
                    height: "180px",
                    padding: "0.5rem",
                    backgroundColor: "#F3F2F1",
                    justifyContent: "center",
                },
            },
            attachmentLabel: {
                root: { fontWeight: 400, fontSize: "13px" },
            },
            attachmentLabel2: {
                root: { fontWeight: 400, fontSize: "13px", textAlign: "left" },
            },
            attachmentListContainer: {
                root: { width: "87%", height: "200px", overflowY: "auto" },
            },
            attachmentLink: {
                root: {
                    fontSize: "1rem",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                },
            },
            attachmentFont: {
                root: {
                    fontSize: "13px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                },
            },
            attachmentDocumentCard: {
                root: {
                    maxWidth: "inherit",
                    display: "flex",
                    flexFlow: "row nowrap",
                    justifyContent: "space-between",
                    whiteSpace: "nowrap",
                    alignItems: "center",
                    height: "30px",
                },
            },
            attachmentDocumentCardTextStack: {
                root: {
                    display: "flex",
                    flexFlow: "row nowrap",
                    alignItems: "center",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                },
            },
            attachmentIcon: { root: { marginLeft: "1rem", marginRight: "0.5rem" } },
            attachmentErrorText: { root: { color: "red" } },
        },
    };
};
