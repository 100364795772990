var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getDataFromSessionStorage } from "../config/configHelper";
import { HttpService } from "../library/http/http.service";
export function getCustomerData(searchText) {
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            const searchRequest = {
                IsTop200SalesAccountsWithoutSearch: false,
                IsAllBySearchCriteria: false
            };
            const isNumber = !isNaN(+searchText);
            if (isNumber) {
                searchRequest.TPId = searchText;
            }
            else {
                searchRequest.AccountName = searchText;
            }
            const apiresponse = yield http.post(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("lookupSalesinfoRoute"), undefined, searchRequest);
            response = (apiresponse === null || apiresponse === void 0 ? void 0 : apiresponse.data).result;
        }
        catch (e) {
            const error = { message: e };
            throw error;
        }
        return response;
    });
}
export function getCustomerDataCollection(requestPayload) {
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            const apiresponse = yield http.post(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("lookupSalesinfoRoute"), undefined, requestPayload);
            response = (apiresponse === null || apiresponse === void 0 ? void 0 : apiresponse.data).result;
        }
        catch (e) {
            const error = { message: e };
            throw error;
        }
        return response;
    });
}
