// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../images/AzureCXPNew-small-logo.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.azureLogo{background-image:url(${___CSS_LOADER_URL_REPLACEMENT_0___}) !important;margin-right:8px;margin-left:10px;height:34px;width:29px}`, "",{"version":3,"sources":["webpack://./src/shell/content/branding.css"],"names":[],"mappings":"AAAA,WACE,mEAAA,CACA,gBAAA,CACA,gBAAA,CACA,WAAA,CACA,UAAA","sourcesContent":[".azureLogo {\n  background-image: url(\"../../images/AzureCXPNew-small-logo.png\") !important;\n  margin-right: 8px;\n  margin-left: 10px;\n  height: 34px;\n  width: 29px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
