import MyEscalationsActionTypeKeys from "./ActionTypeKeys";
export function MyEscalationsReducer(state = { Escalations: {}, Error: "" }, action) {
    switch (action.type) {
        case MyEscalationsActionTypeKeys.Get_MyEscalations_Success:
            return Object.assign(Object.assign({}, state), { Escalations: action.payload, Error: "", InProgress: false });
        case MyEscalationsActionTypeKeys.Set_Followup_Response:
            return Object.assign(Object.assign({}, state), { FollowupResponse: action.payload, Error: "", InProgress: false });
        case MyEscalationsActionTypeKeys.Set_EscalationDetails:
            return Object.assign(Object.assign({}, state), { SelectedEscalationRequestNumber: action.payload, Error: "", InProgress: false });
        case MyEscalationsActionTypeKeys.Set_MyCasesView:
            return Object.assign(Object.assign({}, state), { MyCasesViewDefault: action.payload });
        case MyEscalationsActionTypeKeys.Set_HomeView:
            return Object.assign(Object.assign({}, state), { HomeViewDefault: action.payload });
        case MyEscalationsActionTypeKeys.Get_MyEscalations_Failure:
            return Object.assign(Object.assign({}, state), { Error: action.payload, InProgress: false });
        case MyEscalationsActionTypeKeys.Get_MyEscalations_InProgress:
            return Object.assign(Object.assign({}, state), { Error: "", InProgress: action.payload });
        default:
            return state;
    }
}
