import { FlowTypeSelector } from "../../models/Commercial/FlowDetails";
export const GetSubHeader = (flowType) => {
    switch (flowType) {
        case FlowTypeSelector.SpecialProgram:
            return "Special Programs - By Referral Only";
        case FlowTypeSelector.PSST:
            return "Commerce, Volume Licensing and Post Sales Seller Support";
        case FlowTypeSelector.License:
            return "LicenseQ";
        case FlowTypeSelector.PCA:
            return "Privacy-Compliance-Audit";
        case FlowTypeSelector.CPP:
            return "Customer or Partner Programs";
        case FlowTypeSelector.TechProductIssue:
            return "Technical Product Issues";
        case FlowTypeSelector.ELP:
            return "Executive Customer Relations";
        default:
            return "Commercial";
    }
};
