var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getDataFromSessionStorage } from "../config/configHelper";
import { HttpService } from "../library/http/http.service";
import { getCurrentUser } from "@elixir/auth";
export function getMyEscalations() {
    var _a, _b, _c, _d;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        let euresponse;
        try {
            const http = new HttpService();
            const details = getCurrentUser();
            if (details !== undefined &&
                details !== null &&
                details.account !== undefined &&
                details.account !== null &&
                details.account.length > 0) {
                const email = `${details === null || details === void 0 ? void 0 : details.account}`;
                response = yield http.get(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("myescalationsRoute") + email, undefined, undefined);
                // Fetching Data from EU Instance if enabled
                try {
                    if (((_a = getDataFromSessionStorage("isEUEnabled")) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === "true") {
                        euresponse = yield http.get(getDataFromSessionStorage("euFrontdoorbaseurl"), getDataFromSessionStorage("myescalationsRoute") + email, undefined, undefined);
                        const globalEscalations = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.result;
                        const euEscalations = (_c = euresponse === null || euresponse === void 0 ? void 0 : euresponse.data) === null || _c === void 0 ? void 0 : _c.result;
                        // Appending EU Cases to Global Instance
                        // Setting isEU flag to identify EU Cases.
                        (_d = euEscalations.commercialEscalation) === null || _d === void 0 ? void 0 : _d.forEach((escalation) => {
                            var _a;
                            escalation.isEU = true;
                            (_a = globalEscalations.commercialEscalation) === null || _a === void 0 ? void 0 : _a.push(escalation);
                        });
                        response.data.result = globalEscalations;
                    }
                }
                catch (e) {
                    const error = { message: e };
                    // Placeholder - has to be pushed to App-insights/Geneva
                    console.log(error);
                }
            }
        }
        catch (e) {
            const error = { message: e };
            throw error;
        }
        return response;
    });
}
