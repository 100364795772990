import { Guid } from "guid-typescript";
export const isAlphaNumeric = (text) => {
    const regex = /^[a-zA-Z0-9\s]+$/;
    return regex.test(text);
};
export const isValidName = (text) => {
    const htmlRegex = /<[^>]+>/;
    return !htmlRegex.test(text);
};
export const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
};
export const isValidNumber = (number, isPhoneNumber = false) => {
    if (isPhoneNumber && number.length < 4)
        return false;
    const numbersRegex = isPhoneNumber ? /^\+?[0-9]+$/ : /^\d+$/;
    return numbersRegex.test(number);
};
export const isValidSupportcase = (supportRequestId) => {
    const numbersRegex = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9-]+$/;
    return numbersRegex.test(supportRequestId);
};
export const isValidTenantName = (tenantName) => {
    const regex = /^((?!-)[A-Za-z0-9-]{1,63}(?<!-)\.)+[A-Za-z]{2,4}$/;
    return regex.test(tenantName);
};
export const isValidTenantId = (tenantId) => {
    return Guid.isGuid(tenantId);
};
