import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment, useState } from "react";
import { useHistory } from "react-router";
import { ElxContainer, ElxIconButton, ElxLink, ElxPrimaryButton, ElxSecondaryButton, } from "@elixir/components";
import { Checkbox, Stack, Text } from "@fluentui/react";
import { useLocaleStrings } from "../../../library/providers/strings";
import { useCommercialContextDispatch } from "../../../reducers/CommercialContext/CommercialContextProvider";
import CustomHeader from "../../../commoncomponents/CustomHeader";
import "./Acknowledgement.styles";
const headings = [
    {
        header: "Translation errors:",
        text: "These scenarios are typically where the translation of a document from one language to another leads to inconsistencies in the licensing terms.",
    },
    {
        header: "Contradictions or inconsistencies in materials:",
        text: "These scenarios are typically when a published document is not consistent with another published.",
    },
    {
        header: "Mis-publications:",
        text: "These scenarios deal with incorrect information, typos, or content from previous documentation that were not included in subsequent versions of documents.",
    },
];
export const AcknowledgementDetail = (props) => {
    const { getLocalSpecificString } = useLocaleStrings();
    const commercialContextDispatch = useCommercialContextDispatch();
    const [enableEscalationButton, setEnableEscalationButton] = useState(false);
    const history = useHistory();
    const onCreateEscalationClick = () => {
        commercialContextDispatch.updateEnableAcknowledgement(true);
    };
    const onCancel = () => {
        history.push("/");
    };
    function renderHeader() {
        return (_jsx(CustomHeader, { onCancel: onCancel, headerText: "Before creating a LicenseQ escalation", iconButtonText: "" }));
    }
    return (_jsx(ElxContainer, Object.assign({ headerContent: renderHeader(), fillBackground: true, className: "licenseQAcknowledgementContainer" }, { children: _jsxs(Stack, Object.assign({ className: "licenseQAcknowledgementContent" }, { children: [_jsx(Stack.Item, Object.assign({ styles: { root: { height: "100%" } } }, { children: _jsxs(Stack, Object.assign({ styles: {
                            root: {
                                maxWidth: "45.5rem",
                                paddingLeft: "1rem",
                                paddingRight: "1rem"
                            },
                        }, tokens: { childrenGap: 15 } }, { children: [_jsxs(Stack.Item, { children: [_jsx("h4", Object.assign({ style: { fontSize: 14 } }, { children: getLocalSpecificString("licenseQDirectHelpHeader") })), _jsx(Text, { children: getLocalSpecificString("licenseQDirectHelpHeaderValue") })] }), _jsxs(Stack.Item, { children: [_jsx(Text, Object.assign({ styles: { root: { fontSize: 13, fontWeight: 600 } } }, { children: getLocalSpecificString("licenseQSupportPortalHeader") })), _jsx("br", {}), _jsxs(Text, { children: [getLocalSpecificString("licenseQSupportHeaderValue"), "\u00A0", _jsx(ElxLink, Object.assign({ href: getLocalSpecificString("licenseQResourceUrl"), target: "_blank" }, { children: "Click here to go to LicenseQ Support Knowledge Portal" })), _jsx(ElxIconButton, { iconProps: { iconName: "OpenInNewWindow" }, href: getLocalSpecificString("licenseQResourceUrl"), text: "", target: "_blank", "aria-label": "Click to Open LicenseQ Knowledge Portal" })] })] }), _jsxs(Stack.Item, { children: [_jsx(Text, { children: getLocalSpecificString("licenseQSupportText") }), _jsx("ul", { children: headings.map((item, index) => (_jsxs(Fragment, { children: [_jsxs("li", { children: [_jsx("b", { children: item.header }), item.text] }, index), _jsx("br", {})] }, index))) })] }), _jsx(Stack.Item, { children: _jsxs(Stack, Object.assign({ horizontal: true, tokens: { childrenGap: 10 }, styles: { root: { marginTop: "-15px" } } }, { children: [_jsx(Stack.Item, Object.assign({ styles: { root: { maxWidth: 250 } } }, { children: _jsx(Text, { children: getLocalSpecificString("licenseQCompletedResearchText") }) })), _jsx(Stack.Item, { children: _jsx(Checkbox, { required: true, onChange: (event, value) => {
                                                    setEnableEscalationButton(value);
                                                }, ariaLabel: getLocalSpecificString("licenseQCompletedResearchText") }) })] })) })] })) })), _jsx(Stack.Item, { children: _jsxs(Stack, Object.assign({ horizontal: true, tokens: { childrenGap: 10 }, className: "customFooter", wrap: true }, { children: [_jsx(Stack.Item, Object.assign({ align: "center" }, { children: _jsx(ElxPrimaryButton, { text: "Continue to create LicenseQ escalation", disabled: !enableEscalationButton, onClick: onCreateEscalationClick }) })), _jsx(Stack.Item, Object.assign({ align: "center" }, { children: _jsx(ElxSecondaryButton, { text: "Cancel", onClick: onCancel }) }))] })) })] })) })));
};
