import CommercialContextActionTypeKeys from "./CommercialContextActionTypeKeys";
import { initialState, } from "./CommercialContextProvider";
export function CommercialContextReducer(state = {
    CommercialContext: initialState,
    InProgress: true,
    Error: "",
}, action) {
    switch (action.type) {
        case CommercialContextActionTypeKeys.Get_CommercialMetadata_Success: {
            return {
                CommercialContext: Object.assign(Object.assign({}, state.CommercialContext), { CommercialMetadata: action.payload }),
                InProgress: false,
                Error: "",
            };
        }
        case CommercialContextActionTypeKeys.Get_CommercialMetadata_InProgress: {
            return {
                CommercialContext: Object.assign({}, state.CommercialContext),
                InProgress: action.payload,
                Error: "",
            };
        }
        case CommercialContextActionTypeKeys.Get_CommercialMetadata_Failure: {
            return {
                CommercialContext: Object.assign({}, state.CommercialContext),
                InProgress: false,
                Error: action.payload,
            };
        }
        case CommercialContextActionTypeKeys.Update_EnableAcknowledgement: {
            return {
                CommercialContext: Object.assign(Object.assign({}, state.CommercialContext), { EnableAcknowledgement: action.payload }),
                InProgress: state.InProgress,
                Error: state.Error,
            };
        }
        default:
            return state;
    }
}
