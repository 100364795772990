export const enrichConsumerFollowup = (Type, escalation, Comments) => {
    var _a, _b, _c, _d, _e, _f;
    const date = ((_a = escalation === null || escalation === void 0 ? void 0 : escalation.createdOn) === null || _a === void 0 ? void 0 : _a.toString()) || 0;
    const consumerFollowup = {
        CaseCreatedOn: new Date(date),
        CaseModifiedOn: escalation === null || escalation === void 0 ? void 0 : escalation.lastUpdatedDate,
        Details: escalation === null || escalation === void 0 ? void 0 : escalation.description,
        SRNumber: escalation === null || escalation === void 0 ? void 0 : escalation.escalationRequestNumber,
        FollowupType: Type,
        EmployeeEmail: (_b = escalation === null || escalation === void 0 ? void 0 : escalation.submitterInfo) === null || _b === void 0 ? void 0 : _b.submitterEmail,
        CaseOwnerEmail: escalation === null || escalation === void 0 ? void 0 : escalation.caseOwner,
        SubmitterEmail: (_c = escalation === null || escalation === void 0 ? void 0 : escalation.submitterInfo) === null || _c === void 0 ? void 0 : _c.submitterEmail,
        Comments: Comments,
        TicketId: escalation === null || escalation === void 0 ? void 0 : escalation.escalationRequestNumber,
        EmployeeFirstName: (_d = escalation === null || escalation === void 0 ? void 0 : escalation.submitterInfo) === null || _d === void 0 ? void 0 : _d.submitterFirstName,
        EmployeeLastName: (_e = escalation === null || escalation === void 0 ? void 0 : escalation.submitterInfo) === null || _e === void 0 ? void 0 : _e.submitterLastName,
        RegionName: (_f = escalation === null || escalation === void 0 ? void 0 : escalation.country) === null || _f === void 0 ? void 0 : _f.name,
    };
    return consumerFollowup;
};
export const enrichCommercialFollowup = (Type, escalation, Comments) => {
    var _a, _b, _c, _d;
    const commercialFollowup = {
        CaseCreatedOn: escalation === null || escalation === void 0 ? void 0 : escalation.createdOn,
        CaseModifiedOn: (_a = escalation === null || escalation === void 0 ? void 0 : escalation.lastUpdatedDate) === null || _a === void 0 ? void 0 : _a.toString(),
        Details: escalation === null || escalation === void 0 ? void 0 : escalation.description,
        SRNumber: escalation === null || escalation === void 0 ? void 0 : escalation.escalationRequestNumber,
        FollowupType: Type,
        EmployeeEmail: (_b = escalation === null || escalation === void 0 ? void 0 : escalation.submitterInfo) === null || _b === void 0 ? void 0 : _b.submitterEmail,
        CaseOwnerEmail: escalation === null || escalation === void 0 ? void 0 : escalation.caseOwner,
        SubmitterEmail: (_c = escalation === null || escalation === void 0 ? void 0 : escalation.submitterInfo) === null || _c === void 0 ? void 0 : _c.submitterEmail,
        Comments: Comments,
        TicketId: escalation === null || escalation === void 0 ? void 0 : escalation.escalationRequestNumber,
        RegionName: (_d = escalation === null || escalation === void 0 ? void 0 : escalation.country) === null || _d === void 0 ? void 0 : _d.name,
        isEU: escalation === null || escalation === void 0 ? void 0 : escalation.isEU,
    };
    return commercialFollowup;
};
