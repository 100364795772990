export const convertUTCtoLocal = (utcDateTime) => {
    // Parse the UTC date and time string
    const utcDate = new Date(utcDateTime);
    const localDate = new Date(utcDate.getTime() + utcDate.getTimezoneOffset() * 60 * 1000);
    // Format the local date and time
    const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    };
    const localDateTime = localDate.toLocaleDateString("en-US", options);
    return localDateTime;
};
export const convertISOtoLocal = (isoDateTime) => {
    // Parse the ISO date and time string
    const isodate = new Date(isoDateTime);
    // Format the local date and time
    const options = {
        month: "short",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
    };
    const localDateTime = isodate.toLocaleDateString("en-US", options);
    return localDateTime;
};
