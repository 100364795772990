var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { ElxMasterDetailContainer, ElxMessageBarType, ElxTabControlOrientation, } from "@elixir/components";
import { getCurrentUser } from "@elixir/auth";
import { BasicsTab } from "../components/HomeUser/BasicsTab";
import { EscalationDetailsTab } from "../components/HomeUser/EscalationDetailsTab";
import { ReviewTab } from "../components/HomeUser/ReviewTab";
import { useRootContext } from "../library/providers/root/RootContextProvider";
import EscalationSubmittedDetailPage from "../components/EscalationSubmittedPage/EscalationSubmittedDetailPage";
import { UseConsumerContextDispatch, UseConsumerContextState, } from "../reducers/ConsumerContext/ConsumerContextProvider";
export function ConsumerContainer(props) {
    var _a;
    const consumerContextState = UseConsumerContextState();
    const setConsumerContextState = UseConsumerContextDispatch();
    const { privileges: { isQALoB }, isConfigurationSet, } = useRootContext();
    const [basicsNextClicked, setBasicsNextClicked] = useState(0);
    const [escalationNextClicked, setEscalationNextClicked] = useState(0);
    const [displayMessage, setDisplayMessage] = useState(undefined);
    const [currentTabIndex, setCurrentTabIndex] = useState(0);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        if (isConfigurationSet)
            setConsumerContextState.GetConsumerMetadata();
    }, [isConfigurationSet]);
    useEffect(() => {
        setIsLoading(consumerContextState.InProgress);
    }, [consumerContextState.InProgress]);
    const onSubmit = () => __awaiter(this, void 0, void 0, function* () {
        var _b, _c, _d, _e;
        const consumerMetadata = consumerContextState.ConsumerContext.ConsumerMetadata;
        const basicDetails = consumerContextState.ConsumerContext.BasicDetails;
        const escalationDetails = consumerContextState.ConsumerContext.EscalationDetails;
        //temperary workaround to pass Microsoft Account ID info
        const valueHolder = document.createElement("div");
        valueHolder.innerHTML = escalationDetails.issueDescription;
        const msaSpan = document.createElement("span");
        msaSpan.innerText =
            "Microsoft Account ID: " + basicDetails.microsoftAccountId;
        (_b = valueHolder.lastChild) === null || _b === void 0 ? void 0 : _b.appendChild(document.createElement("br"));
        (_c = valueHolder.lastChild) === null || _c === void 0 ? void 0 : _c.appendChild(msaSpan);
        const issueDescription = valueHolder.innerHTML;
        const date = new Date();
        const attachmentList = [];
        const countryIndex = consumerMetadata.countries.findIndex((x) => {
            return x.key === basicDetails.customerLocation;
        });
        const languageIndex = consumerMetadata.languages.findIndex((x) => {
            return x.key === basicDetails.preferredLanguage;
        });
        const flagIndex = consumerMetadata.flags.findIndex((x) => {
            return x.key === escalationDetails.escalationSourceId;
        });
        const submitterArray = getCurrentUser().displayName.split(" ");
        (_d = escalationDetails.attachments) === null || _d === void 0 ? void 0 : _d.map((x) => {
            var _a;
            const byteArray = new Uint8Array(atob((_a = x.contentBase64) !== null && _a !== void 0 ? _a : "")
                .split("")
                .map(function (char) {
                return char.charCodeAt(0);
            }));
            attachmentList.push({
                FileName: x.fileName,
                ContentBytes: Array.from(byteArray),
                UniqueIdentifier: crypto.randomUUID(),
                FileSize: x.size,
                ContentType: x.contentType,
            });
        });
        const consumerRequest = {
            CustomerInfo: {
                CustomerEmail: basicDetails.customerEmail,
                CustomerPhone: (_e = basicDetails.customerPhone) !== null && _e !== void 0 ? _e : "",
                CustomerFirstName: basicDetails.customerFirstName,
                CustomerLastName: basicDetails.customerLastName,
                PreferredLanguage: basicDetails.preferredLanguage,
                MicrosoftAccount: basicDetails.microsoftAccountId,
                CustomerSegmentName: "Consumer",
            },
            Country: {
                id: basicDetails.customerLocation,
                name: consumerMetadata.countries[countryIndex].text,
                alpha2: consumerMetadata.countries[countryIndex].alpha2,
            },
            Language: {
                name: consumerMetadata.languages[languageIndex].text,
                alpha2: basicDetails.preferredLanguage,
                id: consumerMetadata.languages[languageIndex].id,
            },
            IssueType: {
                id: escalationDetails.issueTypeId,
                isActive: true,
                name: escalationDetails.issueType,
            },
            Product: {
                id: escalationDetails.productFamilyId,
                isActive: true,
                name: escalationDetails.productFamily,
            },
            Description: issueDescription,
            DescriptionHtml: issueDescription,
            SupportRequestNumber: escalationDetails.supportRequestNumber,
            LastUpdatedDate: date.toISOString(),
            CreatedOn: date.toISOString(),
            SubmitterInfo: {
                SubmitterEmail: getCurrentUser().account,
                SubmitterFirstName: submitterArray[0],
                SubmitterLastName: submitterArray
                    .slice(1, submitterArray.length)
                    .join(" "),
                LogOnBehalfOf: basicDetails.submitterEmail,
            },
        };
        // eslint-disable-next-line
        if (isQALoB && flagIndex >= 0) {
            consumerRequest.Flag = {
                id: escalationDetails.escalationSourceId,
                name: consumerMetadata.flags[flagIndex].text,
                isActive: consumerMetadata.flags[flagIndex].isActive,
            };
        }
        consumerRequest.Attachments = attachmentList;
        setConsumerContextState.SubmitConsumerEscalation(consumerRequest);
    });
    useEffect(() => {
        if (consumerContextState.SubmitError.length > 0) {
            const displayError = {
                message: consumerContextState.SubmitError,
                type: ElxMessageBarType.error,
                iconProps: { iconName: "errorBadge" },
            };
            setDisplayMessage(displayError);
        }
        else {
            if (consumerContextState.SubmitInProgress) {
                const displayError = {
                    message: "Escalation submission is in progress.",
                    type: ElxMessageBarType.info,
                    iconProps: { iconName: "InfoSolid" },
                };
                setDisplayMessage(displayError);
            }
            else
                setDisplayMessage(undefined);
        }
    }, [consumerContextState.SubmitInProgress, consumerContextState.SubmitError]);
    const history = useHistory();
    const onCancel = () => {
        history.push("/");
    };
    const moveToNextTab = (isValid) => {
        if (isValid) {
            setCurrentTabIndex(prev => prev + 1);
        }
        setIsLoading(false);
    };
    const tabs = [
        {
            key: "0",
            name: "Basics",
            component: _jsx(BasicsTab, { basicsNextClicked: basicsNextClicked, moveToNextTab: moveToNextTab }),
            primaryButton: {
                onClick: (_event) => {
                    setIsLoading(true);
                    setBasicsNextClicked((prevState) => prevState + 1);
                },
            },
            cancelButton: {
                text: "Close",
                onClick: onCancel,
            },
            isCompleted: consumerContextState.ConsumerContext.areBasicDetailsValid === true,
        },
        {
            key: "1",
            name: "Escalation Details",
            component: (_jsx(EscalationDetailsTab, { escalationNextClicked: escalationNextClicked, moveToNextTab: moveToNextTab })),
            primaryButton: {
                onClick: (_event) => {
                    setIsLoading(true);
                    setEscalationNextClicked((prevState) => prevState + 1);
                },
            },
            cancelButton: {
                text: "Close",
                onClick: onCancel,
            },
            backButton: {
                text: "Previous"
            },
            disabled: consumerContextState.ConsumerContext.areBasicDetailsValid !== true,
            isCompleted: consumerContextState.ConsumerContext.areEscalationDetailsValid === true,
        },
        {
            key: "2",
            name: "Review",
            component: _jsx(ReviewTab, {}),
            primaryButton: {
                text: "Submit",
                disabled: consumerContextState.ConsumerContext.areBasicDetailsValid !== true ||
                    consumerContextState.ConsumerContext.areEscalationDetailsValid !==
                        true ||
                    consumerContextState.SubmitInProgress,
                onClick: onSubmit,
            },
            cancelButton: {
                text: "Close",
                onClick: onCancel,
            },
            backButton: {
                text: "Previous"
            },
            disabled: consumerContextState.ConsumerContext.areBasicDetailsValid !== true ||
                consumerContextState.ConsumerContext.areEscalationDetailsValid !== true,
        },
    ];
    return (_jsx(_Fragment, { children: !consumerContextState.uemTrackingId ? (_jsx(ElxMasterDetailContainer, { headerText: "Create an Escalation", subHeaderText: "Home User", wizard: true, tabsOrientation: ElxTabControlOrientation.Horizontal, showNumeric: true, maxDisplayActions: 3, tabs: tabs, selectedTabIndex: currentTabIndex, primaryIsCustom: true, onTabChange: (tabIndex) => {
                setCurrentTabIndex(tabIndex);
            }, isLoading: isLoading, hasPrevButton: true, styles: { root: { minHeight: "50vh" }, dropdownSection: { paddingTop: "15px" } }, message: displayMessage }, currentTabIndex)) : (_jsx(EscalationSubmittedDetailPage, { uemTrackingId: (_a = consumerContextState.uemTrackingId) !== null && _a !== void 0 ? _a : "", subHeaderTitle: "Home User", escalationType: 0, submitInProgress: consumerContextState.SubmitInProgress, submitFailedErrorMsg: consumerContextState.SubmitError })) }));
}
