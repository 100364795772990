var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { getCustomerData, getCustomerDataCollection, } from "../../services/customersearch";
import CustomerDataActionTypeKeys from "./ActionTypeKeys";
import { getPartnerInfo } from "../../services";
export default function getCustomerDataAction(searchText, dispatch) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(inProgress());
        if (searchText.length > 0) {
            yield getCustomerData(searchText).then((data) => {
                try {
                    // Access the data here
                    const customerData = data;
                    if (customerData)
                        dispatch(success(customerData));
                    else {
                        dispatch(failure("Error occured while fetching customer data"));
                    }
                }
                catch (error) {
                    dispatch(failure(error));
                }
            });
        }
        else {
            dispatch(success([]));
        }
    });
}
export function getPartnerDataAction(searchText, dispatch) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(inProgress());
        if (searchText.length > 0) {
            yield getPartnerInfo({ PartnerName: searchText }).then((data) => {
                try {
                    // Access the data here
                    const partnerData = data;
                    if (partnerData)
                        dispatch(partnerSuccess(partnerData));
                    else {
                        dispatch(failure("Error occured while fetching partner data"));
                    }
                }
                catch (error) {
                    dispatch(failure(error));
                }
            });
        }
        else {
            dispatch(success([]));
        }
    });
}
export function getPartnerDataCollectionAction(requestPayload, dispatch) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(inProgress());
        yield getPartnerInfo(requestPayload).then((data) => {
            try {
                // Access the data here
                const partnerData = data;
                if (partnerData)
                    dispatch(partnerSuccess(partnerData));
                else {
                    dispatch(failure("Error occured while fetching partner data"));
                }
            }
            catch (error) {
                dispatch(failure(error));
            }
        });
    });
}
export function getCustomerDataCollectionAction(requestPayload, dispatch) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(inProgress());
        yield getCustomerDataCollection(requestPayload).then((data) => {
            try {
                // Access the data here
                const customerData = data;
                if (customerData)
                    dispatch(success(customerData));
                else {
                    dispatch(failure("Error occured while fetching customer data"));
                }
            }
            catch (error) {
                dispatch(failure(error));
            }
        });
    });
}
function inProgress() {
    return {
        payload: true,
        type: CustomerDataActionTypeKeys.Get_CustomerData_InProgress,
    };
}
function success(customerData) {
    return {
        payload: customerData,
        type: CustomerDataActionTypeKeys.Get_CustomerData_Success,
    };
}
function failure(error) {
    return {
        type: CustomerDataActionTypeKeys.Get_CustomerData_Failure,
        payload: error,
    };
}
function partnerSuccess(partnerData) {
    return {
        payload: partnerData,
        type: CustomerDataActionTypeKeys.Get_PartnerData_Success,
    };
}
