export const AttachmentExtensions = [
    "bmp",
    "csv",
    "doc",
    "docx",
    "eml",
    "gif",
    "gz",
    "htm",
    "html",
    "jpg",
    "mht",
    "mhtm",
    "mhtml",
    "msg",
    "odf",
    "odt",
    "pdf",
    "png",
    "ppt",
    "pptx",
    "page",
    "rar",
    "rtf",
    "tif",
    "tiff",
    "txt",
    "vsd",
    "vsdx",
    "xls",
    "xlsb",
    "xlsx",
    "xml",
    "wpd",
    "wps",
    "zip",
];
