import * as React from "react";
import { uniqueId } from "lodash";
/**
 * Creates scope object.
 * Scope object is used as a key to access the framework managed states,
 *
 * @param name (optional) scope name
 */
export function createScope(name) {
    return {
        name: uniqueId(name || "elixir_scope"),
    };
}
export function useScope(name) {
    const [scope] = React.useState(() => createScope(name));
    return scope;
}
