import { jsx as _jsx } from "react/jsx-runtime";
import React, { useReducer } from "react";
import { CommercialContextReducer } from "./CommercialContextReducer";
import { getCommercialMetadata, getEngagementLookUpInfo, getOpportunityLookUpInfo, updateCommercialEnableAcknowledgement, } from "./CommercialContextActions";
export const initialState = {
    EnableAcknowledgement: false,
    CommercialMetadata: {
        countries: [],
        languages: [],
        caseSource: [],
        agreementTypes: [],
        products: [],
        affectedProducts: [],
        programs: [],
        affectedPrograms: [],
        azureSubscriptionTypes: [],
        tenantTypes: [],
        businessRisks: [],
        questionnaire: [],
        engagementTotalUsers: "",
        engagementTotalAmount: "",
        opportunityTotalAmount: "",
    },
};
const CommercialContextState = React.createContext({});
const CommercialContextDispatch = React.createContext({});
export function useCommercialContextState() {
    const context = React.useContext(CommercialContextState);
    if (context === undefined) {
        throw new Error("UseCommercialContextState must be used within a CommercialContextProvider");
    }
    return context;
}
export function useCommercialContextDispatch() {
    const context = React.useContext(CommercialContextDispatch);
    if (context === undefined) {
        throw new Error("UseCommercialContextDispatch must be used within a CommercialContextProvider");
    }
    return context;
}
export const CommercialContextProvider = (props) => {
    const [context, contextDispatch] = useReducer(CommercialContextReducer, {
        CommercialContext: initialState,
        InProgress: true,
        Error: "",
    });
    const getMetadata = () => {
        getCommercialMetadata(contextDispatch);
    };
    const getEngagementInfo = (engagementRequest, metadata) => {
        getEngagementLookUpInfo(contextDispatch, engagementRequest, metadata);
    };
    const getOpportunityInfo = (opportunityRequest, metadata) => {
        getOpportunityLookUpInfo(contextDispatch, opportunityRequest, metadata);
    };
    const updateEnableAcknowledgement = (enableAcknowledgement) => {
        updateCommercialEnableAcknowledgement(contextDispatch, enableAcknowledgement);
    };
    const commercialState = {
        CommercialContext: context.CommercialContext,
        InProgress: context.InProgress,
        Error: context.Error,
    };
    const commercialDispatch = {
        getCommercialMetadata: getMetadata,
        getEngagementLookUpInfo: getEngagementInfo,
        getOpportunityLookUpInfo: getOpportunityInfo,
        updateEnableAcknowledgement: updateEnableAcknowledgement,
    };
    return (_jsx(CommercialContextState.Provider, Object.assign({ value: commercialState }, { children: _jsx(CommercialContextDispatch.Provider, Object.assign({ value: commercialDispatch }, { children: props.children })) })));
};
