var CustomerDataActionTypeKeys;
(function (CustomerDataActionTypeKeys) {
    CustomerDataActionTypeKeys["Get_CustomerData"] = "GET_CUSTOMERDATA";
    CustomerDataActionTypeKeys["Get_CustomerData_Success"] = "GET_CUSTOMERDATA_SUCCESS";
    CustomerDataActionTypeKeys["Get_CustomerData_Failure"] = "GET_CUSTOMERDATA_ERROR";
    CustomerDataActionTypeKeys["Get_CustomerData_InProgress"] = "GET_CUSTOMERDATA_INPROGRESS";
    CustomerDataActionTypeKeys["Get_PartnerData"] = "GET_PARTNERDATA";
    CustomerDataActionTypeKeys["Get_PartnerData_Success"] = "GET_PARTNERDATA_SUCCESS";
})(CustomerDataActionTypeKeys || (CustomerDataActionTypeKeys = {}));
export default CustomerDataActionTypeKeys;
