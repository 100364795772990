var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpService } from "../library/http/http.service";
import { getDataFromSessionStorage } from "../config/configHelper";
export function createConsumerFollowup(followup) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.post(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("consumerFollowupRoute"), undefined, followup);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.result;
    });
}
export function createCommercialFollowup(followup) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.post(followup.isEU
                ? getDataFromSessionStorage("euFrontdoorbaseurl")
                : getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("commercialFollowupRoute"), undefined, followup);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.result;
    });
}
