var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpService } from "../library/http/http.service";
import { getDataFromSessionStorage } from "../config/configHelper";
export function getMyEscalations(useralias) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.get(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("myescalationsRoute") + useralias, undefined, undefined);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.result;
    });
}
export function getTenantInfo(tenantName) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.get(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("lookupTenantinfoRoute") + tenantName, undefined, undefined);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.result;
    });
}
export function getEngagementInfo(engagementRequest) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.post(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("lookupEngagementRoute"), undefined, engagementRequest);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return response;
    });
}
export function getOpportunityInfo(opportunityRequest) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.post(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("lookupOpportunityRoute"), undefined, opportunityRequest);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return response;
    });
}
export function getPartnerInfo(partnerInfoRequest) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.post(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("lookupPartnerinfoRoute"), undefined, partnerInfoRequest);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.result;
    });
}
export function getSalesAccountInfo(salesAccountRequest) {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.post(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("lookupSalesinfoRoute"), undefined, salesAccountRequest);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.result;
    });
}
export function getAppConfig() {
    var _a, _b, _c;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            const frontDoorBaseURL = process.env.FRONTDOOR_BASEURL || "";
            response = yield http.get(frontDoorBaseURL, "/api/get/configuration", undefined, undefined);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.result;
    });
}
export function validateEmails(emails) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.get(getDataFromSessionStorage("globalFrontdoorbaseurl"), `${getDataFromSessionStorage("validateEmailsRoute")}${emails}`, undefined, undefined);
        }
        catch (e) {
            const error = { message: e };
            return (_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data;
        }
        return response.data.result;
    });
}
