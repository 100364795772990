var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HttpService } from "../library/http/http.service";
import { getDataFromSessionStorage } from "../config/configHelper";
export function getEscalationRequestByTrackingId(escalationNumberRequest) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        let finalResponse;
        let response;
        try {
            const http = new HttpService();
            response = yield http.post(escalationNumberRequest.IsEU
                ? getDataFromSessionStorage("euFrontdoorbaseurl")
                : getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("getsupportrequestRoute"), undefined, escalationNumberRequest);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return yield ((_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data);
        }
        if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
            const escalations = yield response.data.result;
            finalResponse = escalations;
        }
        return finalResponse;
    });
}
export function getConsumerDomainData() {
    return __awaiter(this, void 0, void 0, function* () {
        let response;
        try {
            const http = new HttpService();
            response = yield http.get(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("consumerDomaindataRoute"), undefined, undefined);
        }
        catch (e) {
            const error = { message: e };
            throw error;
        }
        return response;
    });
}
export function createConsumerEscalation(consumerEscalationRequest) {
    var _a, _b;
    return __awaiter(this, void 0, void 0, function* () {
        let finalResponse;
        let response;
        try {
            const http = new HttpService();
            response = yield http.post(getDataFromSessionStorage("globalFrontdoorbaseurl"), getDataFromSessionStorage("createConsumerEscalationRoute"), undefined, consumerEscalationRequest);
        }
        catch (e) {
            // catch clause variable can be 'any' or 'unknown' only.
            const error = { message: e };
            return yield ((_b = (_a = error === null || error === void 0 ? void 0 : error.message) === null || _a === void 0 ? void 0 : _a.response) === null || _b === void 0 ? void 0 : _b.data);
        }
        if ((response === null || response === void 0 ? void 0 : response.status) === 200) {
            const escalations = yield response.data.result;
            finalResponse = escalations;
        }
        return finalResponse;
    });
}
