import ConsumerContextActionTypeKeys from "./ConsumerContextActionTypeKeys";
import { initialState } from "./ConsumerContextProvider";
export function ConsumerContextReducer(state = {
    ConsumerContext: initialState,
    InProgress: true,
    Error: "",
    uemTrackingId: undefined,
    SubmitInProgress: false,
    SubmitError: ""
}, action) {
    switch (action.type) {
        case ConsumerContextActionTypeKeys.Get_ConsumerMetadata_Success: {
            return {
                ConsumerContext: Object.assign(Object.assign({}, state.ConsumerContext), { ConsumerMetadata: action.payload }),
                InProgress: false,
                Error: "",
                uemTrackingId: undefined,
                SubmitInProgress: false,
                SubmitError: ""
            };
        }
        case ConsumerContextActionTypeKeys.Get_ConsumerMetadata_InProgress: {
            return {
                ConsumerContext: Object.assign({}, state.ConsumerContext),
                InProgress: action.payload,
                Error: "",
                uemTrackingId: undefined,
                SubmitInProgress: false,
                SubmitError: ""
            };
        }
        case ConsumerContextActionTypeKeys.Get_ConsumerMetadata_Failure: {
            return {
                ConsumerContext: Object.assign({}, state.ConsumerContext),
                InProgress: false,
                Error: action.payload,
                uemTrackingId: undefined,
                SubmitInProgress: false,
                SubmitError: ""
            };
        }
        case ConsumerContextActionTypeKeys.Update_BasicDetails: {
            return {
                ConsumerContext: Object.assign(Object.assign({}, state.ConsumerContext), { BasicDetails: action.payload }),
                InProgress: false,
                Error: "",
                uemTrackingId: undefined,
                SubmitInProgress: false,
                SubmitError: ""
            };
        }
        case ConsumerContextActionTypeKeys.Update_EscalationDetails: {
            return {
                ConsumerContext: Object.assign(Object.assign({}, state.ConsumerContext), { EscalationDetails: action.payload }),
                InProgress: false,
                Error: "",
                uemTrackingId: undefined,
                SubmitInProgress: false,
                SubmitError: ""
            };
        }
        case ConsumerContextActionTypeKeys.Update_BasicDetailsValidationStatus: {
            return {
                ConsumerContext: Object.assign(Object.assign({}, state.ConsumerContext), { areBasicDetailsValid: action.payload }),
                InProgress: false,
                Error: "",
                uemTrackingId: undefined,
                SubmitInProgress: false,
                SubmitError: ""
            };
        }
        case ConsumerContextActionTypeKeys.Update_EscalationDetailsValidationStatus: {
            return {
                ConsumerContext: Object.assign(Object.assign({}, state.ConsumerContext), { areEscalationDetailsValid: action.payload }),
                InProgress: false,
                Error: "",
                uemTrackingId: undefined,
                SubmitInProgress: false,
                SubmitError: ""
            };
        }
        case ConsumerContextActionTypeKeys.Submit_ConsumerEscalation_Success: {
            return {
                ConsumerContext: Object.assign({}, state.ConsumerContext),
                InProgress: false,
                Error: "",
                uemTrackingId: action.payload,
                SubmitInProgress: false,
                SubmitError: ""
            };
        }
        case ConsumerContextActionTypeKeys.Submit_ConsumerEscalation_Failure: {
            return Object.assign(Object.assign({}, state), { SubmitError: action.payload, SubmitInProgress: false });
        }
        case ConsumerContextActionTypeKeys.Submit_ConsumerEscalation_InProgress: {
            return Object.assign(Object.assign({}, state), { SubmitInProgress: action.payload, SubmitError: "" });
        }
        default:
            return state;
    }
}
