import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import { getMyEscalationsAction, createConsumerFollowupAction, createCommercialFollowupAction, setSelectedEscalationRequestNumberAction, setMyCasesViewAction, setHomeViewAction, } from "./Actions";
import { MyEscalationsReducer } from "./MyEscalationsReducer";
const MyEscalationsState = React.createContext({});
const MyEscalationsDispatch = React.createContext({});
export function UseMyEscalationsState() {
    const context = React.useContext(MyEscalationsState);
    if (context === undefined) {
        throw new Error("UseMyEscalationsState must be used within a MyEscalationsContextProvider");
    }
    return context;
}
export function UseMyEscalationsDispatch() {
    const context = React.useContext(MyEscalationsDispatch);
    if (context === undefined) {
        throw new Error("UseMyEscalationsDispatch must be used within a MyEscalationsContextProvider");
    }
    return context;
}
export const searchResultsIV = {
    Escalations: {},
    Error: "",
    InProgress: true,
    FollowupResponse: -1,
    MyCasesViewDefault: true,
    HomeViewDefault: true
};
export const MyEscalationsContextProvider = ({ children, }) => {
    const [myEscalationItems, myEscalationItemsDispatch] = React.useReducer(MyEscalationsReducer, searchResultsIV);
    const getMyEscalations = () => {
        getMyEscalationsAction(myEscalationItemsDispatch);
    };
    const createConsumerFollowup = (data) => {
        createConsumerFollowupAction(myEscalationItemsDispatch, data);
    };
    const createCommercialFollowup = (data) => {
        createCommercialFollowupAction(myEscalationItemsDispatch, data);
    };
    const setSelectedEscalationRequestNumber = (data) => {
        setSelectedEscalationRequestNumberAction(data, myEscalationItemsDispatch);
    };
    const setMyCasesView = (toggle) => {
        setMyCasesViewAction(toggle, myEscalationItemsDispatch);
    };
    const setHomeView = (toggle) => {
        setHomeViewAction(toggle, myEscalationItemsDispatch);
    };
    const myEscalationsState = {
        Escalations: myEscalationItems.Escalations,
        FollowupResponse: myEscalationItems.FollowupResponse,
        SelectedEscalationRequestNumber: myEscalationItems.SelectedEscalationRequestNumber,
        Error: myEscalationItems.Error,
        InProgress: myEscalationItems.InProgress,
        MyCasesViewDefault: myEscalationItems.MyCasesViewDefault,
        HomeViewDefault: myEscalationItems.HomeViewDefault
    };
    const myEscalationsDispatch = {
        GetMyEscalations: getMyEscalations,
        CreateConsumerFollowup: createConsumerFollowup,
        CreateCommercialFollowup: createCommercialFollowup,
        SetSelectedEscalationRequestNumber: setSelectedEscalationRequestNumber,
        SetMyCasesView: setMyCasesView,
        SetHomeView: setHomeView
    };
    return (_jsx(MyEscalationsState.Provider, Object.assign({ value: myEscalationsState }, { children: _jsx(MyEscalationsDispatch.Provider, Object.assign({ value: myEscalationsDispatch }, { children: children })) })));
};
