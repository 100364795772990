export function createUserOptions(people) {
    return people.map((o, i) => {
        return {
            key: i,
            text: o.name,
            data: o,
            selected: false,
        };
    });
}
