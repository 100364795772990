var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import CommercialFlowContextActionTypeKeys from "./ActionTypeKeys";
import { submitCommercialCase } from "../../services";
//interface actions for UI dispatch
export function basicDetailsValidationStatusCompleteUpdate(basicDetailsValidationStatusPayload) {
    return {
        type: CommercialFlowContextActionTypeKeys.Update_BasicDetailsValidationStatus_Complete,
        payload: basicDetailsValidationStatusPayload,
    };
}
export function basicDetailsValidationStatusUpdate(basicDetailsValidationStatusPayload) {
    return {
        type: CommercialFlowContextActionTypeKeys.Update_BasicDetailsValidationStatus,
        payload: basicDetailsValidationStatusPayload,
    };
}
export function escalationDetailsValidationStatusCompleteUpdate(escalationDetailsValidationStatusPayload) {
    return {
        type: CommercialFlowContextActionTypeKeys.Update_EscalationDetailsValidationStatus_Complete,
        payload: escalationDetailsValidationStatusPayload,
    };
}
export function escalationDetailsValidationStatusUpdate(escalationDetailsValidationStatusPayload) {
    return {
        type: CommercialFlowContextActionTypeKeys.Update_EscalationDetailsValidationStatus,
        payload: escalationDetailsValidationStatusPayload,
    };
}
export function escalationDetailsCompleteUpdate(escalationDetailsPayload) {
    return {
        type: CommercialFlowContextActionTypeKeys.Update_EscalationDetails_Complete,
        payload: escalationDetailsPayload,
    };
}
export function basicDetailsUpdate(basicDetailsPayload) {
    return {
        type: CommercialFlowContextActionTypeKeys.Update_BasicDetails,
        payload: basicDetailsPayload,
    };
}
export function escalationDetailsFieldsUpdate(escalationFiledDetails) {
    return {
        type: CommercialFlowContextActionTypeKeys.Update_EscalationDetails,
        payload: escalationFiledDetails,
    };
}
// all context actions and dispatch results
export function basicDetailsUpdateAction(basicDetailsPayload, dispatch) {
    dispatch({
        type: CommercialFlowContextActionTypeKeys.Update_BasicDetails,
        payload: basicDetailsPayload,
    });
}
export function escalationDetailsCompleteUpdateAction(escalationDetailsPayload, dispatch) {
    dispatch({
        type: CommercialFlowContextActionTypeKeys.Update_EscalationDetails_Complete,
        payload: escalationDetailsPayload,
    });
}
export function basicDetailsAreValidAction(areValid, dispatch) {
    dispatch({
        type: CommercialFlowContextActionTypeKeys.Set_BasicDetailsAreValid,
        payload: areValid,
    });
}
export function escalationDetailsAreValidAction(areValid, dispatch) {
    dispatch({
        type: CommercialFlowContextActionTypeKeys.Set_EscalationDetailsAreValid,
        payload: areValid,
    });
}
export default function submitCommercialEscalation(commercialEscalation, dispatch) {
    return __awaiter(this, void 0, void 0, function* () {
        dispatch(submitInprogress(true));
        // Calling service layer with commercial escalation model.
        submitCommercialCase(commercialEscalation).then((data) => {
            try {
                // Access the data here
                const uemTrackingID = data === null || data === void 0 ? void 0 : data.uemTrackingId;
                if (uemTrackingID)
                    dispatch(submitSuccess(uemTrackingID));
                else {
                    dispatch(submitInprogress(false));
                    dispatch(submitFail("Failed to submit the escalation. Please validate the details and submit again."));
                }
            }
            catch (error) {
                dispatch(submitFail(error));
            }
        });
    });
}
export function submitEscalation(commercialEscalationPayload) {
    return {
        type: CommercialFlowContextActionTypeKeys.Submit_CommercialEscalation,
        payload: commercialEscalationPayload,
    };
}
export function submitSuccess(uemTrackingID) {
    return {
        type: CommercialFlowContextActionTypeKeys.Submit_CommercialEscalation_Success,
        payload: uemTrackingID,
    };
}
export function submitFail(error) {
    return {
        type: CommercialFlowContextActionTypeKeys.Submit_CommercialEscalation_Failed,
        payload: error,
    };
}
export function submitInprogress(progress) {
    return {
        type: CommercialFlowContextActionTypeKeys.Submit_CommercialEscalation_Inprogress,
        payload: progress,
    };
}
