import { jsx as _jsx } from "react/jsx-runtime";
import React from "react";
import getCustomerDataAction, { getCustomerDataCollectionAction, getPartnerDataAction, getPartnerDataCollectionAction, } from "./Actions";
import { CustomerDataReducer } from "./CustomerSearchReducer";
const CustomerDataState = React.createContext({});
const CustomerDataDispatch = React.createContext({});
export function useCustomerDataState() {
    const context = React.useContext(CustomerDataState);
    if (context === undefined) {
        throw new Error("UseCustomerDataState must be used within a CustomerDataContextProvider");
    }
    return context;
}
export function useCustomerDataDispatch() {
    const context = React.useContext(CustomerDataDispatch);
    if (context === undefined) {
        throw new Error("UseMyEscalationsDispatch must be used within a MyEscalationsContextProvider");
    }
    return context;
}
export const searchResultsIV = {
    CustomerData: [],
    Error: "",
    InProgress: false,
};
export const CustomerDataContextProvider = ({ children, }) => {
    const [customerDataInfo, customerDataInfoDispatch] = React.useReducer(CustomerDataReducer, searchResultsIV);
    const getCustomerData = (searchText) => {
        getCustomerDataAction(searchText, customerDataInfoDispatch);
    };
    const getCustomerDataCollection = (requestPayload) => {
        getCustomerDataCollectionAction(requestPayload, customerDataInfoDispatch);
    };
    const getPartnerData = (searchText) => {
        getPartnerDataAction(searchText, customerDataInfoDispatch);
    };
    const getPartnerDataCollection = (partnerInfoRequest) => {
        getPartnerDataCollectionAction(partnerInfoRequest, customerDataInfoDispatch);
    };
    const customerDataState = {
        CustomerData: customerDataInfo.CustomerData,
        Error: customerDataInfo.Error,
        InProgress: customerDataInfo.InProgress,
        PartnerData: customerDataInfo.PartnerData,
    };
    const customerDataDispatch = {
        GetCustomerData: getCustomerData,
        GetPartnerData: getPartnerData,
        GetCustomerDataCollection: getCustomerDataCollection,
        GetPartnerDataCollection: getPartnerDataCollection,
    };
    return (_jsx(CustomerDataState.Provider, Object.assign({ value: customerDataState }, { children: _jsx(CustomerDataDispatch.Provider, Object.assign({ value: customerDataDispatch }, { children: children })) })));
};
