import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import * as React from "react";
import { ElxContainer, ElxTable, ElxLink, ElxText, ElxPrimaryButton, ElxSecondaryButton, } from "@elixir/components";
import { ElxNavLink } from "@elixir/fx";
import { Icon } from "@fluentui/react/lib/Icon";
import { SelectionMode, Spinner, SpinnerSize, Stack, } from "@fluentui/react";
import { useEfdContainerStyles } from "./HomePage.styles";
import { useLocaleStrings } from "../../library/providers/strings";
import { convertISOtoLocal, } from "../../library/utilities/DateAndTimeFormatter";
import "./HomePageStyle.css";
import { UseMyEscalationsDispatch, UseMyEscalationsState, } from "../../reducers/MyEscalationsContext/MyEscalationsContextProvider";
import { useRootContext } from "../../library/providers/root/RootContextProvider";
import { Tile } from "../../commoncomponents/Tile";
import NoEscalationImage from "../../images/noEscalations.png";
import { getDataFromSessionStorage } from "../../config/configHelper";
import DetailsPage from "../DetailsPage/DetailsPage";
const topStack = { childrenGap: 5 };
const midStack = { childrenGap: 10 };
const HomePage = () => {
    var _a;
    const { getLocalSpecificString } = useLocaleStrings();
    const { isConfigurationSet } = useRootContext();
    const MyEscalationsState = UseMyEscalationsState();
    const { GetMyEscalations, SetSelectedEscalationRequestNumber, SetHomeView } = UseMyEscalationsDispatch();
    const [myCasesDetails, setMyCasesDetails] = React.useState([]);
    const [detailsview, setDetailsview] = React.useState(false);
    const columns = [
        {
            key: "column0",
            name: "Escalation ID",
            fieldName: "escalationId",
            minWidth: 150,
            maxWidth: 200,
            isResizable: true,
            onRender: (item) => {
                return (_jsx(ElxLink, Object.assign({ onClick: () => detailsInfoLoad(item.escalationId) }, { children: item.escalationId })));
            },
        },
        {
            key: "column1",
            name: "Customer",
            fieldName: "customerName",
            minWidth: 160,
            maxWidth: 200,
            isResizable: true,
        },
        {
            key: "column3",
            name: "Product",
            fieldName: "product",
            minWidth: 180,
            maxWidth: 300,
            isResizable: true,
        },
        {
            key: "column5",
            name: "Status",
            fieldName: "status",
            minWidth: 100,
            maxWidth: 300,
            isResizable: true,
            showSortIconWhenUnsorted: true,
            onRender: (item) => {
                var _a, _b, _c, _d, _e, _f, _g, _h;
                if (((_a = item.status) === null || _a === void 0 ? void 0 : _a.toLowerCase()) == "closed" ||
                    ((_b = item.status) === null || _b === void 0 ? void 0 : _b.toLowerCase()) == "resolved") {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "SkypeCircleCheck", style: { color: "green" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else if ((_c = item.status) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes("progress")) {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "SyncStatusSolid", style: { color: "#015CDA" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else if ((_d = item.status) === null || _d === void 0 ? void 0 : _d.toLowerCase().includes("review")) {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "InfoSolid", style: { color: "#be2596" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else if ((_e = item.status) === null || _e === void 0 ? void 0 : _e.toLowerCase().includes("block")) {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "Blocked2Solid", style: { color: "#red" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else if (((_f = item.status) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes("open")) ||
                    ((_g = item.status) === null || _g === void 0 ? void 0 : _g.toLowerCase().includes("new")) ||
                    ((_h = item.status) === null || _h === void 0 ? void 0 : _h.toLowerCase().includes("start"))) {
                    return (_jsx(_Fragment, { children: _jsxs(Stack, Object.assign({ className: "statusdisplay", horizontal: true, style: { gap: 4 } }, { children: [_jsx(Stack.Item, { children: _jsx(Icon, { iconName: "SyncStatusSolid", style: { color: "#015CDA" } }) }), _jsx(Stack.Item, { children: item.status || "Not Available" })] })) }));
                }
                else {
                    return _jsx("div", { children: item.status || "Not Available" });
                }
            },
        },
        {
            key: "column6",
            name: "Submitted",
            fieldName: "submittedOn",
            minWidth: 200,
            maxWidth: 400,
            isResizable: true,
            onRender: (item) => {
                if (item.submittedOn !== undefined && item.submittedOn !== null) {
                    const formattedDateTime = convertISOtoLocal(item.submittedOn);
                    return formattedDateTime;
                }
                else {
                    return "Not Available";
                }
            },
        },
    ];
    function onBreadcrumbItemClicked() {
        SetHomeView(true);
    }
    React.useEffect(() => {
        if (isConfigurationSet)
            GetMyEscalations();
    }, [isConfigurationSet]);
    React.useEffect(() => {
        try {
            if (MyEscalationsState === null || MyEscalationsState === void 0 ? void 0 : MyEscalationsState.Escalations) {
                // Get the anchor element by its label attribute
                const anchorElement = document.querySelector('[label="ElxBreadcrumbItem"]');
                // Check if the element exists before adding the click event
                if (anchorElement) {
                    // Add the click event listener
                    anchorElement.addEventListener("click", onBreadcrumbItemClicked);
                }
                // Access the data here
                const myescalation = MyEscalationsState.Escalations;
                const consumerEscalation = myescalation === null || myescalation === void 0 ? void 0 : myescalation.consumerEscalation;
                // eslint-disable-next-line prefer-const
                let itemsFinalConsumer = [];
                if (consumerEscalation !== null &&
                    consumerEscalation !== undefined &&
                    consumerEscalation.length > 0) {
                    itemsFinalConsumer = consumerEscalation.map((conEscalation) => {
                        var _a, _b;
                        const escalation = {
                            supportRequestNumber: conEscalation.supportRequestNumber || "",
                            customerName: ((_a = conEscalation.customerInfo) === null || _a === void 0 ? void 0 : _a.customerFirstName) || "",
                            escalationId: conEscalation.escalationRequestNumber || "",
                            product: ((_b = conEscalation.product) === null || _b === void 0 ? void 0 : _b.name) || "",
                            status: conEscalation.status || "",
                            submittedOn: conEscalation.createdOn,
                            escalationFlowType: conEscalation.flowType || "Home User",
                        };
                        return escalation;
                    });
                }
                const commercialEscalation = myescalation === null || myescalation === void 0 ? void 0 : myescalation.commercialEscalation;
                let itemsFinalCommercial = [];
                if (commercialEscalation !== null &&
                    commercialEscalation !== undefined &&
                    commercialEscalation.length > 0) {
                    itemsFinalCommercial = commercialEscalation.map((conEscalation) => {
                        var _a, _b, _c, _d;
                        const productNames = (_a = conEscalation.products) === null || _a === void 0 ? void 0 : _a.map((product) => product.productName).join(",");
                        const programNames = (_b = conEscalation.programs) === null || _b === void 0 ? void 0 : _b.map((program) => program.programName).join(",");
                        const combinedNamesArray = [productNames, programNames].filter(Boolean);
                        const combinedNames = combinedNamesArray.join(",");
                        const escalation = {
                            supportRequestNumber: conEscalation.supportRequestNumber || "",
                            customerName: ((_c = conEscalation.customerInfo) === null || _c === void 0 ? void 0 : _c.customerSegmentName) === "Consumer"
                                ? conEscalation.customerInfo.customerFirstName
                                : ((_d = conEscalation.accountInfo) === null || _d === void 0 ? void 0 : _d.companyName) || "NA",
                            escalationId: conEscalation.escalationRequestNumber || "NA",
                            product: combinedNames,
                            status: conEscalation.status || "",
                            submittedOn: conEscalation.createdOn,
                            escalationFlowType: conEscalation.flowType || "Commercial",
                        };
                        return escalation;
                    });
                }
                const finalEscalationSummary = itemsFinalCommercial.concat(itemsFinalConsumer);
                const sortedEscalationsArray = finalEscalationSummary
                    .filter((obj) => obj.submittedOn !== undefined && obj.submittedOn.trim() !== "") // Filter out undefined or empty strings
                    .sort((a, b) => {
                    // Ensure both values are valid before comparing
                    if (a.submittedOn && b.submittedOn) {
                        return b.submittedOn.localeCompare(a.submittedOn);
                    }
                    // Handle cases where one value is undefined
                    else if (!a.submittedOn && b.submittedOn) {
                        return 1; // Treat undefined as greater than a valid date
                    }
                    else if (a.submittedOn && !b.submittedOn) {
                        return -1; // Treat undefined as smaller than a valid date
                    }
                    return 0; // Both values are undefined or empty, treat them as equal
                })
                    .slice(0, 5);
                setMyCasesDetails(sortedEscalationsArray);
            }
        }
        catch (error) {
            console.error(error);
        }
    }, [MyEscalationsState.Escalations]);
    const detailsInfoLoad = (escalationRequestNumber) => {
        setDetailsview(true);
        SetHomeView(false);
        SetSelectedEscalationRequestNumber(escalationRequestNumber);
    };
    function renderHeader() {
        const { name: name } = useRootContext();
        const firstName = name.split(" ")[0];
        return (_jsx("div", { children: _jsxs(Stack, { children: [_jsx(Stack.Item, Object.assign({ styles: { root: { marginBottom: "5px", marginTop: "-20px" } } }, { children: _jsxs("h1", Object.assign({ style: { margin: 0, fontSize: "x-large" } }, { children: [getLocalSpecificString("HomepageHeaderLabel"), " ", firstName] })) })), _jsx(Stack.Item, Object.assign({ styles: { root: { marginBottom: "15px" } } }, { children: _jsx(ElxText, Object.assign({ "data-testid": "subtitle", className: "MyEscalationsHeaderText" }, { children: getLocalSpecificString("HomepageSubHeaderLabel") })) })), _jsx(Stack.Item, Object.assign({ styles: { root: { marginLeft: "5px" } } }, { children: _jsx(ElxNavLink, Object.assign({ tabIndex: -1, to: getLocalSpecificString("CreateEscalationsLink") }, { children: _jsx(ElxPrimaryButton, { "data-testid": "createescalation", iconProps: { iconName: "Add" }, text: "Submit an escalation", href: "/escalation", styles: {
                                    root: {
                                        marginLeft: "-5px",
                                        radius: "4px",
                                        height: "32px",
                                        width: "175px",
                                    },
                                } }) })) }))] }) }));
    }
    return (_jsxs("div", Object.assign({ className: useEfdContainerStyles }, { children: [(MyEscalationsState.HomeViewDefault || detailsview == false) && (_jsx(ElxContainer, Object.assign({ "data-testid": "mycasescontainer", fillBackground: true, headerContent: renderHeader() }, { children: _jsxs("div", Object.assign({ style: { marginLeft: "-15px" } }, { children: [_jsx("h2", Object.assign({ style: { margin: 0, fontSize: "large" }, className: "QuickLinksText" }, { children: "Quick Links" })), _jsxs(Stack, Object.assign({ horizontal: true, wrap: true, styles: { root: { marginBottom: "10px", marginTop: "10px" } } }, { children: [_jsx(Stack.Item, { children: _jsx(Tile, { name: "Support Connect", title: "Support Connect", description: "Support Connect provides internal support and escalation-related resources.", link: "https://microsoft.sharepoint.com/teams/SupportConnect", linkName: "Go To Support Connect", linkTarget: "_blank", buttonText: "Go to Support Connect" }, "supportConnect") }), _jsx(Stack.Item, { children: _jsx(Tile, { name: "GetHelp Overview", title: "GetHelp Overview", description: "Get an overview of the services GetHelp provides to customers, partners, and field, including guidance, support, and escalation management.", link: "https://microsoft.sharepoint.com/teams/GetHelpinfo", linkName: "GetHelp Overview", linkTarget: "_blank", buttonText: "Go to GetHelp Overview" }, "GetHelpOverview") }), _jsx(Stack.Item, { children: _jsx(Tile, { name: "Search Global cases", title: "Search Global cases", description: "Search for global escalations submitted for Commerical/Partner customers.", link: getDataFromSessionStorage("gethelpPortalLink"), linkName: "Go To Global Case Search", linkTarget: "_blank", buttonText: "Go to Global Case Search" }, "GoToCaseSearch") }), ((_a = getDataFromSessionStorage("isEUEnabled")) === null || _a === void 0 ? void 0 : _a.toLowerCase()) ===
                                    "true" && (_jsx(Stack.Item, { children: _jsx(Tile, { name: "Search EU cases", title: "Search EU cases", description: "Search for EU escalations submitted for Commerical/Partner customers.", link: getDataFromSessionStorage("euGethelpPortalLink"), linkName: "Go To EU Case Search", linkTarget: "_blank", buttonText: "Go to EU Case Search" }, "GoToCaseSearch") }))] })), _jsxs(Stack, { children: [_jsxs(Stack.Item, Object.assign({ tokens: topStack }, { children: [_jsx("h2", Object.assign({ style: { margin: 0, fontSize: "large" }, className: "QuickLinksText" }, { children: "My escalations" })), (MyEscalationsState === null || MyEscalationsState === void 0 ? void 0 : MyEscalationsState.InProgress) && (_jsx(Spinner, { size: SpinnerSize.large }))] })), !(MyEscalationsState === null || MyEscalationsState === void 0 ? void 0 : MyEscalationsState.InProgress) &&
                                    (myCasesDetails === null || myCasesDetails === void 0 ? void 0 : myCasesDetails.length) == 0 && (_jsxs(Stack, { children: [_jsx(Stack.Item, Object.assign({ tokens: midStack }, { children: _jsx(ElxTable, { styles: { root: { marginTop: "10px" } }, "data-testid": "mycaseslist", className: "EscalationsList", selectionMode: SelectionMode.none, items: myCasesDetails, columns: columns }) })), _jsx(Stack.Item, { children: _jsx("img", { src: NoEscalationImage, alt: "Image description", height: "100", width: "100", style: { marginTop: "10px", marginLeft: "450px" } }) }), _jsx(Stack.Item, { children: _jsx(ElxText, Object.assign({ "data-testid": "subtitle", className: "QuickLinksText", style: { marginTop: "10px", marginLeft: "430px" } }, { children: "No escalations To display" })) }), _jsx(Stack.Item, { children: _jsx(ElxText, Object.assign({ "data-testid": "subtitle", className: "HomepageSubHeaderLabel", style: { marginLeft: "270px" } }, { children: "Your submitted escalations will be shown here. Create an escalation to get started." })) }), _jsx(Stack.Item, { children: _jsx(ElxNavLink, Object.assign({ tabIndex: -1, to: getLocalSpecificString("CreateEscalationsLink") }, { children: _jsx(ElxPrimaryButton, { "data-testid": "createescalation", text: "Create an escalation", href: "/escalation", styles: {
                                                        root: {
                                                            marginTop: "20px",
                                                            marginLeft: "430px",
                                                            radius: "4px",
                                                            height: "32px",
                                                            width: "175px",
                                                        },
                                                    } }) })) })] })), myCasesDetails && myCasesDetails.length > 0 && (_jsxs(Stack, { children: [_jsx(Stack.Item, Object.assign({ tokens: midStack }, { children: _jsx(ElxTable, { styles: { root: { marginTop: "10px" } }, "data-testid": "mycaseslist", className: "EscalationsList", selectionMode: SelectionMode.none, items: myCasesDetails, columns: columns }) })), _jsx(Stack.Item, Object.assign({ align: "center", styles: { root: { marginTop: "20px" } } }, { children: _jsx(ElxSecondaryButton, { "data-testid": "createescalation", text: "View All Escalations", href: "/myescalations", styles: {
                                                    root: {
                                                        marginLeft: "-10px",
                                                        radius: "4px",
                                                        height: "32px",
                                                        width: "147px",
                                                        justifyContent: "center",
                                                        borderColor: "rgba(209, 209, 209, 1)",
                                                    },
                                                } }) }))] }))] })] })) }))), !MyEscalationsState.HomeViewDefault && detailsview && (_jsx(DetailsPage, { showDetails: detailsview, setShowDetails: setDetailsview }))] })));
};
export default HomePage;
