import { mergeStyles } from "@fluentui/react";
export const useFormRichTextEditorOverrideStyles = mergeStyles({
    root: {
        "& .elx-input": {
            fontSize: "inherit !important",
            width: "500px",
            "& .ms-CommandBar": {
                paddingBottom: "9px",
                marginTop: "-3px",
                border: "1px solid grey",
                "& .ms-CommandBar-primaryCommand": {
                    border: "none",
                },
            },
            ".elx-richtext-editor": {
                maxHeight: 250,
                overflowY: "auto",
            },
        },
        "& span .is-error": {
            color: "rgb(164, 38, 44)",
            paddingTop: "5px",
            fontSize: "13px",
            fontWeight: "400",
        },
    },
});
export const defaultElxTextEditorStyles = {
    editor: {
        minHeight: "100px",
        minWidth: "100px",
    },
    commandBarContainer: {
        maxWidth: "500px",
        minWidth: "100px",
    },
};
