import { mergeStyles } from "@fluentui/react";
export const useReviewFormStyles = () => {
    const ReviewFormStyle = {
        root: [
            {
                "& .section": {
                    width: "100%",
                    paddingBottom: "10px",
                    "& .reviewForm-row": {
                        display: "flex",
                        flexWrap: "wrap",
                        "& .rowLabel": {
                            minWidth: "250px",
                            maxWidth: "420px",
                            width: "30%",
                            color: "#6a6866",
                        },
                        "& .valueList ul": {
                            paddingLeft: 0,
                            margin: 0,
                        },
                        "& .rowValue": {
                            width: "500px",
                        },
                    },
                },
            },
        ],
    };
    return mergeStyles(ReviewFormStyle);
};
