import { jsx as _jsx } from "react/jsx-runtime";
import { ElxContainer, ElxMessageBarType } from "@elixir/components";
import { Tile } from "../../commoncomponents/Tile";
import { useLocaleStrings } from "../../library/providers/strings";
import { useRootContext } from "../../library/providers/root/RootContextProvider";
import { useEfdContainerStyles } from "./Escalations.styles";
import "./EscalationCustom.css";
const Escalations = () => {
    const { getLocalSpecificString } = useLocaleStrings();
    const { privileges: { isExecutive } } = useRootContext();
    const executiveTile = [
        {
            title: getLocalSpecificString("executiveTitle"),
            description: getLocalSpecificString("executiveDescription"),
            link: getLocalSpecificString("executiveLink"),
            linkName: getLocalSpecificString("executiveLinkName"),
            name: getLocalSpecificString("executiveTitle"),
        }
    ];
    const escalationSettings = [
        {
            title: getLocalSpecificString("consumerTitle"),
            description: getLocalSpecificString("consumerDescription"),
            link: getLocalSpecificString("consumerLink"),
            linkName: getLocalSpecificString("consumerLinkName"),
            name: getLocalSpecificString("consumerTitle"),
        },
        {
            title: getLocalSpecificString("licenseTitle"),
            description: getLocalSpecificString("licenseQCardHeader"),
            link: getLocalSpecificString("licenseLink"),
            linkName: getLocalSpecificString("licenseLinkName"),
            name: getLocalSpecificString("licenseTitle"),
        },
        {
            title: getLocalSpecificString("commercialTitle"),
            description: getLocalSpecificString("commercialDescription"),
            link: getLocalSpecificString("commercialLink"),
            linkName: getLocalSpecificString("commercialLinkName"),
            name: getLocalSpecificString("commercialTitle"),
        },
        ...(isExecutive ? executiveTile : []),
        {
            title: getLocalSpecificString("technicalProductTitle"),
            description: getLocalSpecificString("technicalDescription"),
            link: getLocalSpecificString("technicalLink"),
            linkName: getLocalSpecificString("technicalLinkName"),
            name: getLocalSpecificString("technicalProductTitle"),
        },
        {
            title: getLocalSpecificString("partnerProgramTitle"),
            description: getLocalSpecificString("partnerProgramDescription"),
            link: getLocalSpecificString("partnerProgramLink"),
            linkName: getLocalSpecificString("partnerProgramLinkName"),
            name: getLocalSpecificString("partnerProgramTitle"),
        },
        {
            title: getLocalSpecificString("privacyTitle"),
            description: getLocalSpecificString("privacyDescription"),
            link: getLocalSpecificString("privacyLink"),
            linkName: getLocalSpecificString("privacyLinkName"),
            name: getLocalSpecificString("privacyTitle"),
        },
        {
            title: getLocalSpecificString("specialprogramTitle"),
            description: getLocalSpecificString("specialprogramDescription"),
            link: getLocalSpecificString("specialprogramLink"),
            linkName: getLocalSpecificString("specialprogramLinkName"),
            name: getLocalSpecificString("specialprogramTitle"),
        },
    ];
    const messageInfo = { message: getLocalSpecificString("escalationInfoMessage"), type: ElxMessageBarType.info, iconProps: { iconName: "InfoSolid" } };
    return (_jsx("div", Object.assign({ className: `${useEfdContainerStyles} landingContainer` }, { children: _jsx(ElxContainer, Object.assign({ headerText: getLocalSpecificString("pageHeader"), fillBackground: true, message: messageInfo, subHeaderText: getLocalSpecificString("pageSubHeader") }, { children: escalationSettings.map((escalation) => {
                return (_jsx("div", Object.assign({ className: "tileContainer" }, { children: _jsx(Tile, { "aria-label": escalation.name, name: escalation.name, title: escalation.title, description: escalation.description, link: escalation.link, linkName: escalation.linkName }, escalation.name) }), escalation.name));
            }) })) })));
};
export default Escalations;
