import { lazy } from "react";
export const SpecialProgramBasicTab = lazy(() => import("../../components/SpecialPrograms/BasicTab"));
export const SpecialProgramDetailsTab = lazy(() => import("../../components/SpecialPrograms/EscalationDetailsTab"));
export const SpecialProgramReviewTab = lazy(() => import("../../components/SpecialPrograms/ReviewTab"));
export const PSSTBasicsTab = lazy(() => import("../../components/PSST/BasicsTab"));
export const PSSTEscalationDetailsTab = lazy(() => import("../../components/PSST/EscalationDetailsTab"));
export const PSSTReviewTab = lazy(() => import("../../components/PSST/ReviewTab"));
export const LicenseQBasicsTab = lazy(() => import("../../components/LicenseQ/BasicsTab"));
export const LicenseQEscalationDetailsTab = lazy(() => import("../../components/LicenseQ/EscalationDetailsTab"));
export const LicenseQReviewTab = lazy(() => import("../../components/LicenseQ/ReviewTab"));
export const PCABasicsTab = lazy(() => import("../../components/PCA/BasicsTab"));
export const PCAEscalationDetailsTab = lazy(() => import("../../components/PCA/EscalationDetailsTab"));
export const PCAReviewTab = lazy(() => import("../../components/PCA/ReviewTab"));
export const TechProductIssueBasicsTab = lazy(() => import("../../components/TechProductIssue/BasicsTab"));
export const TechProductIssueEscalationDetailsTab = lazy(() => import("../../components/TechProductIssue/EscalationDetailsTab"));
export const TechProductIssueReviewTab = lazy(() => import("../../components/TechProductIssue/ReviewTab"));
export const ELPBasicsTab = lazy(() => import("../../components/ELP/BasicsTab"));
export const ELPEscalationDetailsTab = lazy(() => import("../../components/ELP/EscalationDetails"));
export const ELPReviewTab = lazy(() => import("../../components/ELP/ReviewTab"));
export const CPPBasicsTab = lazy(() => import("../../components/CustomerPartnerPrograms/BasicsTab"));
export const CPPEscalationDetailsTab = lazy(() => import("../../components/CustomerPartnerPrograms/EscalationDetailsTab"));
export const CPPReviewTab = lazy(() => import("../../components/CustomerPartnerPrograms/ReviewTab"));
