var MyEscalationsActionTypeKeys;
(function (MyEscalationsActionTypeKeys) {
    MyEscalationsActionTypeKeys["Get_MyEscalations"] = "GET_MYESCALATIONS";
    MyEscalationsActionTypeKeys["Set_Followup_Response"] = "SET_FOLLOWUP_RESPONSE";
    MyEscalationsActionTypeKeys["Set_EscalationDetails"] = "SET_ESCALATIONDETAILS";
    MyEscalationsActionTypeKeys["Get_MyEscalations_Success"] = "GET_MYESCALATIONS_SUCCESS";
    MyEscalationsActionTypeKeys["Get_MyEscalations_Failure"] = "GET_MYESCALATIONS_ERROR";
    MyEscalationsActionTypeKeys["Get_MyEscalations_InProgress"] = "GET_MYESCALATIONS_INPROGRESS";
    MyEscalationsActionTypeKeys["Set_MyCasesView"] = "SET_MYCASESVIEW";
    MyEscalationsActionTypeKeys["Set_HomeView"] = "SET_HOMEVIEW";
})(MyEscalationsActionTypeKeys || (MyEscalationsActionTypeKeys = {}));
export default MyEscalationsActionTypeKeys;
