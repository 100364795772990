import { mergeStyles } from "@fluentui/react";
import { useTheme } from "@fluentui/react-theme-provider";
export const useFieldLabelStyles = () => {
    const theme = useTheme();
    const fieldLabelStyle = {
        root: [
            {
                "& .fieldLabel": {
                    "& .mandatory": {
                        color: "rgb(164, 38, 44)",
                    },
                    "& .ms-TooltipHost": {
                        position: "relative",
                        cursor: "pointer",
                        verticalAlign: "middle",
                        "& .infoIcon": {
                            color: "#666",
                        }
                    },
                },
            },
        ],
    };
    return mergeStyles(fieldLabelStyle);
};
