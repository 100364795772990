var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import jwtDecode from "jwt-decode";
import { getAccessToken } from "@elixir/auth";
import { getAppConfig } from "../services";
// Function to fetch roles from user token.
export const getConfig = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    const token = yield getAccessToken();
    const decoded = jwtDecode(token);
    const config = yield getAppConfig();
    return {
        roles: (_a = decoded === null || decoded === void 0 ? void 0 : decoded.roles) !== null && _a !== void 0 ? _a : [],
        name: decoded === null || decoded === void 0 ? void 0 : decoded.name,
        config: config,
    };
});
// Function to push app config to session storage.
// Data is fetched from Azure app config.
export const pushAppConfigToSessionStorage = (appConfig) => {
    Object.entries(appConfig).map(([key, value]) => {
        sessionStorage.setItem(key, value);
    });
};
// Function to read data from session storage.
export const getDataFromSessionStorage = (key) => {
    return sessionStorage.getItem(key) || "";
};
// Function to delete data from session storage.
export const deleteDataFromSessionStorage = (key) => {
    sessionStorage.removeItem(key);
};
